export const LandingSlider = [
  {
    id: "1",
    img: "../svgs/WebApplicationDevelopmentSvg.svg",
    name: "Web Application Development",
    content:
      "Our new-age custom web applications deliver an unbeatable experience to your employees and customers.",
  },
  {
    id: "2",
    img: "../svgs/MobileApplicationDevelopmentSvg.svg",
    name: "Mobile App Development",
    content:
      "We create purpose-driven custom mobile solutions to build an outstanding brand for your business.",
  },
  {
    id: "3",
    img: "../svgs/SoftwareDevelopmentSvg.svg",
    name: "Software Development",
    content:
      "Gain competitive advantage, operational efficiency, & customer satisfaction with custom software solutions.",
  },
  {
    id: "4",
    img: "../svgs/WebsiteDevelopmentSvg.svg",
    name: "Website Development",
    content:
      "We create more than pages, we build a digital experience consisting of powerful and responsive website features.",
  },
  {
    id: "5",
    img: "../svgs/FrontEndDevelopmentSvg.svg",
    name: "Front-End Development",
    content:
      "Our quality-first approach helps us build an interactive front end for better customer retention and engagement.",
  },
  {
    id: "6",
    img: "../svgs/MobileApplicationDevelopmentSvg.svg",
    name: "Back-End Development",
    content:
      "Robust, safe, and scalable, our backend development services can be tailored to suit your business goals.",
  },
  {
    id: "7",
    img: "../svgs/EcommerceStoreDevelopmentSvg.svg",
    name: "E-commerce Store Development",
    content:
      "Want to build an online store? Reach more customers and sell more products with our persuasive e-store design and features!",
  },
  {
    id: "8",
    img: "../svgs/UIUXDevelopmentSvg.svg",
    name: "UI/UX Development Services",
    content:
      "Our team of IT professionals is everything you need to make your business digitally smart and successful!",
  },
  {
    id: "9",
    img: "../svgs/HireDedicatedDevelopmentSvg.svg",
    name: "Hire a Dedicated Developer",
    content:
      "Boost your business by hiring a dedicated IT developer! Choose an IT expert based on your requirements & budget.",
  },
  // {
  //   id: "10",
  //   img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
  //   name: "IT Services",
  //   content:
  //     "Our team of IT professionals is everything you need to make your business digitally smart and successful! ",
  // },
];
export const ReactJsSlider = [
  {
    id: "1",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: " <a class='text-hover' target='_blank' href='/progressive-web-app-development'>Progressive Web Apps</a>",
    content:
      "Our secure web app solutions align with your business's long-term goals and strategies.",
  },
  {
    id: "2",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Secure Web Apps",
    content:
      "We provide secure web application solutions that support your business's strategic growth.",
  },
  {
    id: "3",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Scalable Applications",
    content:
      "Our scalable web app development solutions are designed to grow with your business needs.",
  },
  {
    id: "4",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Third Party Integration",
    content:
      "We offer seamless third-party integration to ensure your app works well with external systems.",
  },
];
export const ReactDevelopmentMapData = [
  {
    id: "1",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Progressive \n Web Apps",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "2",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Secure \n Web Apps",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "3",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Scalable \n Applications",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "4",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Third Party \n Integration",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "5",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Progressive \n Web Apps",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "6",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Secure \n Web Apps",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "7",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Scalable \n Applications",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "8",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Third Party \n Integration",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "9",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Progressive \n Web Apps",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "10",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Third Party \n Integration",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "11",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Scalable \n Applications",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
  {
    id: "12",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-dev.svg",
    name: "Third Party \n Integration",
    content:
      "Our secure web application development solutions align with business long-term strategies. ",
  },
];

export const TechnologiesIconTab1 = [
  {
    id: "1",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/laravel.webp",
    Logo_name: "Laravel",
    category: "5",
    link: "/laravel-development-services",
  },
  {
    id: "34",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Android.png",
    Logo_name: "Android",
    category: "1",
    link: "/android-app-development-services",
  },
  {
    id: "8",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Apple.png",
    Logo_name: "IOS",
    category: "1",
    link: "/ios-app-development-company",
  },
  {
    id: "5",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Swift.png",
    Logo_name: "Swift",
    category: "1",
    link: "/swift-app-development-company",
  },
  {
    id: "3",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Ionic.png",
    Logo_name: "Ionic",
    category: "1",
    link: "#",
  },
  {
    id: "4",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Flutter.png",
    Logo_name: "Flutter",
    category: "1",
    link: "/flutter-app-development-services",
  },
  {
    id: "7",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/React.png",
    Logo_name: "React Native",
    category: "1",
    link: "/react-native-app-development-company",
  },
  {
    id: "6",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Xamarin.png",
    Logo_name: "Xamarin",
    category: "1",
    link: "/xamarin-mobile-app-development-company",
  },
  {
    id: "9",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/react.webp",
    Logo_name: "React",
    category: "2",
    link: "/react-js-development-company",
  },
  {
    id: "10",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Vue.js.png",
    Logo_name: "Vue",
    category: "2",
    link: "/vue-js-development-company",
  },
  {
    id: "11",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/JavaScript.png",
    Logo_name: "Javascript",
    category: "2",
    link: "#",
  },
  {
    id: "12",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Svelte.png",
    Logo_name: "Svelte Js",
    category: "2",
    link: "/svelte-js-development-company",
  },
  {
    id: "13",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Nuxt-JS.png",
    Logo_name: "Nuxt Js",
    category: "2",
    link: "/nuxt-js-development-company",
  },
  {
    id: "14",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/gatsby-js.webp",
    Logo_name: "Gatsby Js",
    category: "2",
    link: "/gatsbyjs-development-company",
  },
  {
    id: "15",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Knockout.webp",
    Logo_name: "Knockout Js",
    category: "2",
    link: "/knockoutjs-development-company",
  },
  {
    id: "16",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/ember-js.webp",
    Logo_name: "Ember Js",
    category: "2",
    link: "#",
  },
  {
    id: "17",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Odoo.webp",
    Logo_name: "Odoo",
    category: "9",
    link: "/odoo-development-company",
  },
  {
    id: "18",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/woo-commerce.webp",
    Logo_name: "Woo Commerce",
    category: "9",
    link: "/woocommerce-development",
  },
  {
    id: "19",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/shopify.webp",
    Logo_name: "Shopify",
    category: "9",
    link: "/shopify-development-company",
  },
  {
    id: "20",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/magento.webp",
    Logo_name: "Magento",
    category: "9",
    link: "/magento-development-services",
  },
  {
    id: "22",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Python.png",
    Logo_name: "Python",
    category: "4",
    link: "/python-development",
  },

  {
    id: "23",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/PHP.png",
    Logo_name: "PHP",
    category: "4",
    link: "/php-development-company",
  },
  {
    id: "24",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Node.js.png",
    Logo_name: "Node Js",
    category: "4",
    link: "/node-js-development-company",
  },
  {
    id: "56",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Java.png",
    Logo_name: "Java",
    category: "4",
    link: "#",
  },
  {
    id: "57",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Fastify.png",
    Logo_name: "Fastify",
    category: "4",
    link: "#",
  },
  {
    id: "25",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/nest.webp",
    Logo_name: "Nest Js",
    category: "2",
    link: "/nest-js-development-company",
  },
  {
    id: "26",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Meteor.js.png",
    Logo_name: "Meteor Js",
    category: "2",
    link: "/meteor-js-development-services",
  },
  {
    id: "27",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/express.webp",
    Logo_name: "Express Js",
    category: "4",
    link: "/express-js-development-company",
  },
  {
    id: "28",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/NET.png",
    Logo_name: ".Net",
    category: "4",
    link: "/aspnet-core-development-company",
  },
  {
    id: "29",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/CodeIgniter.png",
    Logo_name: "Codeigniter",
    category: "5",
    link: "/codeigniter-development-services",
  },
  {
    id: "58",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/CakePHP.png",
    Logo_name: "Cake PHP",
    category: "5",
    link: "#",
  },
  {
    id: "59",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Django.png",
    Logo_name: "Django",
    category: "5",
    link: "#",
  },
  {
    id: "30",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Backbone-js.webp",
    Logo_name: "Backbone Js",
    category: "2",
    link: "/backbonejs-development-services",
  },
  {
    id: "31",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/asp-net.webp",
    Logo_name: "Asp net",
    category: "4",
    link: "/top-asp-net-development-company",
  },
  {
    id: "33",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Angular.png",
    Logo_name: "Angular",
    category: "2",
    link: "/angular-js-development-company",
  },
  {
    id: "2",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kotlin.png",
    Logo_name: "Kotlin",
    category: "1",
    link: "/kotlin-development-company",
  },
  {
    id: "35",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/next-js.webp",
    Logo_name: "Next Js",
    category: "2",
    link: "/next-js-development-company",
  },
  {
    id: "38",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/next-js.webp",
    Logo_name: "Next Js",
    category: "4",
    link: "/next-js-development-company",
  },
  {
    id: "39",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/microsoft-sql-server-logo-svgrepo-com.svg",
    Logo_name: "SQL Server",
    category: "6",
    link: "#",
  },
  {
    id: "40",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mongodb-icon.svg",
    Logo_name: "MongoDB",
    category: "7",
    link: "#",
  },
  {
    id: "41",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Jenkins.svg",
    Logo_name: "Jenkins",
    category: "8",
    link: "#",
  },
  {
    id: "42",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/6206707fd7b91b0004122616-1.png",
    Logo_name: "Azure DevOps",
    category: "8",
    link: "#",
  },
  {
    id: "43",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/62a9c7ab8ff6441a2952dad2.png",
    Logo_name: "Docker",
    category: "8",
    link: "#",
  },
  {
    id: "44",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/58480a44cef1014c0b5e4917.png",
    Logo_name: "Kubernetes",
    category: "8",
    link: "#",
  },
  // {
  //   id: "45",
  //   Icon_img:
  //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/wordpress.webp",
  //   Logo_name: "WordPress",
  //   category: "9",
  // },
  {
    id: "46",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/584809b4cef1014c0b5e4907.png",
    Logo_name: "Kentico",
    category: "9",
    link: "/kentico-development",
  },
  {
    id: "47",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Gradle.svg",
    Logo_name: "Gradle",
    category: "8",
    link: "#",
  },
  {
    id: "49",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/NET-core.svg",
    Logo_name: ".NetNuke",
    category: "8",
    link: "#",
  },
  {
    id: "50",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/MySQL.svg",
    Logo_name: "MySQL",
    category: "7",
    link: "#",
  },
  {
    id: "51",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/PostgresSQL.svg",
    Logo_name: "PostgreSQL",
    category: "7",
    link: "#",
  },
  {
    id: "52",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Oracle.svg",
    Logo_name: "Oracle",
    category: "7",
    link: "#",
  },
  {
    id: "53",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Ruby.svg",
    Logo_name: "DynamoDB",
    category: "7",
    link: "#",
  },
  {
    id: "54",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SQLite.svg",
    Logo_name: "SQLite",
    category: "7",
    link: "#",
  },
  {
    id: "55",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Firebase.svg",
    Logo_name: "Firebase",
    category: "7",
    link: "#",
  },
  {
    id: "56",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/AWS.png",
    Logo_name: "AWS",
    category: "8",
    link: "/aws-development",
  },
  {
    id: "57",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/wordpress.webp",
    Logo_name: "WordPress",
    category: "10",
    link: "/wordpress-development-company",
  },
  {
    id: "58",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/drupal.png",
    Logo_name: "Drupal",
    category: "10",
    link: "#",
  },
  {
    id: "59",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/squarespace.png",
    Logo_name: "Squarespace",
    category: "10",
    link: "#",
  },
];
export const TechnologiesIconTab2 = [
  {
    id: "1",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "2",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "3",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "4",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "5",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "6",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "7",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "8",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "9",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "10",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "11",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "12",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
  {
    id: "13",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    Logo_name: "Create the right team",
  },
];

export const PortfolioCardArray = [
  {
    id: "1",
    breadcrumb: "Mobile App / Website / Motion",
    heading: "Vechit",
    contant1:
      "An innovative startup from the United Kingdom. The application allows you to easily Sell new or pre-loved items on Vechit for free!",
    contant2:
      "We have created an On demand marketplace app in which we have proposed appropriate functions to facilitate selling and shopping for the user. Our work amounted to 930 hours, during which we designed +240 screens in three different resolutions.",
    bg: "#F6F3FD",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit-app.webp",
    srcSet:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit-app-mobile.webp",
    p_link: "/case-study/vechit",
  },
  {
    id: "2",
    breadcrumb: "Mobile App / Website / Motion/ Branding",
    heading: "MySquard",
    contant1:
      "MySquard is a US-based marketplace app for Android and iOS that connects users with service providers for scheduled bookings.",
    contant2:
      "Built with React Native and Node.js, it ensures high performance, real-time tracking, and secure Stripe payments. With AWS-powered backend scalability and a user-friendly dashboard, MySquard streamlines on-demand services while enhancing user satisfaction.",
    bg: "#0c0b0b",
    color: "#fff",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard-app.webp",
    srcSet:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard-app-mobile.webp",
    p_link: "/case-study/mysquard",
  },
  {
    id: "3",
    breadcrumb: "Mobile App / Website / Motion",
    heading: "Astro Aakshar",
    contant1:
      "Astroaakshar is a cross-platform app offering astrology, numerology, horoscope, Kundli readings, and e-commerce for astro products.",
    contant2:
      "IT Services India developed the app with ReactJS and React Native, ensuring seamless user experience, global access, and scalability. With personalized services, user engagement grew 100%, product sales increased 50%, and retention reached 80%.",
    bg: "#F5C451",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar-app.webp",
    srcSet:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/astroaakshar-app-mobile.webp",
    p_link: "/case-study/astro-aakshar",
  },
  {
    id: "4",
    breadcrumb: "Mobile App / Website / Motion",
    heading: "Giddyup",
    contant1:
      "Canvasser Pro, developed with React Native, revolutionizes door-to-door canvassing. Featuring drag-and-drop scheduling, voice-to-text notes, lead qualification, and real-time reporting, it empowers campaigners with data-driven tools for seamless and productive campaigns.",
    contant2:
      "We have created an On demand marketplace app in which we have proposed appropriate functions to facilitate selling and shopping for the user. Our work amounted to 930 hours, during which we designed +240 screens in three different resolutions.",
    bg: "#F6F3FD",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/giddyup-roofing-software-application.webp",
    p_link: "/case-study/giddyup-canvasser",
  },
  {
    id: "5",
    breadcrumb: "Mobile App / Website / Motion/ Branding",
    heading: "Hoookedup",
    contant1:
      "HookedUp is a social media app with AV/VR integration, offering boomerang effects, video calls, and chat options. We created an immersive, seamless experience for users with smooth cross-platform performance and an intuitive interface.",
    contant2:
      "We have created an On demand marketplace app in which we have proposed appropriate functions to facilitate selling and shopping for the user. Our work amounted to 930 hours, during which we designed +240 screens in three different resolutions.",
    bg: "#0c0b0b",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Hookedup-app-image-processing.webp",
    p_link: "/case-study",
  },
];

export const ServicesCardArray = [
  {
    id: 1,
    name: "Retail",
  },
  {
    id: 2,
    name: "Health Care",
  },
  {
    id: 3,
    name: "Startups",
  },
  {
    id: 4,
    name: "Education/e-Learning",
  },
  {
    id: 5,
    name: "Real Estate",
  },
  {
    id: 6,
    name: "Travel and Hospitality",
  },
  {
    id: 7,
    name: "Logistics and Distribution",
  },
  {
    id: 8,
    name: "Media and Entertainment",
  },
];
export const MobileCardArray = [
  {
    id: 1,
    name: "Healthcare",
  },
  {
    id: 2,
    name: "Data analytics",
  },
  {
    id: 3,
    name: "Geoservice",
  },
  {
    id: 4,
    name: "Developer focused",
  },
  {
    id: 5,
    name: "AI-powered",
  },
  {
    id: 6,
    name: "Marketing",
  },
  {
    id: 7,
    name: "Fintech",
  },
  {
    id: 8,
    name: "Sales",
  },
];
export const TestimonialsSliderArray = [
  {
    id: 1,
    star: 5,
    contant:
      "ITservices India has built a reputation within Metas witch as supplying quality code at the exacting standards we expect of our own developers.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astrohepe-Portfolio-1.webp",
    name: "Leslie Alexander",
    position: "Senior Director of Engineering at afyasolution",
  },
  {
    id: 2,
    star: 5,
    contant:
      "ITservices India has built a reputation within Metas witch as supplying quality code at the exacting standards we expect of our own developers.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astrohepe-Portfolio-1.webp",
    name: "Leslie Alexander",
    position: "Senior Director of Engineering at afyasolution",
  },
  {
    id: 3,
    star: 5,
    contant:
      "ITservices India has built a reputation within Metas witch as supplying quality code at the exacting standards we expect of our own developers.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astrohepe-Portfolio-1.webp",
    name: "Leslie Alexander",
    position: "Senior Director of Engineering at afyasolution",
  },
  {
    id: 4,
    star: 5,
    contant:
      "ITservices India has built a reputation within Metas witch as supplying quality code at the exacting standards we expect of our own developers.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astrohepe-Portfolio-1.webp",
    name: "Leslie Alexander",
    position: "Senior Director of Engineering at afyasolution",
  },
];

export const AboutUsHayThisBody = [
  {
    id: "1",
    Title: "Quick and Curious",
    Text: "Straightforward, inquisitive, and simulated: this is how we approach every challenge.",
  },
  {
    id: "2",
    Title: "Think + Make",
    Text: "We dive deep into your business, goals, and audience with crucial discovery sessions.",
  },
  {
    id: "3",
    Title: "Quality Delivered in Time",
    Text: "We ensure timely, efficient delivery of high-quality projects in fast-paced settings.",
  },
];
export const OurCultureHayThisBody = [
  {
    id: "1",
    Title: "Quick and Curious",
    Text: "Straightforward, inquisitive, and simulated: this is how we approach every challenge.",
  },
  {
    id: "2",
    Title: "Think + Make",
    Text: "We dive deep into your business, goals, and audience with crucial discovery sessions.",
  },
  {
    id: "3",
    Title: "Quality Delivered in Time",
    Text: "We ensure timely, efficient delivery of high-quality projects in fast-paced settings.",
  },
];

export const ProjectTeamArray = [
  {
    id: 1,
    title: "Define the scope of work",
    inner:
      "Specify your requirements and, based on them, we select specialists in specific fields:",
    list: [
      {
        id: "1",
        name: "Branding and Motion Design",
      },
      {
        id: "2",
        name: "Product Design",
      },
      {
        id: "3",
        name: "Web Development",
      },
    ],
    label: "1",
  },
  {
    id: 2,
    title: "Project time and cost estimation",
    inner:
      "Specify your requirements and, based on them, we select specialists in specific fields:",
    list: [
      {
        id: "1",
        name: "Fixed Price",
      },
      {
        id: "2",
        name: "Time & Materials",
      },
    ],
    label: "2",
  },
  {
    id: 3,
    title: "Interview and first kick-off meeting",
    inner:
      "In the third phase, you will meet specialists and with our Account Manager to get to know you better.",
    list: [
      {
        id: "1",
        name: "Prepare agenda.",
      },
      {
        id: "2",
        name: "Discuss goals.",
      },
      {
        id: "3",
        name: "Follow up notes.",
      },
    ],
    inner2: "",

    label: "3",
  },
  {
    id: 4,
    title:
      "Ready? <a href='/lets-talk' class='text-hover' target='_self' >Let's start!</a>",
    inner:
      "After a short process and setting a starting date, we are ready to take on new challenges in your team. <br /> <br /> Regardless of whether you choose a dedicated PM or not, you will receive substantive and technical support all the time. ",
    inner2: "",
    label: "4",
  },
];

export const ProjectTeamArrayCopy = [
  {
    id: 1,
    title: "Scope of work",
    inner:
      "We understand your requirements and goals to define a clear scope of work. It eliminates guesswork and provides a robust framework to ascertain your project’s success.",
    list: [
      {
        id: "1",
        name: "Branding and Motion Design",
      },
      {
        id: "2",
        name: "Product Design",
      },
      {
        id: "3",
        name: "Web Development",
      },
    ],
    label: "1",
  },
  {
    id: 2,
    title: "Project Estimation",
    inner:
      "After learning about your budget and needs, we estimate the project cost and the timeline. This way; you will be in the know about everything that we do!",
    list: [
      {
        id: "1",
        name: "Fixed Price",
      },
      {
        id: "2",
        name: "Time & Materials",
      },
    ],
    label: "2",
  },
  {
    id: 3,
    title: "Choosing Your Team",
    inner:
      "Once on board with the project estimation and other details, we connect you with the best IT talent to help you achieve your business goals.",
    inner2: "",

    label: "3",
  },
  {
    id: 4,
    title: "Ready? Set Go!",
    inner:
      "We begin working on your project and provide you with regular updates to keep you in the know! With our support and maintenance services, we are with you even after deployment.",
    inner2: "",
    label: "4",
  },
];

export const LearnSkillsArray = [
  {
    id: "1",
    title: "Mobile App Development",
    subInner:
      "Band creation and all kinds of animations - check what we can offer:",
    color: "#F4CD7D",
    list: [
      {
        id: "1",
        name: "<a class='HUderLine' href='/ios-app-development-company' target='_blank'>iOS App Development</a>",
      },
      {
        id: "2",
        name: "<a class='HUderLine' href='/android-app-development-services' target='_blank'>Android App Development</a>",
      },
      {
        id: "3",
        name: "<a class='HUderLine' href='/cross-platform-app-development-company' target='_blank'>Cross Platform Development</a>",
      },
      {
        id: "4",
        name: "<a class='HUderLine' href='/flutter-app-development-services' target='_blank'>Flutter App Development</a>",
      },
      {
        id: "5",
        name: "<a class='HUderLine' href='/swift-app-development-company' target='_blank'>Swift App Development</a>",
      },
      {
        id: "6",
        name: "<a class='HUderLine' href='/application-maintenance-services' target='_blank'>App Maintenance & Support</a>",
      },
    ],
  },
  {
    id: "2",
    title: "Software Development",
    subInner:
      "Specify your requirements for the product - see what we can create:",
    color: "#BAE8EC",
    list: [
      {
        id: "1",
        name: "<a class='HUderLine' href='/custom-application-development-services' target='_blank'>Custom Software Development</a>",
      },
      {
        id: "2",
        name: "<a class='HUderLine' href='/saas-development-company' target='_blank'>SAAS Development</a>",
      },
      {
        id: "3",
        name: "<a class='HUderLine' href='/enterprise-software-development-solutions' target='_blank'>Enterprise Software Development</a>",
      },
      {
        id: "4",
        name: "<a class='HUderLine' href='/erpsoftware-development' target='_blank'>ERP Software Development</a>",
      },
      {
        id: "5",
        name: "<a class='HUderLine' href='/cloud-and-deveops' target='_blank'>Cloud & Devops</a>",
      },
      {
        id: "6",
        name: "<a class='HUderLine' href='/custom-crm-development' target='_blank'>Custom CRM Development</a>",
      },
      {
        id: "7",
        name: "<a class='HUderLine' href='/artificial-intelligence-development-company' target='_blank'>AI/ML Development</a>",
      },
    ],
  },
  {
    id: "3",
    title: "Web Development",
    subInner:
      "Coding websites and web applications - check what we can implement:",
    color: "#C4ECC8",
    list: [
      {
        id: "1",
        name: "<a class='HUderLine' href='/web-application-development-company' target='_blank'>Web App Development</a>",
      },
      {
        id: "2",
        name: "<a class='HUderLine' href='/ecommerce-development-services' target='_blank'>E-commerce Development</a>",
      },
      {
        id: "3",
        name: "<a class='HUderLine' href='/api-development-services' target='_blank'>API Development</a>",
      },
      {
        id: "4",
        name: "<a class='HUderLine' href='/front-end-development' target='_blank'>Frontend Development</a>",
      },
      {
        id: "5",
        name: "<a class='HUderLine' href='/custom-crm-development' target='_blank'>Backend Development</a>",
      },
      {
        id: "6",
        name: "<a class='HUderLine' href='/custom-crm-development' target='_blank'>Hire a Dedicated Developer</a>",
      },
    ],
  },
];
export const BlogLandingArray = [
  {
    id: 1,
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/web-app-pic-1-768x512.png",
    name: "Most popular design systems to learn from in 2022",
    label: "Design Systems",
  },
  {
    id: 2,
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/react-documentation-1-768x512.webp",
    name: "Understanding accessibility makes you a better",
    label: "Accessibility",
  },
  {
    id: 3,
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/blog-image-web-development-768x461.jpg",
    name: "15 best tools that will help you build your website",
    label: "Tech",
  },
];
export const FAQText = [
  {
    id: 1,
    text: "Do you do agile development?",
    subText:
      "As our client, you’ll have direct contact with each member of your development team. We use tools such as Slack, Jira, Confluence, e-mail, and videoconferencing to keep our clients up to date. ",
  },

  {
    id: 2,
    text: "What’s your experience in real-time communication technologies and frameworks?",
    subText: "You will earn $100 for every new customer you refer.",
  },
  {
    id: 3,
    text: "What number of dedicated software developers can you provide me with?",
    subText:
      "Refer as many friends as you like and start earning. To maximize your earnings, actively promote the MySquard Referral Program to your friends.",
  },
  {
    id: 4,
    text: "What are the benefits of a dedicated team?",
    subText:
      "We made a quick and hassle-free cashing out system! Once your account hits $250, request a withdrawal and receive your funds within days.",
  },
];
export const WeAppFAQText = [
  {
    id: 1,
    title:
      "Why should be I select IT Services India as my web application development company?",
    text: "One of the top web application development company in India, WE offers consultancy services for web development. In terms of web app creation and consulting, our business has established a reputable reputation.",
    subtext:
      "With the highest client retention rate for web development consulting and other digital services, we are one of India’s leading web development service providers. We can help you cut costs and development time by serving as your consulting partner for web development.",
  },
  {
    id: 2,
    title: "What level of expertise does your web development team have?",
    text: "The top web app developers and web development consulting team have a minimum of 10 years of expertise developing web apps. The committee staff at IT Services India, which consists of more than 50 technical specialists, is knowledgeable in modern technology that enables them to solve challenging business issues.",
  },
  {
    id: 3,
    title: "What types of web applications do you develop?",
    text: "At IT Services India, we specialize in developing a wide range of web applications tailored to suit various business needs. Our expertise includes e-commerce platforms, customer relationship management (CRM) systems, content management systems (CMS), enterprise web applications, social networking platforms, and more. Whether you need a simple single-page application or a complex, data-driven web solution, we have the experience and skills to deliver high-quality results.",
  },
  {
    id: 4,
    title: "Can I visit my website while it is still being developed?",
    text: "Yes, yeah. In fact, we support this practice. Your web application is developed on our development server. Then, we give you the username and password necessary to log in and keep track of the development of your website.",
  },
  {
    id: 5,
    title: "Is developing web applications the same as designing websites?",
    text: "There is a distinction. Programmes like Adobe Photoshop are created by web designers to construct the web application’s layout and other visual components. Additionally, to add functionality to the design, the web development team employs a variety of software coding languages, including HTML, CSS, JavaScript, PHP, and others.",
  },
  {
    id: 6,
    title: "How long does it take to develop a website?",
    text: "What is the average time it takes to create a website? is a common query. Of course, the answer is based on the website’s size and level of competence. However, the majority of people could be spending anywhere between 20 and 40 hours developing a website, according to estimations from various sources.",
    subtext:
      "Depending on how much time you have, you could spread that out over a few weeks or months. Of course, you’ll need to account for the expense of hiring a web developer if you’re not doing the work yourself. However, you can definitely construct your own website if you’re ready to put in the time and work.",
  },
  {
    id: 7,
    title: "How do you ensure the security of the web applications you build?",
    text: "Security is our top priority when developing web applications. We follow industry best practices and utilize the latest security measures to safeguard your data and protect against potential threats. Our development team implements secure coding practices; conduct regular security audits, and employs encryption protocols to ensure that your web application remains protected from vulnerabilities and cyber-attacks. Rest assured your business and user data are in safe hands with us.",
  },
  {
    id: 8,
    title:
      "Can you handle the entire web application development process, from concept to launch?",
    text: "Absolutely! Our team at IT Services India is a full-service web application development company. We provide end-to-end solutions, starting from the initial concept and ideation phase, through the design and development process, to final testing and deployment. We believe in close collaboration with our clients, and your input is crucial to delivering a product that aligns perfectly with your vision and requirements. After the launch, we also offer ongoing support and maintenance services to ensure your web application continues to perform at its best.",
  },
];
export const OffshoreCardArray = [
  {
    id: "1",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-1.svg",
    title: "Custom Solutions",
    content:
      "We are seasoned professionals with expertise in developing tailored software solutions. Let us bring your vision to life!",
  },
  {
    id: "2",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-2.svg",
    title: "Flexible Hiring Models",
    content:
      "Depending on your project requirements and budget, find diverse hiring options to access the best pool of IT talent.",
  },
  {
    id: "3",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/pag3.svg",
    title: "Cost-Effective",
    content:
      "Our budget-friendly approach to software development guarantees exceptional value without compromising on quality.",
  },
  {
    id: "4",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-4.svg",
    title: "Client-First Approach",
    content:
      "At IT Services India, client needs come first & foremost! We welcome open communication to deliver the utmost satisfaction.",
  },
  {
    id: "5",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Page-5.svg",
    title: "Highly Scalable",
    content:
      "Our software solutions are scalable and flexible, thus allowing your business to adapt seamlessly to the changing environment.",
  },
  {
    id: "6",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/page6.svg",
    title: "Quality Assurance",
    content:
      "Quality is at the core of everything we do. Our QA team will test every aspect to identify and resolve issues before deployment.",
  },
  {
    id: "7",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/page-7.svg",
    title: "Agile Methodology",
    content:
      "With our agile methodologies, we adapt to changing business requirements swiftly and efficiently to deliver projects on time.",
  },
  {
    id: "8",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/page8.svg",
    title: "Continuous Support",
    content:
      "Our commitment doesn't end with deployment. Our ongoing support & maintenance services are with you every step of the way.",
  },
];
export const AIMLPeopleMap = [
  {
    id: "1",
    bg: "#FAFAFA",
    title: "Define your product goals",
    name: "We collaborate closely with your team to set clear, actionable goals specialized to your user insights and benefit you.",
  },
  {
    id: "2",
    bg: "#000000",
    color: "#FFFFFF",
    title: "Create a feedback loop",
    name: "We design interactive experiences that help AI learn from user behavior & adapt to their needs. It helps in better outputs.",
  },
  {
    id: "3",
    bg: "#FFA2D1",
    title: "Design for adaptation",
    name: "Our Artificial Intelligence Solutions continually evolve to provide relevant outcomes, ensuring your product stays at the top and demand.",
  },
  {
    id: "4",
    bg: "#FAFAFA",
    title: "We help you to grow using AI",
    name: "We enable users to refine and improve AI outputs, ensuring accurate and valuable results using our AI solutions.",
  },
  {
    id: "5",
    bg: "#FAFAFA",
    title: "Make your user interface context-aware",
    name: "We design user-friendly interfaces that consider the context of your product’s usage to maximize its efficiency and optimal performance.",
  },
  {
    id: "6",
    bg: "#CCF095",
    color: "#000",
    title: "Call us now-",
    name: "Book a free three day trial",
  },
];
export const WehaveKnackArray = [
  {
    id: "1",
    bg: "#FAFAFA",
    name: "Creating engaging features that keep users active and encourage them to stay.",
  },
  {
    id: "2",
    bg: "#000000",
    color: "#FFFFFF",
    name: "Creating onboarding solutions that help new users quickly understand and interact with the product.",
  },
  {
    id: "3",
    bg: "#FFA2D1",
    name: "Developing systems to capture and integrate user feedback into your product functionality.",
  },
  {
    id: "4",
    bg: "#FAFAFA",
    name: "Enhancing collaboration across teams with a well-structured development system.",
  },
  {
    id: "5",
    bg: "#FAFAFA",
    name: "Expanding the product line with new features to improve customer satisfaction.",
  },
  {
    id: "6",
    bg: "#1D1E22",
    color: "#F6F4F2",
    name: "“They took the time to thoroughly understand our specific needs. With the new system, the client reduced stockouts, enhanced their supply chain, and fulfilled orders more efficiently and accurately.”",
  },
];
export const OurClientSuccessArray = [
  {
    id: "1",
    bg: "#1D1E22",
    color: "#FFFFFF",
    heading:
      "Vechit: A UK-Based Marketplace Redefining Seamless Selling and Shopping Experiences",
    inner:
      "A UK-based innovative startup, Vechit lets users sell new or pre-loved items for free! We built an on-demand marketplace app, dedicating 930 hours to the process and designing 240+ screens across three resolutions for optimal functionality.",
    Rightheading: "Node.JS / Next.JS / MongoDB / React.JS / AWS /Headless CMS",
    imgs: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_05.png",
    link: "/case-study/vechit",
  },
  {
    id: "2",
    bg: "#F6F4F2",
    color: "#000000",
    heading:
      "MySquard an On Demand Marketplace App Development by IT Services India",
    inner:
      "MySquard is a US marketplace app connecting users with service providers. Built with React Native and Node.js, it offers performance, real-time tracking, secure Stripe payments, and scalable AWS infrastructure for optimal operations.",
    Rightheading: "Node.JS / Next.JS / MongoDB / React.JS / AWS /Headless CMS",
    imgs: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Mysquard-dashboard.webp",
    link: "/case-study/mysquard",
  },
];

export const OurClientWebSuccessArray = [
  {
    id: "1",
    bg: "#1D1E22",
    color: "#FFFFFF",
    heading:
      "Vechit: A UK-Based Marketplace Redefining Seamless Selling and Shopping Experiences",
    inner:
      "A UK-based innovative startup, Vechit lets users sell new or pre-loved items for free! We built an on-demand marketplace app, dedicating 930 hours to the process and designing 240+ screens across three resolutions for optimal functionality.",
    Rightheading: "Node.JS / Next.JS / MongoDB / React.JS / AWS /Headless CMS",
    imgs: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_05.png",
    link: "/case-study/vechit",
  },
  {
    id: "2",
    bg: "#F6F4F2",
    color: "#000000",
    heading:
      "MySquard an On Demand Marketplace App Development by IT Services India",
    inner:
      "MySquard is a US marketplace app connecting users with service providers. Built with React Native and Node.js, it offers performance, real-time tracking, secure Stripe payments, and scalable AWS infrastructure for optimal operations.",
    Rightheading: "Node.JS / Next.JS / MongoDB / React.JS / AWS /Headless CMS",
    imgs: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Mysquard-dashboard.webp",
    link: "/case-study/mysquard",
  },
];

export const BenefitsArray = [
  {
    id: "1",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "Smooth Software Team Extension",
    inner:
      "We emphasize strong teamwork and smooth interaction between your local and remote software development units.",
  },
  {
    id: "2",
    bg: "#000000",
    color: "#FFFFFF",
    heading: "A Client-First Approach",
    inner:
      "We focus on balancing technical know-how, soft skills, and additional qualifications that you deem important.",
  },
  {
    id: "3",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "You and Your Team Stay in Sync.",
    inner:
      "Our method for extending your <a class='HUderLine' href='/hire-dedicated-developers' target='_blank'>Web App development team</a> lets you stay involved and manage your squad directly, giving you full control over your team and the SDLC process.",
  },
  {
    id: "4",
    bg: "#FFA2D1",
    color: "#000000",
    heading: "Relevant Candidates",
    inner:
      "With our software development team extension services, you benefit from our reputation as a premier employer. You can <a class='HUderLine' href='/hire-dedicated-developers' target='_blank'>hire dedicated web developer</a> candidates who have passed our assessments in technical skills, soft skills, and English proficiency.",
  },
];

export const Faqs = [
  {
    id: "1",
    question: "How do you make \nyour app a value add\nto your SaaS?",
    answer:
      "In the last year, almost 60% of all web traffic came through mobile.  When phone usage grows in leaps and bounds, a poor mobile experience can be a shotin the foot for your business.",
  },
  {
    id: "2",
    question:
      "How do you simplify  \nyour app's functionality \nto fit on a mobile screen?",
    answer:
      "Squeezing a web app into a mobile version is like trying to stuff your whole wardrobe into a single suitcase. You need to be strategic about what features you include and how you pack them.",
  },
  {
    id: "3",
    question: "How do you keep users \nhappy regardless\n of the screen size?",
    answer:
      "Ensuring that your app is optimized for all of these different devices and screen sizes can be challenging. That requires an experienced designer who can make app layouts responsive and adaptive.",
  },
  {
    id: "4",
    question: "Who is \nIT Services India?",
    answer:
      "IT Services India is a recognized software development company serving multinational businesses and entrepreneurial startups across the globe. Started in 2010, the company is home to more than 70 IT professionals with expertise in UI/UX, enterprise software development, mobile app development, website development, and everything else related to getting your business online and making it a success.",
  },
  {
    id: "5",
    question: "What can I expect \nfrom IT Services India?",
    answer:
      "We are a team of IT professionals and managers who help your business grow to the next level. With our expertise in tech and business management, we understand the business-side things to ensure maximum ROI for your project. \n\nWhether you need skilled developers for software development or require help with the maintenance of your existing IT system, we can customize our solutions to address your needs.",
  },
  {
    id: "6",
    question: "How do you ensure \nthe Security of your Clients?",
    answer:
      "We believe in total client security. Before working on your project, we sign an NDA clause to protect your rights.",
  },
  {
    id: "7",
    question: "Do I have Direct \nContact with the Development Team?",
    answer:
      "Yes. You can communicate with the development team to ensure quick and efficient working. You can also participate in our weekly standups to direct the team personally.",
  },
  {
    id: "8",
    question: "Do you provide \nSupport after Deployment?",
    answer:
      "Yes. We provide support and maintenance services after project deployment. Whether it is fixing a bug or a framework upgrade, you can discuss your needs with us.",
  },
];

export const MobileAppFaqs = [
  {
    id: "1",
    question: "How do you make \nyour app a value add\nto your SaaS?",
    answer:
      "In the last year, almost 60% of all web traffic came through mobile.  When phone usage grows in leaps and bounds, a poor mobile experience can be a shotin the foot for your business.",
  },
  {
    id: "2",
    question:
      "How do you simplify  \nyour app's functionality \nto fit on a mobile screen?",
    answer:
      "Squeezing a web app into a mobile version is like trying to stuff your whole wardrobe into a single suitcase. You need to be strategic about what features you include and how you pack them.",
  },
  {
    id: "3",
    question: "How do you keep users \nhappy regardless\n of the screen size?",
    answer:
      "Ensuring that your app is optimized for all of these different devices and screen sizes can be challenging. That requires an experienced designer who can make app layouts responsive and adaptive.",
  },
];

export const HomePageFaqs = [
  {
    id: "4",
    question: "How do you ensure the security of web applications?",
    answer:
      "We implement robust security measures such as encryption, secure APIs, and regular vulnerability testing to ensure the protection of your web applications and user data.",
  },
  {
    id: "5",
    question: "Why should I choose your IT company for mobile app development?",
    answer:
      "With over 15 years of experience, our team delivers high-quality, user-friendly mobile apps optimized for performance, security, and scalability, tailored to your business goals.",
  },
  {
    id: "6",
    question: "Do you offer ongoing support after app development?",
    answer:
      "Yes, we provide ongoing <a class='HUderLine' href='/application-maintenance-services' target='_blank' >maintenance and support</a> to ensure your app runs smoothly, with updates, bug fixes, and enhancements based on your business needs.",
  },
  {
    id: "7",
    question: "How much does custom software development cost?",
    answer:
      "The cost of custom software development varies based on the project’s scope, complexity, and features. We offer competitive pricing and will provide a <a class='HUderLine' href='/lets-talk' target='_blank' >custom quote</a> based on your requirements.",
  },
  {
    id: "8",
    question: "What technologies do you use for web development?",
    answer:
      "We use a range of modern technologies like HTML5, CSS3, JavaScript, <a class='HUderLine' href='/react-js-development-company' target='_blank' >React</a>, <a class='HUderLine' href='/angular-js-development-company' target='_blank' >Angular</a>, and Node.js to create responsive, scalable, and user-friendly web applications.",
  },
  {
    id: "9",
    question:
      "What are the advantages of  <a class='text-hover' href='/progressive-web-app-development' target='_blank' >Progressive Web Apps</a> (PWAs)?",
    answer:
      "PWAs offer enhanced user experiences, faster load times, offline access, and can be installed on devices, bridging the gap between websites and native apps.",
  },
  {
    id: "10",
    question:
      "Can you integrate third-party services with my software or website?",
    answer:
      "Yes, we specialize in integrating third-party services such as payment gateways, CRM systems, and marketing tools to enhance the functionality of your software or website.",
  },
  {
    id: "11",
    question:
      "What is the difference between web development and software development?",
    answer:
      "Web development focuses on creating websites and web applications, while software development includes building custom applications, desktop software, and enterprise solutions.",
  },
  {
    id: "12",
    question: "How do you ensure the quality of your mobile apps?",
    answer:
      "We follow rigorous quality assurance processes, including manual testing, automation testing, and user feedback integration, to deliver high-performing, bug-free mobile apps.",
  },
];

export const ContactFaqs = [
  {
    id: "4",
    question: "Can you help with app maintenance and updates?",
    answer:
      "Absolutely! IT Services India provides ongoing maintenance and support for all applications we develop, including updates, bug fixes, and feature enhancements to keep your app functional and up-to-date.",
  },
  {
    id: "5",
    question: "What is your approach to project management?",
    answer:
      "At IT Services India, we follow an agile project management methodology, ensuring collaboration and flexibility. This allows us to adapt quickly while keeping you informed and delivering high-quality results.",
  },
  {
    id: "6",
    question: "Do you have experience working with startups?",
    answer:
      "Yes, IT Services India has extensive experience working with startups in various industries. We provide tailored solutions to help bring your startup ideas to life while managing budget constraints.",
  },
  {
    id: "7",
    question: "How do you ensure the security of my application?",
    answer:
      "IT Services India implements best security practices, including data encryption, secure coding, and regular audits. This ensures that your application is protected from vulnerabilities and security threats.",
  },
  {
    id: "8",
    question: "Can you assist with digital marketing after my app is launched?",
    answer:
      "Yes, IT Services India offers digital marketing services to promote your app post-launch. Our solutions include SEO, social media marketing, and targeted advertising to boost visibility and downloads.",
  },
  {
    id: "9",
    question: "Can I see examples of your previous work?",
    answer:
      "Yes! IT Services India is happy to share case studies and examples of previous projects. These highlight our expertise in app development, web solutions, and custom software.",
  },
  {
    id: "10",
    question: "How do I get started with my project?",
    answer:
      "To get started, simply fill out the contact form on our 'Contact Us' page or reach out via email or phone. IT Services India will schedule a consultation to discuss your project ideas and outline the next steps.",
  },
  {
    id: "11",
    question: "What if I have questions not covered in this FAQ?",
    answer:
      "Feel free to contact IT Services India directly through our contact form or support channels. We’re here to address any additional questions or provide further clarification on any topic.",
  },
  {
    id: "12",
    question: "How do you handle client feedback during the project?",
    answer:
      "IT Services India values client feedback. We encourage open communication and regular check-ins to ensure the final product aligns with your vision and meets your expectations.",
  },
  {
    id: "13",
    question: "What sets your IT company apart from others?",
    answer:
      "IT Services India stands out for its commitment to quality, innovation, and customer satisfaction. We tailor our services to each client’s needs while leveraging cutting-edge technology for optimal results.",
  },
];

export const LetsTalkFaqs = [
  {
    id: "2",
    question: "What should I include in my initial message?",
    answer:
      "In your initial message, please include a brief description of your project, your key requirements, and any specific goals you have. This helps us understand your needs better.",
  },
  {
    id: "3",
    question: "How soon will I hear back from you?",
    answer:
      "We typically respond within 24-48 hours. If you need immediate assistance, please mention it in your message.",
  },
  {
    id: "4",
    question: "Can I schedule a call to discuss my project?",
    answer:
      "Yes, absolutely! Once we receive your details, we can arrange a call to discuss your project in more depth and answer any questions you may have.",
  },
  {
    id: "5",
    question: "Is there a fee for the initial consultation?",
    answer:
      "No, the initial consultation is completely free. We’ll discuss your requirements and explore how we can assist you without any obligation.",
  },
  {
    id: "6",
    question: "How does the NDA work?",
    answer:
      "Our Non-Disclosure Agreement (NDA) ensures that your business ideas and information remain confidential. We will share the NDA upon request before any detailed discussions begin.",
  },
  {
    id: "7",
    question: "What happens after we share our requirements?",
    answer:
      "After receiving your requirements, our expert team will review them and contact you to discuss the best approach and next steps for your project.",
  },
  {
    id: "8",
    question: "Can I make changes to my project requirements later?",
    answer:
      "Yes, absolutely! Once we receive your details, we can arrange a call to discuss your project in more depth and answer any questions you may have.",
  },
  {
    id: "9",
    question: "How do I get started?",
    answer:
      "Simply fill out the contact form on our ''Let's Talk'' page or reach out via email or phone. We’ll get in touch to schedule a consultation and discuss your project in detail.",
  },
  {
    id: "10",
    question: "What industries do you work with?",
    answer:
      "We work with a wide range of industries, including <a class='HUderLine' href='/ecommerce-development-services' target='_blank'>e-commerce</a>, <a class='HUderLine' href='/healthcare-software-development-services' target='_blank'>healthcare</a>, <a class='HUderLine' href='/travel-app-development-company' target='_blank'>Travel & Hospitality</a>, and more. Our solutions are tailored to meet the unique needs of each sector.",
  },
  {
    id: "11",
    question: "How do you ensure the quality of your work?",
    answer:
      "We follow best practices and industry standards to ensure high-quality results.<a class='HUderLine' href='/ecommerce-development-services' target='_blank'>Our team employs</a>rigorous testing and quality assurance processes throughout the development lifecycle.",
  },
];

export const LestTalkImagesLogo = [
  {
    id: "1",
    url: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/oh_logo_01-min-1.png",
  },
  {
    id: "2",
    url: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/oh_logo_02-min.png",
  },
  {
    id: "3",
    url: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/oh_logo_03-min-1.png",
  },
  {
    id: "4",
    url: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/oh_logo_04-min-1.png",
  },
  {
    id: "5",
    url: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/oh_logo_05-min.png",
  },
  {
    id: "6",
    url: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/oh_logo_06-min-1.png",
  },
  {
    id: "7",
    url: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/oh_logo_07-min-1.png",
  },
  {
    id: "8",
    url: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/oh_logo_08-min.png",
  },
];

export const LetsTalkSec = [
  {
    id: "1",
    title: "Share Your  Requirement",
    content:
      "We keenly analyze your requirements from the beginning for a seamless development process.",
  },
  {
    id: "2",
    title: "Non Disclosure  Agreement (NDA)",
    content:
      "Your business ideas are always safe with us. We assure you of complete confidentiality with NDA.",
  },
  {
    id: "3",
    title: "Understanding Your Requirement",
    content:
      "Once we have your requirement, will allocate our expert team consultation to choose the approach.",
  },
];
export const LetsTalkSocialSec = [
  {
    id: "1",
    title: "General inquiries",
    content:
      "<a href='mailto:info@itservicesindia.com'>info@itservicesindia.com</a>",
  },
  {
    id: "2",
    title: "Phone Number",
    content: "<a href='tel:91 966254 3857'>+ 91 966254 3857</a>",
  },
  {
    id: "3",
    title: "Hangout",
    content: "hello@itservicesindia.com",
  },
  {
    id: "4",
    title: "Skype",
    content: "<a href='skype:itservicesindia?chat'>itservicesindia</a>",
  },
];

export const TestimonialsArray = [
  {
    id: "1",
    content:
      "IT Services lndia was tasked with taking a concept for a mobile dating app, designing it, developing it, and testing it. The concept evolved over time into a very robust, full-featured dating app. In addition, I asked them to act as product managers, developing new, functional product ideas",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Dennis-Hefter-user-avatar.jpg",
    uname: "Dennis Hefter",
    position: "Founder, TruuBlue",
    bg: "#1d1e22",
  },
  {
    id: "2",
    content:
      "IT Services India delivered a high-quality app that improved the client's operational efficiency, user engagement, and customer satisfaction. The team was flexible, adaptive, and customer-centric. Moreover, IT Services India's project management and communication were excellent.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/andrzej-ciesielski.webp",
    uname: "Andrzej Ciesielski",
    position: "Owner, Furgonetka Sp. z o. o. Sp. k.",
    bg: "#FCFCFC",
    color: "000000",
  },
  {
    id: "3",
    content:
      "I wasn't sure which technology to choose, IT Services lndia recommended me the right tech stack and asked me to pick. I picked MERN Stack and I believe they did the right thing.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Jacob-Webb-user-avatar.jpg",
    uname: "Jacob Webb",
    position: "Founder, Starlight",
  },
];

export const TestimonialsArrayTwo = [
  {
    id: "1",
    content:
      "It started with the creation of BEP20 and ERC20 tokens. After this, the bridge, ICO and Vesting contracts were created. In the investor panel, we created the metamask and wallet connect. To track analytics, sale details and progress, the investor dashboard was created. The token section included.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Chris-Crough-user-avatar.jpg",
    uname: "Chris Crough",
    position: "CEO, First Financial",
    bg: "#FCFCFC",
    color: "000000",
  },
  {
    id: "2",
    content:
      "We hired IT Services lndia to help us with the front- and backend development of our mobile app. They built it in React Native, leveraging Firebase in the Google Cloud for a lot of our backend services. The app required third-party integrations with services like Stripe for payments and Twilio..",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Dennis-Stauffer-user-avatar.jpg",
    uname: "Dennis Stauffer",
    position: "Data Analyst , overDrive",
  },
  // {
  //   id: "3",
  //   content: "",
  //   img: "",
  //   uname: "",
  //   position: "",
  //   bg: "#FCFCFC",
  //   color: "",
  // },
  {
    id: "4",
    content:
      "We first went through a discovery phase with the IT Services lndia team. We defined our business process and goals, and they went into developing an application that could help us better target our audience. The project is ongoing, and there are multiple phases in the pipeline.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Eric-Ryan-user-avatar.jpg",
    uname: "Eric Ryan",
    position: "Co- founder, NASCAR",
    bg: "#FCFCFC",
    color: "000000",
  },
];

export const TestimonialsArrayThree = [
  {
    id: "1",
    content:
      "IT Services lndia has been the driver for our app development as well as our desktop and online applications. They have solved problems, addressed our testing concerns and helped develop the app in a user-friendly way that addressed our consumers' needs.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kevin-Edward-user-avatar.jpg",
    uname: "Kevin Edward",
    position: "Co-Founder | CEO, ProfolioX",
    bg: "#1d1e22",
  },
  {
    id: "2",
    content:
      "IT Services lndia has created a custom web app that functions as a portfolio for athlete stock shares. Everything is on the cloud, which meant that their team needed to build out the pipelines to get information from our data provider into our architecture.Additionally, theyve.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Rick-Blaisdell-user-avatar.jpg",
    uname: "Rick Blaisdell",
    position: "founder, Rick AI Cloud",
    bg: "#FCFCFC",
    color: "000000",
  },
  {
    id: "3",
    content:
      "We recently had the pleasure of working with IT Services India to create our new website, and we couldn't be happier with the results! They delivered exactly what we envisioned.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Morgan-Gregory-user-avatar.jpg",
    uname: "Morgan Gregory",
    position: "CEO & Co-founder, Alltold",
  },
];
export const TestimonialsArraycontact = [
  {
    id: "1",
    inner_contant: [
      {
        id: "1",
        content:
          "IT Services lndia has been the driver for our app development as well as our desktop and online applications. They have solved problems, addressed our testing concerns and helped develop the app in a user-friendly way that addressed our consumers' needs.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kevin-Edward-user-avatar.jpg",
        uname: "Kevin Edward",
        position: "Co-Founder | CEO, ProfolioX",
        bg: "#1d1e22",
      },
      {
        id: "2",
        content:
          "IT Services India delivered a high-quality app that improved the client's operational efficiency, user engagement, and customer satisfaction. The team was flexible, adaptive, and customer-centric. Moreover, IT Services India's project management and communication were excellent.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/andrzej-ciesielski.webp",
        uname: "Andrzej Ciesielski",
        position: "Owner, Furgonetka Sp. z o. o. Sp. k.",
        bg: "#FCFCFC",
        color: "000000",
      },
      {
        id: "3",
        content:
          "IT Services lndia has created a custom web app that functions as a portfolio for athlete stock shares. Everything is on the cloud, which meant that their team needed to build out the pipelines to get information from our data provider into our architecture.Additionally, theyve.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Rick-Blaisdell-user-avatar.jpg",
        uname: "Rick Blaisdell",
        position: "founder, Rick AI Cloud",
      },
    ],
  },
  {
    id: "2",
    inner_contant: [
      {
        id: "1",
        content:
          "We recently had the pleasure of working with IT Services India to create our new website, and we couldn't be happier with the results! They delivered exactly what we envisioned.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Morgan-Gregory-user-avatar.jpg",
        uname: "Morgan Gregory",
        position: "CEO & Co-founder, Alltold",
        bg: "#FCFCFC",
        color: "000000",
      },
      {
        id: "2",
        content:
          "It started with the creation of BEP20 and ERC20 tokens. After this, the bridge, ICO and Vesting contracts were created. In the investor panel, we created the metamask and wallet connect. To track analytics, sale details and progress, the investor dashboard was created. The token section included.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Chris-Crough-user-avatar.jpg",
        uname: "Chris Crough",
        position: "CEO, First Financial",
      },
      {
        id: "3",
        content:
          "We hired IT Services lndia to help us with the front- and backend development of our mobile app. They built it in React Native, leveraging Firebase in the Google Cloud for a lot of our backend services. The app required third-party integrations with services like Stripe for payments and Twilio..",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Dennis-Stauffer-user-avatar.jpg",
        uname: "Dennis Stauffer",
        position: "Data Analyst , overDrive",
        bg: "#FCFCFC",
        color: "000000",
      },
    ],
  },
  {
    id: "3",
    inner_contant: [
      {
        id: "1",
        bg: "#1D1E22",
        color: "#FFF",
        content:
          "“IT Services India successfully delivered the project on time and within budget, and the client was pleased with the results. The team communicated effectively via Slack and email. Their collaborative approach and.”",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/david-watts.webp",
        uname: "David Watts, \n Chief Commercial Officer",
      },
      {
        id: "2",
        bg: "#1D1E22",
        color: "#FFF",
        content:
          "“They took the time to thoroughly understand our specific needs. With the new system, the client reduced stockouts, enhanced their supply chain, and fulfilled orders more efficiently and accurately.”",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/auth-img-1.webp",
        uname: "Barry Fish,\n MD",
      },
      {
        id: "3",
        content:
          "We first went through a discovery phase with the IT Services lndia team. We defined our business process and goals, and they went into developing an application that could help us better target our audience. The project is ongoing, and there are multiple phases in the pipeline.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Eric-Ryan-user-avatar.jpg",
        uname: "Eric Ryan",
        position: "Co- founder, NASCAR",
        bg: "#FCFCFC",
        color: "000000",
      },
    ],
  },
  {
    id: "4",
    inner_contant: [
      {
        id: "1",
        content:
          "IT Services lndia was tasked with taking a concept for a mobile dating app, designing it, developing it, and testing it. The concept evolved over time into a very robust, full-featured dating app. In addition, I asked them to act as product managers, developing new, functional product ideas",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Dennis-Hefter-user-avatar.jpg",
        uname: "Dennis Hefter",
        position: "Founder, TruuBlue",
        bg: "#1d1e22",
      },
      {
        id: "2",
        content:
          "I wasn't sure which technology to choose, IT Services lndia recommended me the right tech stack and asked me to pick. I picked MERN Stack and I believe they did the right thing.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Jacob-Webb-user-avatar.jpg",
        uname: "Jacob Webb",
        position: "Founder, Starlight",
        bg: "#FCFCFC",
        color: "000000",
      },
      {
        id: "3",
        content:
          "IT Services lndia has helped us develop our custom software system. Before we signed the contract, we had initial pre-sales meetings, during which I provided them with documentation.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/brian-sikes.webp",
        uname: "Graham Edward",
        position: "Managing Director, Holbeck Viaduct",
      },
    ],
  },
  {
    id: "5",
    inner_contant: [
      {
        id: "1",
        content:
          "Their timeliness and their solid project management process are hallmarks of their work. Above all, IT SERVICES INDIA is committed, flexible, and creative, which allows them to establish a collaborative and fruitful partnership.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Ariane-Gorin-user-avatar.jpg",
        uname: "Ariane Gorin",
        position: "CEO, Expedia Group",
        bg: "#FCFCFC",
        color: "000000",
      },
      {
        id: "2",
        content:
          "We signed a contract with them to develop an app that brings fun to fitness through: -Simplified process of booking on-demand fitness trainers and participating in amateur fitness activities events -One-stop-shop.",
        img: "https://media.designrush.com/agency_reviews/748515/conversions/1733876298346-user-avatar.jpg",
        uname: "Paul Chesher",
        position: "Concert Promoter, Di Touring",
      },
      {
        id: "3",
        content:
          "IT SERVICES INDIA has been providing custom development services for us, focusing on TypeScript and Node.js. We describe the project and our requirements so they can look for resources that fit our wants.IT DERVICES INDIA has great expertise in development, and the products they've built for us are very easy to scale or down.",
        img: "https://media.designrush.com/agency_reviews/746596/conversions/1733539451089-user-avatar.jpg",
        uname: "Robel Yemane",
        position: "Head of Engineering , Privilee",
        bg: "#FCFCFC",
        color: "000000",
      },
    ],
  },
];

export const PortfolioMobileArray = [
  {
    id: "1",
    bg: "#1D1E22",
    color: "#FFFFFF",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/MySquard-Two-mobile-full.webp",
    srcSet:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/MySquard-Two-mobile-full.webp",
    topText: "React Native / Next js / Node Js / AWS / Mangodb / Headless CMS",
    Heading:
      "MySquard an On Demand Marketplace App Development by IT Services India",
    subText:
      "MySquard is a US marketplace app connecting users with service providers. Built with React Native and Node.js, it offers performance, real-time tracking, secure Stripe payments, and scalable AWS infrastructure for optimal operations.",
    link: "/case-study/mysquard",

  },
  // {
  //   id: "2",
  //   bg: "#F7F8FA",
  //   color: "#000000",
  //   img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Hookedup-app-image-processing.webp",
  //   topText: "Node.JS / Next.JS / MongoDB / React.JS / AWS / Headless CMS",
  //   Heading: "Realestate Web Development by IT Services India for SkyHub",
  //   subText:
  //     "SKYHUB, Canada’s leading real estate platform, solved data issues, performance challenges, and scalability with real-time updates, optimized backend, and advanced tech, ensuring seamless property searches and an enhanced user experience.",
  // link: "/case-study/skyhub", 
  // 
  // },
  {
    id: "3",
    bg: "#D3EE9F",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/giddyup-roofing-software-application.webp",
    topText: "Node.JS / Next.JS / MongoDB / React.JS / AWS / Headless CMS",
    Heading:
      "Canvasser Pro: Smart Scheduling & Reporting for Better Door-to-Door Canvassing",
    subText:
      "Canvasser Pro, crafted with React Native, redefines door-to-door canvassing. With drag-and-drop scheduling, voice-to-text notes, lead tracking, and real-time insights, it equips campaigners with powerful tools for efficient and effective outreach.",
    link: "/case-study/giddyup-canvasser",
  },
  {
    id: "4",
    bg: "#F6F3FD",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit-application-040325.webp",
    srcSet: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit-application-040325.webp",
    topText: "Node.JS / Next.JS / MongoDB / React.JS / AWS / Headless CMS",
    Heading:
      "Vechit: A UK-Based Marketplace Redefining Seamless Selling and Shopping Experiences",
    subText:
      "A UK-based innovative startup, Vechit lets users sell new or pre-loved items for free! We built an on-demand marketplace app, dedicating 930 hours to the process and designing 240+ screens across three resolutions for optimal functionality.",
    link: "/case-study/vechit",
  },
  {
    id: "5",
    bg: "#F5C451",
    color: "#000",
    img: "https://wpapi.itservicesindia.com/wp-content/uploads/2025/03/astrologer.png",
    srcSet:
      "https://wpapi.itservicesindia.com/wp-content/uploads/2025/03/astrologer.png",
    topText: "Node.JS / Next.JS / MongoDB / React.JS / AWS / Headless CMS",
    Heading:
      "Astro Aakshar an Astrology Mobile App Developed by IT Services India",
    subText:
      "Astroaakshar, developed by IT Services India, is a cross-platform app offering astrology, numerology, horoscope, and Kundli services, along with astro product e-commerce. Built with ReactJS and React Native, it ensures a seamless user experience.",
    class: "hideImg",
    link: "/case-study/astro-aakshar",

  },
];

export const PortfolioMobileCaseStudyArray = [
  {
    id: "1",
    bg: "#1D1E22",
    color: "#FFFFFF",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/MySquard-Two-mobile-full.webp",
    srcSet:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/MySquard-Two-mobile-full.webp",
    topText: "React Native / Next js / Node Js / AWS / Mangodb / Headless CMS",
    Heading:
      "MySquard an On Demand Marketplace App Development by IT Services India",
    subText:
      "MySquard is a US marketplace app connecting users with service providers. Built with React Native and Node.js, it offers performance, real-time tracking, secure Stripe payments, and scalable AWS infrastructure for optimal operations.",
    link: "/case-study/mysquard",

  },
  // {
  //   id: "2",
  //   bg: "#F7F8FA",
  //   color: "#000000",
  //   img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Hookedup-app-image-processing.webp",
  //   topText: "Node.JS / Next.JS / MongoDB / React.JS / AWS / Headless CMS",
  //   Heading: "Realestate Web Development by IT Services India for SkyHub",
  //   subText:
  //     "SKYHUB, Canada’s leading real estate platform, solved data issues, performance challenges, and scalability with real-time updates, optimized backend, and advanced tech, ensuring seamless property searches and an enhanced user experience.",
  // link: "/case-study/skyhub", 
  // 
  // },
  {
    id: "3",
    bg: "#D3EE9F",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/giddyup-roofing-software-application.webp",
    topText: "Node.JS / Next.JS / MongoDB / React.JS / AWS / Headless CMS",
    Heading:
      "Canvasser Pro: Smart Scheduling & Reporting for Better Door-to-Door Canvassing",
    subText:
      "Canvasser Pro, crafted with React Native, redefines door-to-door canvassing. With drag-and-drop scheduling, voice-to-text notes, lead tracking, and real-time insights, it equips campaigners with powerful tools for efficient and effective outreach.",
    link: "/case-study/giddyup-canvasser",
  },
  {
    id: "4",
    bg: "#F6F3FD",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit-application-040325.webp",
    srcSet: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit-application-040325.webp",
    topText: "Node.JS / Next.JS / MongoDB / React.JS / AWS / Headless CMS",
    Heading:
      "Vechit: A UK-Based Marketplace Redefining Seamless Selling and Shopping Experiences",
    subText:
      "A UK-based innovative startup, Vechit lets users sell new or pre-loved items for free! We built an on-demand marketplace app, dedicating 930 hours to the process and designing 240+ screens across three resolutions for optimal functionality.",
    link: "/case-study/vechit",
  },
  {
    id: "5",
    bg: "#F5C451",
    color: "#000",
    img: "https://wpapi.itservicesindia.com/wp-content/uploads/2025/03/astrologer.png",
    srcSet:
      "https://wpapi.itservicesindia.com/wp-content/uploads/2025/03/astrologer.png",
    topText: "Node.JS / Next.JS / MongoDB / React.JS / AWS / Headless CMS",
    Heading:
      "Astro Aakshar an Astrology Mobile App Developed by IT Services India",
    subText:
      "Astroaakshar, developed by IT Services India, is a cross-platform app offering astrology, numerology, horoscope, and Kundli services, along with astro product e-commerce. Built with ReactJS and React Native, it ensures a seamless user experience.",
    class: "hideImg",
    link: "/case-study/astro-aakshar",

  },
];

export const ServicesOfferArray = [
  {
    id: "1",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "IOS NATIVE",
    subText:
      "With native mobile apps, you can exploit the inherent power of iOS and Android platforms with secure and performant apps.",
    middle: "Our specialist knowledge includes:",
    ul: [
      {
        id: "1",
        contant: "Extensive experience with Objective-C and Swift",
      },
      {
        id: "2",
        contant: "MVVM-C design pattern for project architecture",
      },
      {
        id: "3",
        contant:
          "RxSwift and Combine frameworks to carry out complex operations",
      },
    ],
  },
  {
    id: "2",
    bg: "#000000",
    color: "#FFFFFF",
    heading: "ANDROID NATIVE",
    subText:
      "We know Android inside-out, building advanced apps on this platform since 2012. We’ve amassed a wealth of experience in Android native development, getting the very best out of its ecosystem.",
    middle: "Our specialist knowledge includes:",
    ul: [
      {
        id: "1",
        contant: "Extensive experience with Java and KotlinTypography",
      },
      {
        id: "2",
        contant: "MVVM design pattern for project architecture",
      },
      {
        id: "3",
        contant: "RxJava and Flow frameworks for complex operations",
      },
      {
        id: "4",
        contant: "Coroutines for writing asynchronous code",
      },
    ],
  },
  {
    id: "3",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "FLUTTER",
    subText:
      "We combine deep knowledge of native iOS and Android with cross-platform solution Flutter. Backed by Google, it means we can build apps for both operating systems using one codebase.",
    middle: "Our specialist knowledge includes:",
    ul: [
      {
        id: "1",
        contant: "Extensive experience with Objective-C and Swift",
      },
      {
        id: "2",
        contant: "MVVM-C design pattern for project architecture",
      },
      {
        id: "3",
        contant:
          "RxSwift and Combine frameworks to carry out complex operations",
      },
    ],
  },
  {
    id: "4",
    bg: "#FFA2D1",
    color: "#000000",
    heading: "REACT NATIVE",
    subText:
      "React Native is a powerful framework used for building cross-platform applications. Backed by Facebook, it’s another great option when creating apps for Android and iOS platforms using one codebase.",
    middle: "Our specialist knowledge includes:",
    ul: [
      {
        id: "1",
        contant: "Writing bridges to connect native modules with JavaScript",
      },
      {
        id: "2",
        contant:
          "Redux for state management and Redux-Sagas for async side effects",
      },
      {
        id: "3",
        contant: "Declarative UI paradigm to manipulate native views directly",
      },
    ],
  },
];
export const RelatedExpertiseArray = [
  {
    id: "1",
    heading: "IOT and \n Wearables",
    heading2: "Wearables",
    inner:
      "We provide advanced Audio/Video call solutions using WebRTC framework, as well as experience with 3rd Party Calling Services like TokBox.",
  },
  {
    id: "2",
    heading: "Payment \n Getway",
    inner:
      "We have experience with e-commerce and have knowledge of Payment Gateways, such as PayPal, Stripe, and Authorize.net.",
  },
  {
    id: "3",
    heading: "VOIP \n Calling",
    inner:
      "Our team of experts provides advanced audio and video call solutions using WebRTC frameworks, as well as expertise with 3rd Party Calling Services like TokBox.",
  },
  {
    id: "4",
    heading: "Realtime \n Chat Apps",
    inner:
      "With over 10 years of experience developing iOS apps, we specialize in creating apps that use XMPP, Firebase, WebSocket, and 3rd Party SDKs.",
  },
  {
    id: "5",
    heading: "IOT and \n Wearables",
    inner:
      "We provide advanced Audio/Video call solutions using WebRTC framework, as well as experience with 3rd Party Calling Services like TokBox.",
  },
  {
    id: "6",
    heading: "Payment \n Getway",
    inner:
      "We have experience with e-commerce and have knowledge of Payment Gateways, such as PayPal, Stripe, and Authorize.net.",
  },
  {
    id: "7",
    heading: "IOT and \n Wearables",
    heading2: "Wearables",
    inner:
      "We provide advanced Audio/Video call solutions using WebRTC framework, as well as experience with 3rd Party Calling Services like TokBox.",
  },
  {
    id: "8",
    heading: "Payment \n Getway",
    inner:
      "We have experience with e-commerce and have knowledge of Payment Gateways, such as PayPal, Stripe, and Authorize.net.",
  },
  {
    id: "9",
    heading: "VOIP \n Calling",
    inner:
      "Our team of experts provides advanced audio and video call solutions using WebRTC frameworks, as well as expertise with 3rd Party Calling Services like TokBox.",
  },
  {
    id: "10",
    heading: "Realtime \n Chat Apps",
    inner:
      "With over 10 years of experience developing iOS apps, we specialize in creating apps that use XMPP, Firebase, WebSocket, and 3rd Party SDKs.",
  },
  {
    id: "11",
    heading: "IOT and \n Wearables",
    inner:
      "We provide advanced Audio/Video call solutions using WebRTC framework, as well as experience with 3rd Party Calling Services like TokBox.",
  },
  {
    id: "12",
    heading: "Payment \n Getway",
    inner:
      "We have experience with e-commerce and have knowledge of Payment Gateways, such as PayPal, Stripe, and Authorize.net.",
  },
];

export const FrequentlyMobileArray = [
  {
    id: "1",
    heading:
      "How long does it take to \n kick off a SaaS web design \n project?",
    inner:
      "Blazing-fast launch of projects is one of main It Services India’s competitive advantages. Once we gather requirements, your SaaS website design project starts immediately with a 3-day free trial.So, it usually takes a few days or even hours from project request to the moment our designers start working on your project.",
    ul: [],
  },
  {
    id: "2",
    heading: "What are the challenges in \n developing SaaS design?",
    inner:
      "Some of the issues that require specific skills from a SaaS designer are",
    ul: [
      {
        id: "1",
        name: "Mastering unlimited product combinations",
      },
      {
        id: "2",
        name: "Doing non-stop updates",
      },
      {
        id: "3",
        name: "Subscription management",
      },
      {
        id: "4",
        name: "In-product tracking and analytics.",
      },
    ],
  },
  {
    id: "3",
    heading: "How much  does it cost to \n develop mobile app?",
    inner:
      "At It Services India, we operate on a monthly retainer model, meaning you pay a set price for a designer's time. Designing from scratch typically takes 2 to 4 months, depending on how complex the project is.",
    ul: [],
  },
  {
    id: "4",
    heading:
      "What makes you \n different from other SaaS \n web design services?",
    inner:
      "At It Services India, we operate on a monthly retainer model, meaning you pay a set price for a designer's time. Designing from scratch typically takes 2 to 4 months, depending on how complex the project is.",

    ul: [
      {
        id: "1",
        name: "We specialize in designing exclusively for SaaS products.No ecommerce, gamedev, logo or banners designs.",
      },
      {
        id: "2",
        name: "Our clients and designers talk to each other directly(no middleman).",
      },
      {
        id: "3",
        name: "We provide 3 days free trial to reduce your risks and help you make an informed decision.",
      },
      {
        id: "4",
        name: "While other design agencies or freelancers have many projects, we focus on one.",
      },
    ],
  },
];

export const WhatBusinessArray = [
  {
    id: "1",
    heading:
      "“The designs met the requirements and expectations of the internal team.It Services India responds quickly to inquiries and is willing to adapt where necessary.The team is hard- working and collaborative, facilitating a smooth workflow.”",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Stacey-Rickson.webp",
    uname: "Stacey Rickson",

    bg: "#1D1E22",
  },
  {
    id: "2",
    heading:
      "“The workflow with the It Services India team is seamless.They work as an embedded member of our team.”",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Stacey-Rickson.webp",
    uname: "Stacey Rickson",

    bg: "#FCFCFC",
  },
  {
    id: "3",
    heading:
      "The approach to assign one main designer to our project, that worked full-time on it was very unique.",
    heading2:
      "It allowed them to build a very detailed understanding of our - sometimes a bit complex - product. Essentially having a competent full-time designer on your team was very valuable to us.",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Stacey-Rickson.webp",
    uname: "Stacey Rickson",

    bg: "#FCFCFC",
  },
  {
    id: "4",
    heading:
      "“The most impressive aspect of this company is the collaboration between the UX engineer and our team. So many consulting companies claim they provide fantastic customer support but often find out after the contract is signed that this isn’t the case. This is not the situation with It Services India. Their engineer and project manager have been easy to get a hold of and have been in constant contact with our team throughout the project.”",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Stacey-Rickson-02.webp",
    uname: "Stacey Rickson",

    bg: "#1D1E22",
  },
];

export const WhyCompaniesArray = [
  {
    id: "1",
    heading: "Start tomorrow",
    bg: "#FFA2D1",
    color: "#000",
    inner: "Our developers get to work the next day after our first call",
  },
  {
    id: "2",
    heading: "3-day free trial",
    bg: "#FBFEFF",
    color: "#000",
    inner: "Try 3 full days of designer work before paying",
  },
  {
    id: "3",
    heading: "Team-powered developer",
    bg: "#000000",
    color: "#fff",
    inner: "Each of our developers get support from the team and a supervisor",
  },
];

export const HiringProcessArray = [
  {
    id: "1",
    heading: "Easy team scaling",
    inner:
      "You can add more designers to your project at any time, skipping time-consuming hiring and onboarding processes.",
    bg: "#FFFFFF",
    color: "#000",
    class: "active",
  },
  {
    id: "2",
    heading: "Startup-friendly, predictable pricing",
    inner:
      "We charge a fixed monthly fee. The price is lower than the average mobile app developer salary in the US.",
    bg: "#1D1E22",
    color: "#FFFFFF",
  },
  {
    id: "3",
    heading: "Proven track record",
    inner:
      "ITservices india is a community of designers working on app development for different app projects.They regularly share their experience and work together to find the best solution.",
    bg: "#FFFFFF",
    color: "#000",
    class: "active",
  },
  {
    id: "4",
    heading: "Design network support",
    inner:
      "It Services India is a community of designers working on different app development  projects.They regularly share their experience to find the best solution.",
    bg: "#FFA2D1",
    color: "#000",
  },
  {
    id: "5",
    heading: "Quality guarantees",
    inner:
      "We don’t limit app design and development services to the list of deliveries and will continue improving the product until you and your users are fully satisfied.",
    bg: "#FFFFFF",
    color: "#000",
    class: "active",
  },
  {
    id: "6",
    heading: "Iterative development process",
    inner:
      "We don’t limit development  to the list of deliveries and will continue improving the product until you and your users are fully satisfied.",
    bg: "#E4E4E4",
    color: "#000",
    class: "active",
  },
];

export const NextStepsArray = [
  {
    id: "1",
    heading: "First meet — in 24 hours",
    inner:
      "If we both feel we’re a great fit for each other, we can move on to the next step.",
    rightHead:
      "Over a relaxed call or two, we’d like to hear as much as possible about your project (fully NDA’d of course).",
    listHeading: "We’ll talk about:",
    list: [
      {
        id: "1",
        listName: "Your expectations",
      },
      {
        id: "2",
        listName: "Engineering seniority level you require",
      },
      {
        id: "3",
        listName: "Technologies you need ",
      },
      {
        id: "4",
        listName: "Your vision",
      },
    ],
  },
  {
    id: "2",
    heading: "Team set up — in 3 days",
    inner: "There are a few steps in this phase:",
    inner2:
      "Only when you’re 100% on board with our choice, we’ll go to the next phase.",
    list: [
      {
        id: "1",
        listName:
          "We’ll hand-pick a team based on your desired seniority level and other requirements.",
      },
      {
        id: "2",
        listName:
          "We’ll send you their CVs so you’ll know every engineer working for you.",
      },
      {
        id: "3",
        listName:
          "If necessary, we can set up interviews between you and every engineer.",
      },
    ],
  },
  {
    id: "3",
    heading: "Project start — immediately",
    rightHead: "If everything sounds good to you, we’re ready to get started.",
  },
];

export const StartUpGraph = [
  {
    id: "1",
    title: "Fast Prototyping",
    content:
      "Whether you're at the Pre-seed or Seed stage, our services help startups transform ideas into reality. From concept to launch, we provide the support you need to succeed.",
    list: [
      {
        id: "1",
        listName: "Custom wireframes and design.",
      },
      {
        id: "2",
        listName: "Rapid development for early feedback.",
      },
    ],
  },
  {
    id: "2",
    title: "Proof of Concept (PoC)",
    content:
      "Whether you're at the Pre-seed or Seed stage, our services help startups transform ideas into reality. From concept to launch, we provide the support you need to succeed.",
    list: [
      {
        id: "1",
        listName: "Technical validation.",
      },
      {
        id: "2",
        listName: "Investor-ready demo prototypes.",
      },
    ],
  },
  {
    id: "3",
    title: "Market-ready MVP",
    content:
      "Whether you're at the Pre-seed or Seed stage, our services help startups transform ideas into reality. From concept to launch, we provide the support you need to succeed.",
    list: [
      {
        id: "1",
        listName: "Optimized feature development.",
      },
      {
        id: "2",
        listName: "Scalable solutions for Indian and global markets.",
      },
    ],
  },
];

export const AboutSliderArray = [
  {
    id: "1",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/parth-thumbnail-2.webp",
    video: "02_Parth_Astro_Testimonial.mp4"
  },
  {
    id: "2",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/About-Video-1.webp",
    video: "TestimonialforITServicesIndia-01.mp4"

  },
  {
    id: "3",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/About-Video-3.webp",
    video: "TestimonialforITServicesIndia-02.mp4"

  },
  {
    id: "4",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/parth-thumbnail-2.webp",
    video: "02_Parth_Astro_Testimonial.mp4"
  },
  {
    id: "5",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/About-Video-1.webp",
    video: "TestimonialforITServicesIndia-01.mp4"
  },
  {
    id: "6",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/About-Video-3.webp",
    video: "TestimonialforITServicesIndia-02.mp4"
  },
];

export const TeamSliderArray = [
  {
    id: "1",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Mask-Group-203.webp",
  },
  {
    id: "2",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Mask-Group-204.webp",
  },
  {
    id: "3",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/holi-2025-04.webp",
  },
  {
    id: "4",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/office-img-19.webp",
  },
  {
    id: "5",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Mask-Group-207.webp",
  },
  {
    id: "6",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/holi-2025-06.webp",
  },
];
export const TestimonialsArrayStartUp = [
  {
    id: "1",
    bg: "#1D1E22",
    color: "#FFF",
    content:
      "“IT Services lndia has helped us develop our custom software system. Before we signed the contract, we had initial pre-sales meetings, during which I provided them with documentation.”",
    pro_image:
      "https://media.designrush.com/agency_reviews/758559/conversions/1735945296077-user-avatar.jpg",
    usr_name: "Graham Edward, \n Managing Director",
  },
  {
    id: "2",
    bg: "#E4E4E4",
    color: "#000",
    content:
      "“I wasn't sure which technology to choose, IT Services lndia recommended me the right tech stack and asked me to pick. I picked MERN Stack and I believe they did the right thing.”",
    pro_image:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Jacob-Webb-user-avatar.jpg",
    usr_name: "Jacob Webb,\n Founder",
  },
  {
    id: "3",
    bg: "#FAFAFA",
    color: "#000",
    content:
      "“We hired IT Services lndia to help us with the front- and backend development of our mobile app. They built it in React Native, leveraging Firebase in the Google Cloud for a lot of our backend services. ”",
    pro_image:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Dennis-Stauffer-user-avatar.jpg",
    usr_name: "Dennis Stauffer,\n Data Analyst",
  },
];

export const CollaborationArray = [
  {
    id: "1",
    title: "Product discovery",
    content:
      "Through workshops and modern research methods we’ll test your idea, make sure it fits the market and show you how to turn it into reality.",
    list: [
      {
        id: "1",
        listName: "From basic to complex",
      },
      {
        id: "2",
        listName: "3 – 10+ weeks depending on complexity",
      },
      {
        id: "3",
        listName: "14.000 – 25.000 USD",
      },
    ],
  },
  {
    id: "2",
    title: "MVP development",
    content:
      "Through workshops and modern research methods we’ll test your idea, make sure it fits the market and show you how to turn it into reality.",
    list: [
      {
        id: "1",
        listName: "From basic to complex",
      },
      {
        id: "2",
        listName: "3 – 10+ weeks depending on complexity",
      },
      {
        id: "3",
        listName: "25.000 – 35.000 USD",
      },
    ],
  },
  {
    id: "3",
    title: "Custom solution →",
    content:
      "You didn’t find a plan that fits your needs? No problem! We suggest having a call with our tech experts to better understand your idea.",
  },
];

export const StartUpFaqs = [
  {
    id: "1",
    question: "What industries do you specialize in for startup solutions?",
    answer:
      "We have worked across <a class='HUderLine' target='_blank' href='/saas-product'>SaaS</a>, e-commerce, <a class='HUderLine' target='_blank' href='/healthcare-software-development-services'>healthcare</a>, <a class='HUderLine' target='_blank' href='/elearning-software-development'>education</a>, and more, tailoring solutions to meet specific industry needs.",
  },
  {
    id: "2",
    question: "How do you ensure on-time delivery?",
    answer:
      "We use detailed road maps, agile practices, and regular progress updates to keep projects on track.",
  },
  {
    id: "3",
    question: "Can you help refine my startup idea before development?",
    answer:
      "Absolutely. Our team conducts workshops and market validation to ensure your idea resonates with target users.",
  },
  {
    id: "4",
    question: "What’s your approach to handling tight budgets?",
    answer:
      "We prioritize efficient resource allocation and leverage reusable components to minimize costs without compromising quality.",
  },
  {
    id: "5",
    question: "How do you ensure quality in your deliverables?",
    answer:
      "Our multidisciplinary team rigorously tests every product for functionality, usability, and scalability before launch.",
  },
  {
    id: "6",
    question: "Do you offer post-launch support?",
    answer:
      "Yes, we provide maintenance and updates to keep your product optimized and relevant.",
  },
  {
    id: "7",
    question:
      "What makes your <a class='text-hover' target='_blank' href='/ui-ux-design-services'>UX/UI design</a> stand out?",
    answer:
      "Our designs are user-centric, focusing on seamless experiences that drive engagement and satisfaction.",
  },
  {
    id: "8",
    question: "How do you maintain transparency during development?",
    answer:
      "Through open communication, shared project management tools, and regular status reports.",
  },
  {
    id: "9",
    question: "Can you help with investor presentations or pitch decks?",
    answer:
      "Yes, our design team can create visually compelling decks to support your funding efforts.",
  },
  {
    id: "10",
    question: "Do you work with startups at all stages?",
    answer:
      "Yes, whether you’re at the ideation phase or scaling, we adapt to meet your needs and goals.",
  },
];

export const BenefitsCareerArray = [
  {
    id: "1",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "Work/Life Balance",
    inner:
      "Our work policies include flexible working hours, generous vacation days per year, and regular team-wide events.",
  },
  {
    id: "2",
    bg: "#000000",
    color: "#FFFFFF",
    heading: "Wellness",
    inner:
      "I’m a fitness fanatic with gym memberships and volunteer time at IT Services India.",
  },
  {
    id: "3",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "Vacation",
    inner:
      "The work we do is important to us, but we know it’s also important to maintain a healthy balance. Our generous PTO policy allows you to take the time you need when you need it.",
  },
  {
    id: "4",
    bg: "#FFA2D1",
    color: "#000000",
    heading: "Familly",
    inner:
      "It should be work that helps you live the life you want-that’s why we provide 12 weeks of paid maternity leave and 6 weeks of paid paternity leave, as well as partnering with CLEO for expert coaching and support.",
  },
  {
    id: "5",
    bg: "#000",
    color: "#fff",
    heading: "Rewards",
    inner: "The performance should be valued and appreciated regularly.",
  },
];
export const CareerFaqsArray = [
  {
    id: "1",
    question:
      "You didn’t hire me. ​​​​​​​Will I be considered for other jobs in the future?",
    answer:
      "Of course! We would be more than happy to consider your application again, particularly if you come back to us with new knowledge or skills.",
  },
  {
    id: "2",
    question: "What’s the best way to apply for a position?",
    answer:
      "Search and apply for a job on our Careers page. Follow us on social media too – we’re on LinkedIn, Facebook and Instagram – where we will keep you up to date on open positions at IT Services India.",
  },
  {
    id: "3",
    question: "How long does it take to apply for a job?",
    answer:
      "We collect applications through Bamboo.hr. If you have your CV/resume ready, it should take you a few minutes to complete our online application. Bear in mind that the more relevant information we get from you, the more we will be able to understand if you are the right candidate.",
  },
  {
    id: "4",
    question: "Is the cover letter a compulsory part of the application?",
    answer:
      "It is not required but it’s certainly an advantage. We really appreciate when a candidate takes the time to show us his or her motivation.",
  },
  {
    id: "5",
    question: "Do you employ non- technical people?",
    answer:
      "Certainly! We need people on our team who can bring in great projects and even better people. Show us what you can do and we’ll see if you’d fit right in.",
  },
];

export const InterestInvestor = [
  {
    id: "1",
    title: "Want to bring your startup idea to life fast and precisely?",
    text: "Design can make or break your startup. Let our professional design team quickly convert your vision into a working prototype that you can test with users and show to investors.",
    btnname: "Design form scratch",
    projecttitle: "MySquard went from product idea to a $500M investment",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Mysquard-dashboard.webp",
    ownerquote:
      "IT Services India's work met the client's expectations. The team understood the client's vision and was eager to work with them to ensure it came to life,",
    ownername: "Matthew Cifelli, DBD",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/ATAIN.webp",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/ATAIN.webp",
    icon: "carbon:arrow-right",
  },
  {
    id: "2",
    title: "Customers find your SaaS product hard to use?",
    text: "Bad UX means you are losing money right now. Hire IT Services India designers to refine user engagement, increase positive feedback, and improve your bottom line.",
    btnname: "Redesign services",
    projecttitle:
      "Skyhub MLS CRM revamped its CRM to raise $800K at a pre-series A round",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub-dashboard.webp",
    ownerquote:
      "We find IT Services India’s ability to grasp the requirements and develop the application quite impressive.",
    ownername: "David J. Dunn, President",
    ownerimg:
      "https://assets-global.website-files.com/5e8b3356a5a8f5321855bbe7/david-j-dunn.webp",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/PEGNATO.webp",
    icon: "carbon:arrow-right",
  },
  {
    id: "3",
    title: "Design became your scaling bottleneck?",
    text: "Need a senior design talent to build new features, master consistency in a complex product or streamline a design system? You are at the right place.",
    btnname: "Team extentation services",
    projecttitle: "MySquard went from product idea to a $500M investment",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Mysquard-dashboard.webp",
    ownerquote:
      "“IT Services India significantly improved the client's efficiency, reduced system downtime by 80%, and enhanced the system. ”",
    ownername: "Alexandria Pegnato,\n Executive VP",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/alexandria-pegnato.webp",

    icon: "carbon:arrow-right",
  },
];

export const InterestInvestorStartup = [
  {
    id: "1",
    title: "Mysquard achieved 90% of customer and partner engagement",
    text: "MySquard saw a 40% increase in user engagement, 30% rise in bookings, and 20% boost in customer satisfaction, establishing itself as a top on-demand service platform in the US with seamless functionality and positive user experience.",
    btnname: "Design form scratch",
    projecttitle:
      "MySquard an On Demand Marketplace App Development by IT Services India",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Mysquard-dashboard.webp",
    ownerquote:
      "IT Services India's work met the client's expectations. The team understood the client's vision and was eager to work with them to ensure it came to life,",
    ownername: "Matthew Cifelli, DBD",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/auth-img-1.webp",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/ATAIN.webp",
    icon: "carbon:arrow-right",
    link: "/case-study/mysquard",
  },
  {
    id: "2",
    title:
      "Seamless Property Searches, Enhanced User Experience, and 40% Business Growth.",
    text: "SKYHUB, with IT Services India, resolved challenges using advanced technology. Enhanced scalability and performance boosted user experience and streamlined processes, achieving a 40% business growth and setting new standards in real estate innovation.",
    btnname: "Redesign services",
    projecttitle: "Realestate Web Development by IT Services India for SkyHub",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub-dashboard.webp",
    ownerquote:
      "They took the time to thoroughly understand our specific needs. With the new system, the client reduced stockouts, enhanced their supply chain, and fulfilled orders more efficiently and accurately.",
    ownername: "Barry Fish,\n MD",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/auth-img-1.webp",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/a2b-office-supplies.webp",
    icon: "carbon:arrow-right",
    link: "/case-study/skyhub",
  },
  {
    id: "3",
    title:
      "Vechit drove 35% more resales, cut textile waste by 75%, and hit 60% user engagement",
    text: "Vechit leverages MangoPay for secure payments and Ship Engine for efficient delivery and order tracking. It simplifies buying, selling, and decluttering, offering users a sustainable, seamless marketplace for traditional and lightly used clothing.",
    btnname: "Team extentation services",
    projecttitle:
      "Vechit: A UK-Based Marketplace Redefining Seamless Selling and Shopping Experiences",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_05.png",
    ownerquote:
      "We recently had the pleasure of working with IT Services India to create our new website, and we couldn't be happier with the results! They delivered exactly what.",
    ownername: "Morgan Gregory \n  CEO",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Morgan-Gregory-user-avatar.jpg",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/PEGNATO.webp",

    icon: "carbon:arrow-right",
    link: "/case-study/vechit",
  },
];

export const FrountEndInvestor = [
  {
    id: "1",
    title: "Discovery",
    text: "Our <a class='HUderLine' href='/team-extension' target='_blank'>frontend development team</a>  maps user flows and explores functionality with your Product team to fully understand the app.",
    projecttitle: "MySquard went from product idea to a $500M investment",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-1-100.webp",
    ownerquote:
      "They took the time to thoroughly understand our specific needs. With the new system, the client reduced stockouts, enhanced their supply chain, and fulfilled orders more efficiently and accurately.",
    ownername: "Barry Fish,\n MD",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/auth-img-1.webp",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/a2b-office-supplies.webp",
    icon: "carbon:arrow-right",
  },
  {
    id: "2",
    title: "Research",
    text: "<a class='HUderLine' href='/hire-front-end-developers' target='_blank'> Our frontend developers</a> interview your Product and Support teams to identify key issues customers face within the app.",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-2-100.webp",
    ownerquote:
      "We recently had the pleasure of working with IT Services India to create our new website, and we couldn't be happier with the results! They delivered exactly what.",
    ownername: "Morgan Gregory \n  CEO",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Morgan-Gregory-user-avatar.jpg",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/PEGNATO.webp",
    icon: "carbon:arrow-right",
  },
  {
    id: "3",
    title: "Analysis",
    text: "Our <a class='HUderLine' href='/' target='_blank'>frontend development agency</a> links identified issues to user flows, generating solutions to improve user experience.",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-3-100.webp",
    ownerquote:
      "Responsive and efficient, IT Services India quickly implemented any requests for changes that came up during peer review discussions. Their comprehensive experience, coupled with their proactive.",
    ownername: "Michael G., \n CEO",
    ownerimg:
      "https://media.designrush.com/agency_reviews/715229/conversions/1728344761133-user-avatar.jpg",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/a2b-office-supplies.webp",
    icon: "carbon:arrow-right",
  },
  {
    id: "4",
    title: "Prioritization",
    text: "We work with your Product team to map solutions using an Impact/Effort matrix for effective frontend development.",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-4-100.webp",
    ownerquote:
      "IT Services India delivered a well-functioning mobile app allowing easier access to the client’s online store..",
    ownername: "Ted Smernes,\n Manager",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/ted-smernes.webp",

    icon: "carbon:arrow-right",
  },
  {
    id: "5",
    title: "Frontend Audit Report",
    text: "Our frontend development company provides a <a class='HUderLine' href='/ux-audit-services' target='_blank'>UI/UX audit report</a> and roadmap for prioritized UI/UX frontend enhancements.",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-5-100.webp",
    ownerquote:
      "We had a file of about 200,000 skus that needed to be corrected in mass. We had a very large file of products where we needed to hide a vendors part# from multiple places on the.",
    ownername: "Michael Elson, \n President",
    ownerimg:
      "https://media.designrush.com/agency_reviews/724471/conversions/1730066412888-user-avatar.jpg",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Cargill_white.webp",
    icon: "carbon:arrow-right",
  },
];
export const UXAuditServicesWeCanwhatPartsMapData = [
  {
    id: "1",
    title: "Discovery Phase of UI/UX Audit",
    text: "In the discovery phase, we thoroughly analyze your app’s structure, user flows, and core features with your Product team to understand its full functionality.",
    projecttitle: "MySquard went from product idea to a $500M investment",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-1-100.webp",
    ownerquote:
      "They took the time to thoroughly understand our specific needs. With the new system, the client reduced stockouts, enhanced their supply chain, and fulfilled orders more efficiently and accurately.",
    ownername: "Barry Fish,\n MD",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/auth-img-1.webp",
    companylogo:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/a2b-office-supplies.webp",
    icon: "carbon:arrow-right",
  },
  {
    id: "2",
    title: "Research in UI/UX Audit Services",
    text: "Through comprehensive interviews with your customers, Product, and Support teams, we uncover pain points and usability issues.",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-2-100.webp",
    ownerquote:
      "We had a file of about 200,000 skus that needed to be corrected in mass. We had a very large file of products where we needed to hide a vendors part# from multiple places on the.",
    ownername: "Michael Elson, \n President",
    ownerimg:
      "https://media.designrush.com/agency_reviews/724471/conversions/1730066412888-user-avatar.jpg",
    companylogo: "",
    icon: "carbon:arrow-right",
  },
  {
    id: "3",
    title: "Sensemaking in UI/UX Audit Process",
    text: "We’ll connect identified issues to specific user flows, developing targeted ideas and solutions to address each pain point.",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-3-100.webp",
    ownerquote:
      "Responsive and efficient, IT Services India quickly implemented any requests for changes that came up during peer review discussions. Their comprehensive experience, coupled with their proactive.",
    ownername: "Michael G., \n CEO",
    ownerimg:
      "https://media.designrush.com/agency_reviews/715229/conversions/1728344761133-user-avatar.jpg",
    companylogo: "",
    icon: "carbon:arrow-right",
  },
  {
    id: "4",
    title: "Prioritization in UI/UX Audits",
    text: "In a collaborative workshop with your Product team, we prioritize potential improvements using an Impact/Effort matrix.",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-4-100.webp",
    ownerquote:
      "IT Services India delivered a well-functioning mobile app allowing easier access to the client’s online store..",
    ownername: "Ted Smernes,\n Manager",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/ted-smernes.webp",

    icon: "carbon:arrow-right",
  },
  {
    id: "5",
    title: "Comprehensive UX Audit Report",
    text: "Based on the priorities set, we’ll compile a detailed UX audit report along with a roadmap for future UI/UX enhancements.",
    projectimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Artboard-5-100.webp",
    ownerquote:
      "We recently had the pleasure of working with IT Services India to create our new website, and we couldn't be happier with the results! They delivered exactly what.",
    ownername: "Morgan Gregory \n  CEO",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Morgan-Gregory-user-avatar.jpg",
    companylogo: "",
    icon: "carbon:arrow-right",
  },
];

export const WhyUsArray = [
  {
    id: "1",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "1 project – 1 team",
    inner:
      "We fully immerse ourselves in your world, staying laser-focused on your goals. From day one, we dedicate ourselves entirely to your project, without distractions from other clients. It's all about aligning with your business goals.",
  },
  {
    id: "2",
    bg: "#000000",
    color: "#FFFFFF",
    heading: "Extreme transparency",
    inner:
      "With transparency at every stage, you will stay informed about who is handling each task, the timeline, the reasons behind each decision, and the associated costs.",
  },
  {
    id: "3",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "Multi-disciplinary approach",
    inner:
      "Your software product requires more than just engineering; it needs a multidisciplinary approach. A team of tech experts, project managers, QA engineers, and designers must collaborate to deliver a fully developed product.",
  },
  {
    id: "4",
    bg: "#FFA2D1",
    color: "#000000",
    heading: "Family",
    inner:
      "We believe in work-life balance. That’s why we offer 12 weeks of paid maternity leave, and 6 weeks of paid paternity leave, and partner with CLEO for expert coaching and support.",
  },
];

export const FrontCardDataArray = [
  {
    id: "1",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/AngularJS.svg",
    title: "Front-end \n Architecture & Design",
    text: "A set of tools and processes for improving front-end code quality aimed at creating efficient and sustainable applications with front-end architecture.",
  },
  {
    id: "2",
    img: "	https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/AngularJS_Development.svg",
    title: "SPA Application & \n Development",
    text: "JavaScript frameworks for quick scalability are used to develop single page applications.JavaScript frameworks for quick scalability are used to develop single page applications.",
  },
  {
    id: "3",
    img: "	https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/3.svg",
    title: "UI/UX App \n Development",
    text: "We develop highly scalable, data-driven, UI/UX-rich web applications.",
    width: "25px",
  },
  {
    id: "4",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/4.svg",
    title: "Progressive \n Web Apps",
    text: "A combination of native mobile apps and websites can be achieved with progressive web apps (or PWAs).",
  },
  {
    id: "5",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/5.svg",
    title: "Re-engineering & \n Maintenance",
    text: "In addition to providing end-to-end support for modernizing or enhancing your apps, we offer the best front-end development services for growing start-ups and established businesses.",
  },
  {
    id: "6",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/6.svg",
    title: "HTML5/ CSS \n Development",
    text: "Maintain and upgrade your HTML5 website if and when you desire, wherever and whenever you choose.",
    width: "25px",
  },
];

export const IndustriesSliderArray = [
  {
    id: "1",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/education-elearning-swift-mobile-app.jpg",
    title: "Education & Elearning",
    sub: "Management System, School Management System, Online Examination Platform and More…",
  },
  {
    id: "2",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/transport-logistic-swift-mobile-app.jpg",
    title: "Transport & Logistics",
    sub: "Solutions for transportation management, warehouse management, dispatch management, and more…",
  },
  {
    id: "3",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mobile-app-for-media-entertainment.jpg",
    title: "Media & Entertainment",
    sub: "Applications for booking parties, social networking apps, news portals and more…",
  },
  {
    id: "4",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/texi.png",
    title: "Taxi Booking app",
    sub: "Taxi Business apps, Taxi Business for Corporations, Car Rental apps, Air Taxi apps",
  },
  {
    id: "5",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/food.png",
    title: "Food Delivery App",
    sub: "I recommend launching your on-demand delivery app now. We have built several apps in this field.",
  },
  {
    id: "6",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/fitness.png",
    title: "Fitness App Development",
    sub: "Nutrition and Diet apps, Workout and Personal Trainer apps, Meditation and Yoga apps, Activity tracking apps.",
  },
  {
    id: "7",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/market.png",
    title: "Marketplace App",
    sub: "IT Services India offers a marketplace solution that lets you create a fully customised marketplace with the….",
  },
  {
    id: "8",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/real-estse.png",
    title: "Real estate App",
    sub: "Grow your real estate business with a custom digital solution built by IT Services India experts.",
  },
  {
    id: "9",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/travel-Hospitality-swift-mobile-app.png",
    title: "Travel & Hospitality",
    sub: "We help travel agencies, hospitality providers, travelers, and airlines leverage technology advancements.",
  },
];

export const MobileSidebarData = [
  {
    id: "1",
    name: "Web Application Development",
    link: "/web-application-development-company",
  },
  {
    id: "2",
    name: "Mobile App Development",
    link: "/mobile-app-development-company",
  },
  {
    id: "3",
    name: "Back End Development",
    link: "/backend-development-services",
  },
  {
    id: "4",
    name: "Software Development",
    link: "/software-development",
  },
  {
    id: "5",
    name: "Front End Development",
    link: "/front-end-development",
  },
  {
    id: "6",
    name: "UI/UX Design",
    link: "/ui-ux-design-services",
  },
  {
    id: "7",
    name: "MERN Stack Developers",
    link: "/mern-stack-developers",
  },
  {
    id: "8",
    name: "E-Commerce Development",
    link: "/ecommerce-development-services",
  },
  {
    id: "9",
    name: "Cross-Platform App",
    link: "/cross-platform-app-development-company",
  },
  {
    id: "10",
    name: "Website Design",
    link: "/website-design-and-development",
  },
  {
    id: "11",
    name: "MVP Development Company",
    link: "/mvp-development",
  },
  {
    id: "12",
    name: "Progressive Web App Development",
    link: "/progressive-web-app-development",
  },
];

export const ContactPagecontact = [
  {
    id: "1",
    bg: "#1D1E22",
    color: "#FFF",
    content:
      "“They took the time to thoroughly understand our specific needs. With the new system, the client reduced stockouts, enhanced their supply chain, and fulfilled orders more efficiently and accurately.”",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/auth-img-1.webp",
    uname: "Barry Fish,\n MD",
    company: "A2B Office Supplies",
  },
  {
    id: "2",
    bg: "#1D1E22",
    color: "#FFF",
    content:
      "“Responsive and efficient, IT Services India quickly implemented any requests for changes that came up during peer review discussions. Their comprehensive experience, coupled with their proactive approach, made for a fruitful partnership. Customers can expect clean and professional designs from this team.”",
    img: "https://media.designrush.com/agency_reviews/715229/conversions/1728344761133-user-avatar.jpg",
    uname: "Michael G.,\n CEO",
    company: "E-Valve Technologies, Inc.",
  },
  {
    id: "3",
    bg: "#1D1E22",
    color: "#FFF",
    content:
      "“IT Services India successfully delivered the project on time and within budget, and the client was pleased with the results. The team communicated effectively via Slack and email. Their collaborative approach and.”",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/david-watts.webp",
    uname: "David Watts, \n Chief Commercial Officer",
    company: "Accelex",
  },
];

export const ContactPageData = [];
for (let i = 1; i < 100; i++) {
  ContactPagecontact.forEach((item) => {
    ContactPageData.push({
      ...item,
      id: `${item.id}-${i + 1}`,
    });
  });
}

export const TechnologiesMobileSidebarData = [
  {
    id: "1",
    Title: "Front-End Developers",
    list: [
      {
        id: "1",
        name: "Angular JS Developers",
        link: "/angular-js-development-company",
      },
      {
        id: "2",
        name: "React JS Developers",
        link: "/react-js-development-company",
      },
      {
        id: "3",
        name: "Vue JS Developers",
        link: "/vue-js-development-company",
      },
      {
        id: "4",
        name: "Next JS Developers",
        link: "/next-js-development-company",
      },
      {
        id: "5",
        name: "UI UX Designers",
        link: "/ui-ux-design-services",
      },
      {
        id: "6",
        name: "Swift Developers",
        link: "/swift-app-development-company",
      },
      {
        id: "7",
        name: "Kotlin Developers",
        link: "/kotlin-development-company",
      },
      {
        id: "8",
        name: "UX audit Services",
        link: "/ux-audit-services",
      },
    ],
  },
  {
    id: "2",
    Title: "Back-End Developers",
    list: [
      {
        id: "1",
        name: "ASP .NET Developers",
        link: "/top-asp-net-development-company",
      },
      {
        id: "2",
        name: "PHP Developers",
        link: "/php-development-company",
      },
      {
        id: "3",
        name: "Python Developers",
        link: "/python-web-development",
      },
      {
        id: "4",
        name: "Node JS Developers",
        link: "/node-js-development-company",
      },
      {
        id: "5",
        name: "Express JS Developers",
        link: "/express-js-development-company",
      },
      {
        id: "6",
        name: "Dedicated Developers",
        link: "/dedicated-software-development-team",
      },
      {
        id: "7",
        name: "Gastby Developers",
        link: "/gatsby js-development-company",
      },
    ],
  },
  {
    id: "3",
    Title: "Mobile App Developers",
    list: [
      {
        id: "1",
        name: "iOS Developers",
        link: "/ios-app-development-company",
      },
      {
        id: "2",
        name: "Android Developers",
        link: "/android-app-development-services",
      },
      {
        id: "3",
        name: "Flutter Developers",
        link: "/flutter-app-development-services",
      },
      {
        id: "4",
        name: "Mobile App Developers",
        link: "/mobile-app-development-company",
      },
      {
        id: "5",
        name: "Kotlin Developers",
        link: "/express-js-development-company",
      },
      {
        id: "6",
        name: "Xamarin Developers",
        link: "/xamarin-mobile-app-development-company",
      },
      {
        id: "7",
        name: "Backbone Developers",
        link: "/backbonejs-development-services",
      },
      {
        id: "8",
        name: "Cross-Platfrom App",
        link: "/cross-platform-app-development-company",
      },
    ],
  },
  {
    id: "4",
    Title: "Ecommerce Developers",
    list: [
      {
        id: "1",
        name: "Shopify Developers",
        link: "/shopify-development-company",
      },
      {
        id: "2",
        name: "Magento Developers",
        link: "/magento-development-services",
      },
      {
        id: "3",
        name: "Woocommerce Developers",
        link: "/woocommerce-development",
      },
      {
        id: "4",
        name: "Odoo Developers",
        link: "/odoo-development-company",
      },

      {
        id: "5",
        name: "E-Commerce Development",
        link: "/ecommerce-development-services",
      },
    ],
  },
];

export const IndustrySidebarData = [
  {
    id: "0",
    name: "On-Demand App Development",
    link: "/on-demand-app-development",
  },
  {
    id: "1",
    name: "Healthcare",
    link: "/healthcare-software-development-services",
  },
  {
    id: "2",
    name: "Education/ eLearning",
    link: "/elearning-software-development",
  },
  {
    id: "3",
    name: "Retail",
    link: "/retail-software-development",
  },
  {
    id: "4",
    name: "Restaurant App",
    link: "/restaurant-app-development-company",
  },
  {
    id: "5",
    name: "Manufacturing",
    link: "/manufacturing-software-development",
  },
  {
    id: "6",
    name: "Beauty & Lifestyle",
    link: "/beauty-and-lifestyle",
  },
  {
    id: "7",
    name: "Real Estate",
    link: "/real-estate-software-development-company",
  },
  {
    id: "8",
    name: "Travel & Hospitality",
    link: "/travel-app-development-company",
  },
  {
    id: "9",
    name: "Logistics & Distribution",
    link: "/logistics-software-development-company",
  },
  {
    id: "10",
    name: "Fintech",
    link: "/fintech-software-development",
  },
  {
    id: "11",
    name: "Social Media App",
    link: "/social-media-app-development",
  },
  {
    id: "12",
    name: "Dedicated Development Team",
    link: "/dedicated-software-development-team",
  },
];

export const PlatformSidebarData = [
  {
    id: "1",
    Title: "Platform",
    list: [
      {
        id: "1",
        name: "IOS",
        link: "/ios-app-development-company",
      },
      {
        id: "2",
        name: "Android",
        link: "/android-app-development-services",
      },
      {
        id: "3",
        name: "Swift",
        link: "/swift-app-development-company",
      },
      {
        id: "4",
        name: "Kotlin",
        link: "/kotlin-development-company",
      },
      {
        id: "5",
        name: "Xamarin App",
        link: "/xamarin-mobile-app-development-company",
      },
      {
        id: "6",
        name: "Vue-Js",
        link: "/vue-js-development-company",
      },
      {
        id: "7",
        name: "Angular js",
        link: "/angular-js-development-company",
      },
      {
        id: "8",
        name: "Shopify",
        link: "/shopify-development-company",
      },
      {
        id: "9",
        name: "PHP",
        link: "/php-development-company",
      },
      {
        id: "10",
        name: "Laravel",
        link: "/laravel-development-services",
      },
      {
        id: "11",
        name: "Wordpress",
        link: "/wordpress-development-company",
      },
    ],
  },
  {
    id: "2",
    Title: "Platform",
    list: [
      {
        id: "1",
        name: "Codeigniter",
        link: "/codeigniter-development-services",
      },
      {
        id: "2",
        name: "Meteor JS",
        link: "/meteor-js-development-services",
      },
      {
        id: "3",
        name: "Magento",
        link: "/magento-development-services",
      },
      {
        id: "4",
        name: "React JS",
        link: "/react-js-development-company",
      },
      {
        id: "5",
        name: "Knockout JS",
        link: "/knockoutjs-development-company",
      },
      {
        id: "6",
        name: "Asp.Net",
        link: "/top-asp-net-development-company",
      },
      {
        id: "7",
        name: "Asp.Net Core",
        link: "/aspnet-core-development-company",
      },
      {
        id: "8",
        name: "Gatsby JS",
        link: "/gatsby js-development-company",
      },
      {
        id: "9",
        name: "Express JS",
        link: "/express-js-development-company",
      },
      {
        id: "10",
        name: "Next JS",
        link: "/next-js-development-company",
      },
      {
        id: "11",
        name: "Nuxt JS",
        link: "/nuxt-js-development-company",
      },
    ],
  },
  {
    id: "3",
    Title: "Platform",
    list: [
      {
        id: "1",
        name: "BackBone JS",
        link: "/backbonejs-development-services",
      },
      {
        id: "2",
        name: "Nest Js",
        link: "/nest-js-development-company",
      },
      {
        id: "3",
        name: "Flutter",
        link: "/flutter-app-development-services",
      },
      {
        id: "4",
        name: "Python",
        link: "/python-development",
      },
      {
        id: "5",
        name: "Python Web",
        link: "/python-web-development",
      },
      {
        id: "6",
        name: "Odoo",
        link: "/odoo-development-company",
      },
      {
        id: "7",
        name: "WooCommerce",
        link: "/woocommerce-development",
      },
      {
        id: "8",
        name: "Node Js",
        link: "/node-js-development-company",
      },
      {
        id: "9",
        name: "Svelts Js",
        link: "/svelte-js-development-company",
      },
      {
        id: "10",
        name: ".Net Core",
        link: "/aspnet-core-development-company",
      },
      {
        id: "11",
        name: "React Native",
        link: "/react-native-app-development-company",
      },
    ],
  },
];
export const HireDevelopersSidebarData = [
  {
    id: "1",
    Title: "Platform",
    list: [
      {
        id: "1",
        name: "Hire Swift",
        link: "/hire-swift-app-developers",
      },
      {
        id: "2",
        name: "Hire Php",
        link: "/hire-php-developers",
      },
      {
        id: "3",
        name: "Hire Codeigniter",
        link: "/hire-codeigniter-developers",
      },
      {
        id: "4",
        name: "Hire Node js",
        link: "/hire-nodejs-developers",
      },
      {
        id: "5",
        name: "Hire WordPress",
        link: "/",
      },
      {
        id: "6",
        name: "Hire Knockout Js",
        link: "/hire-knockout-js-developers",
      },
      {
        id: "7",
        name: "Hire Asp.net Developers",
        link: "/hire-asp-net-developers",
      },
      {
        id: "8",
        name: "Hire Nest JS",
        link: "/hire-nest-js-developers",
      },
      {
        id: "9",
        name: "Hire Laravel",
        link: "/hire-laravel-developers",
      },
      {
        id: "10",
        name: "Hire Mobile",
        link: "/hire-mobile-app-developers",
      },
      {
        id: "11",
        name: "Hire iOS",
        link: "/hire-ios-app-developers",
      },
      {
        id: "12",
        name: "Hire Android",
        link: "/hire-android-app-developers",
      },
      {
        id: "13",
        name: "Hire Xamarin ",
        link: "hire-xamarin-mobile-app-developers",
      },
      {
        id: "14",
        name: "Hire Meteor Js",
        link: "/hire-meteor-js-developers",
      },
      {
        id: "15",
        name: "Hire React Native",
        link: "/hire-react-native-developers",
      },
    ],
  },
  {
    id: "2",
    Title: "Platform",
    list: [
      {
        id: "1",
        name: "Hire Angular JS",
        link: "/hire-angularjs-developers",
      },
      {
        id: "2",
        name: "Hire Kotlin",
        link: "/hire-kotlin-developers",
      },
      {
        id: "3",
        name: "Hire Gatsby JS",
        link: "/hire-gatsby-js-developers",
      },
      {
        id: "4",
        name: "Hire React JS",
        link: "/hire-react-js-developers",
      },
      {
        id: "5",
        name: "Native App Development",
        link: "/native-app-development",
      },
      {
        id: "6",
        name: "Hire Next JS",
        link: "/hire-nextjs-developers",
      },
      {
        id: "7",
        name: "Hire Nuxt JS",
        link: "/hire-nuxt-js-developers",
      },
      {
        id: "8",
        name: "Hire Python",
        link: "/hire-python-developers",
      },
      {
        id: "9",
        name: "Hire Vue JS",
        link: "/hire-vue-js-developers",
      },
      {
        id: "10",
        name: "Hire Flutter",
        link: "/hire-flutter-developers",
      },
      {
        id: "11",
        name: "Hire Backbone",
        link: "/hire-backbone-js-developers",
      },
      {
        id: "12",
        name: "Hire Express JS",
        link: "/hire-express-js-developers",
      },
      {
        id: "13",
        name: "Hire Front-End",
        link: "/hire-front-end-developers",
      },
      {
        id: "14",
        name: "Hybrid App Development",
        link: "/hybrid-apps-development",
      },
      {
        id: "15",
        name: "Hire Shopify",
        link: "/hire-shopify-developers",
      },
    ],
  },
];

export const FrontEndCardArray = [
  {
    id: "1",
    name: "Complete Front-End Solutions",
    bg: "#FFB7DB",
    color: "#000",
    inner:
      "One bad experience can make 35% of customers switch to a different brand.",
  },
  {
    id: "2",
    name: "Tech Stack",
    bg: "#F2F9FC",
    color: "#000",
    inner:
      "Unhappy customers often voice complaints, while others may quietly walk away.",
  },
  {
    id: "3",
    name: "User-Focused Design",
    bg: "#000000",
    color: "#fff",
    inner:
      "Negative experiences typically spread to 20 or more individuals from one user.",
  },
];

export const SoftwareCardArray = [
  {
    id: "1",
    text: "Year Founded",
    number: "2010",
  },
  {
    id: "2",
    text: "Team Members",
    number: "60",
  },
  {
    id: "3",
    text: "Happy clients",
    number: "500",
  },
  {
    id: "4",
    text: "",
    number: "Read Our Client’s Reviews",
  },
];

export const HomeCounterMapData = [
  {
    id: "1",
    number: "15 years",
    subtext: "in Software Development",
    box_no: "/01",
  },
  {
    id: "2",
    number: "300 +",
    subtext: "in Projects Completed",
    box_no: "/02",
  },
  {
    id: "3",
    number: "1 day",
    subtext: "and Hire Resources",
    box_no: "/03",
  },
];

export const BlogSidebarData = [
  {
    id: "1",
    name: "All topics",
    path: "/blog/blog-all",
  },
  {
    id: "2",
    name: "Design process",
    path: "/category/[id]",
  },
  {
    id: "3",
    name: "For enterprice",
  },
  {
    id: "4",
    name: "For startups",
  },
  {
    id: "5",
    name: "Mobile application",
  },
  {
    id: "6",
    name: "UX design",
  },
  {
    id: "7",
    name: "Web development",
  },
  {
    id: "8",
    name: "E-commerce",
  },
  {
    id: "9",
    name: "Trending Technology",
  },
  {
    id: "10",
    name: "Product design",
  },
  {
    id: "11",
    name: "Design team",
  },
  {
    id: "12",
    name: "Design process",
  },
];

export const BlogCardsArray = [
  {
    id: "1",
    bg: "#FFCAE4",
    heading: "DESIGN PROCESS",
    sub: "/ 15 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "1",
  },
  {
    id: "2",
    bg: "#FFFFFF",
    heading: "DESIGN PROCESS",
    sub: "/ 35 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "2",
  },
  {
    id: "3",
    bg: "#FFFFFF",
    heading: "DESIGN PROCESS",
    sub: "/ 45 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "1",
  },
  {
    id: "4",
    bg: "#FFEED4",
    heading: "DESIGN PROCESS",
    sub: "/ 55 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "3",
  },
  {
    id: "5",
    bg: "#FFFFFF",
    heading: "DESIGN PROCESS",
    sub: "/ 22 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "1",
  },
  {
    id: "6",
    bg: "#FFCAE4",
    heading: "DESIGN PROCESS",
    sub: "/ 38 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "2",
  },
  {
    id: "7",
    bg: "#FFF",
    heading: "DESIGN PROCESS",
    sub: "/ 15 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "1",
  },
  {
    id: "8",
    bg: "#FFCAE4",
    heading: "DESIGN PROCESS",
    sub: "/ 42 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "3",
  },
  {
    id: "9",
    bg: "#FFF",
    heading: "DESIGN PROCESS",
    sub: "/ 21 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "1",
  },
  {
    id: "8",
    bg: "#FFF",
    heading: "DESIGN PROCESS",
    sub: "/ 11 MIN READ",
    inner: "26 Product Feature Prioritization Frameworks Used by Top SaaS",
    icon: "3",
  },
];

export const FooterArray = [
  {
    id: "1",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "TECHNOLOGIES",
    subText:
      "With native mobile apps, you can exploit the inherent power of iOS and Android platforms with secure and performant apps.",
    middle: "Our specialist knowledge includes:",
    ul: [
      {
        id: "1",
        contant: "Extensive experience with Objective-C and Swift",
      },
      {
        id: "2",
        contant: "MVVM-C design pattern for project architecture",
      },
      {
        id: "3",
        contant:
          "RxSwift and Combine frameworks to carry out complex operations",
      },
    ],
  },
  {
    id: "2",
    bg: "#000000",
    color: "#FFFFFF",
    heading: "INDUSTRIES",
    subText:
      "We know Android inside-out, building advanced apps on this platform since 2012. We’ve amassed a wealth of experience in Android native development, getting the very best out of its ecosystem.",
    middle: "Our specialist knowledge includes:",
    ul: [
      {
        id: "1",
        contant: "Extensive experience with Java and KotlinTypography",
      },
      {
        id: "2",
        contant: "MVVM design pattern for project architecture",
      },
      {
        id: "3",
        contant: "RxJava and Flow frameworks for complex operations",
      },
      {
        id: "4",
        contant: "Coroutines for writing asynchronous code",
      },
    ],
  },
  {
    id: "3",
    bg: "#FAFAFA",
    color: "#000000",
    heading: "CUSTOM SOFTWARE DEVELOPMENT",
    subText:
      "We combine deep knowledge of native iOS and Android with cross-platform solution Flutter. Backed by Google, it means we can build apps for both operating systems using one codebase.",
    middle: "Our specialist knowledge includes:",
    ul: [
      {
        id: "1",
        contant: "Extensive experience with Objective-C and Swift",
      },
      {
        id: "2",
        contant: "MVVM-C design pattern for project architecture",
      },
      {
        id: "3",
        contant:
          "RxSwift and Combine frameworks to carry out complex operations",
      },
    ],
  },
  {
    id: "4",
    bg: "#FFA2D1",
    color: "#000000",
    heading: "NON PROFIT",
    subText:
      "React Native is a powerful framework used for building cross-platform applications. Backed by Facebook, it’s another great option when creating apps for Android and iOS platforms using one codebase.",
    middle: "Our specialist knowledge includes:",
    ul: [
      {
        id: "1",
        contant: "Writing bridges to connect native modules with JavaScript",
      },
      {
        id: "2",
        contant:
          "Redux for state management and Redux-Sagas for async side effects",
      },
      {
        id: "3",
        contant: "Declarative UI paradigm to manipulate native views directly",
      },
    ],
  },
  ,
  {
    id: "4",
    bg: "#FFA2D1",
    color: "#000000",
    heading: "ABOUT US",
    subText:
      "React Native is a powerful framework used for building cross-platform applications. Backed by Facebook, it’s another great option when creating apps for Android and iOS platforms using one codebase.",
    middle: "Our specialist knowledge includes:",
    ul: [
      {
        id: "1",
        contant: "Writing bridges to connect native modules with JavaScript",
      },
      {
        id: "2",
        contant:
          "Redux for state management and Redux-Sagas for async side effects",
      },
      {
        id: "3",
        contant: "Declarative UI paradigm to manipulate native views directly",
      },
    ],
  },
];

export const BlogCaseStudySliderArray = [
  {
    id: "1",
    bg: "#F5F5F5",
    color: "#000",
    heading:
      "MySquard an On Demand Marketplace App Development by IT Services India",
    inner:
      "Floret team had an idea of a platform for foodtech companies to store all the transaction records in one place. They  needed a UI/UX designer who could quickly create an MVP. After 2-months collaboration with It Services India, the company raised $2.3 million in funding for further development.",
    technology: "UI/UX redesign / UK / Education",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_04-crm.png",
    urlpath: "",
    ownerquote:
      "  “IT Services India transformed MySquard’s on-demand services with powerful, scalable web app development. Highly recommended!”",
    ownername: " Matutu Nyabange,\n founder",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Matutu-Nyabange.webp",
    companylogo: "",
  },
  {
    id: "2",
    bg: "#F5F5F5",
    color: "#000000",
    heading:
      "Vechit: A UK-Based Marketplace Redefining Seamless Selling and Shopping Experiences",
    inner:
      "With a vision to democratize access to data for everyone, Precog, a team of developers and mathematicians, started looking for tech-savvy UI/UX designers to help shape a usable product for their one-of-a-kind universal data connector and cloud ETL/ELT solution for data engineering. The product we created contributed to the company attracting $2 million in funding.",
    technology: "UI/UX Services / USA / Data analytics",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_07.png",
    urlpath: "",
    ownerquote:
      "I highly recommend IT Services India for their seamless app development for my business, Vechit, and their outstanding supportive team members.",
    ownername: "Kusum",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/auth-img-1.webp",
    companylogo: "",
  },
  {
    id: "3",
    bg: "#F5F5F5",
    color: "#000",
    heading:
      "Astro Aakshar an Astrology Mobile App Developed by IT Services India",
    inner:
      "At an inflection point in their journey, Clientjoy, a client lifecycle management platform for small agencies, turned to It Services India’s UI and UX services for UX audit. \n After \n analyzing visitor behavior and interviewing users, we discovered that the product required a complete UI/UX overhaul. We recreated the platform helping the team at Clientjoy get $800,000 in a pre-series A funding round.",
    technology: "UI/UX redesign / India / CRM",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_09.png",
    urlpath: "",
    ownerquote:
      "IT Services India created an intuitive and feature-rich mobile app that enhances user engagement and accessibility for our global audience. Their commitment to quality and timely delivery has greatly boosted our digital growth.",
    ownername: "Parth Patel",
    ownerimg:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/parth-patel.webp",
    companylogo: "",
  },
];

export const PopularBlogSliderArray = [
  {
    id: "1",
    bg: "#F5F5F5",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/blog-img1.webp",
    date: "5 AUG, 2024",
    title: "Why MVP Design Matters or How to Build an MVP Your Users Will Love",
    desc: "Looking for a designer that has the stomach to design an effective SaaS application? Read tips on how, where, and when to find a SaaS designer.",
  },
  {
    id: "2",
    bg: "#F5F5F5",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/blog-img1.webp",
    date: "5 AUG, 2024",
    title: "Why MVP Design Matters or How to Build an MVP Your Users Will Love",
    desc: "Looking for a designer that has the stomach to design an effective SaaS application? Read tips on how, where, and when to find a SaaS designer.",
  },
  {
    id: "3",
    bg: "#F5F5F5",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/blog-img1.webp",
    date: "5 AUG, 2024",
    title: "Why MVP Design Matters or How to Build an MVP Your Users Will Love",
    desc: "Looking for a designer that has the stomach to design an effective SaaS application? Read tips on how, where, and when to find a SaaS designer.",
  },
  {
    id: "4",
    bg: "#F5F5F5",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/blog-img1.webp",
    date: "5 AUG, 2024",
    title: "Why MVP Design Matters or How to Build an MVP Your Users Will Love",
    desc: "Looking for a designer that has the stomach to design an effective SaaS application? Read tips on how, where, and when to find a SaaS designer.",
  },
  {
    id: "5",
    bg: "#F5F5F5",
    color: "#000",
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/blog-img1.webp",
    date: "5 AUG, 2024",
    title: "Why MVP Design Matters or How to Build an MVP Your Users Will Love",
    desc: "Looking for a designer that has the stomach to design an effective SaaS application? Read tips on how, where, and when to find a SaaS designer.",
  },
];

export const ScrollDataArray = [
  {
    id: "1",
    name: "PROBLEM",
    inner: "Other platforms struggle to scale, causing performance issues.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "2",
        subName: "SOLUTION",
        subInner:
          "Laravel ensures scalability with caching and modular components.",
        bg: "#1d1e22",
        color: "#fff",
      },
    ],
  },
  {
    id: "3",
    name: "PROBLEM",
    inner:
      "Developers waste time on repetitive tasks like routing and validation.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "4",
        subName: "SOLUTION",
        subInner:
          "Laravel streamlines these tasks, allowing focus on unique features.",
        bg: "#CCF095",
        color: "#000",
      },
    ],
  },
  {
    id: "5",
    name: "PROBLEM",
    inner:
      " Many platforms limit customization, hindering personalized experiences.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "6",
        subName: "SOLUTION",
        subInner:
          "Laravel offers extensive customization for tailored user experiences.",
        bg: "#E4E4E4",
        color: "#000",
      },
    ],
  },
];

export const ScrollCodeigniterArray = [
  {
    id: "1",
    name: "PROBLEM",
    inner:
      " Many platforms struggle with scalability, leading to performance bottlenecks.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "2",
        subName: "SOLUTION",
        subInner:
          "CodeIgniter offers robust scalability through efficient caching and modular architecture.",
        bg: "#1d1e22",
        color: "#fff",
      },
    ],
  },
  {
    id: "3",
    name: "PROBLEM",
    inner:
      "Developers often face repetitive tasks that slow down the development process.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "4",
        subName: "SOLUTION",
        subInner:
          "CodeIgniter’s built-in tools automate routing, sessions, and validation, enhancing productivity.",
        bg: "#CCF095",
        color: "#000",
      },
    ],
  },
  {
    id: "5",
    name: "PROBLEM",
    inner:
      "Customization limitations in some frameworks hinder unique application experiences.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "6",
        subName: "SOLUTION",
        subInner:
          "CodeIgniter provides extensive customization options through flexible APIs and modules.",
        bg: "#E4E4E4",
        color: "#000",
      },
    ],
  },
];

export const ScrollGatsbyArray = [
  {
    id: "1",
    name: "PROBLEM",
    inner:
      "Other platforms often struggle with scalability, leading to slow and inefficient websites.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "2",
        subName: "SOLUTION",
        subInner:
          "Gatsby JS ensures unmatched scalability, delivering lightning-fast and reliable performance.",
        bg: "#1d1e22",
        color: "#fff",
      },
    ],
  },
  {
    id: "3",
    name: "PROBLEM",
    inner:
      "Integrating content from multiple sources can be challenging and time-consuming.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "4",
        subName: "SOLUTION",
        subInner:
          "Gatsby JS seamlessly connects with headless CMSs for smooth multi-source integration.",
        bg: "#7958AD",
        color: "#fff",
      },
    ],
  },
  {
    id: "5",
    name: "PROBLEM",
    inner:
      "Rigid frameworks can limit creativity, restricting unique user experiences.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "6",
        subName: "SOLUTION",
        subInner:
          "Gatsby JS offers flexibility with extensive customization for tailored, dynamic applications.",
        bg: "#E4E4E4",
        color: "#000",
      },
    ],
  },
];

export const ScrollMagentoArray = [
  {
    id: "1",
    name: "PROBLEM",
    inner: "High traffic can crash an ecommerce site.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "2",
        subName: "SOLUTION",
        subInner: "Magento's scalable architecture ensures stable performance.",
        bg: "#1d1e22",
        color: "#fff",
      },
    ],
  },
  {
    id: "3",
    name: "PROBLEM",
    inner: "Managing multiple sales channels causes inventory issues.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "4",
        subName: "SOLUTION",
        subInner:
          "Magento's omnichannel integration synchronizes inventory in real-time.",
        bg: "#FF8E5E",
        color: "#000",
      },
    ],
  },
  {
    id: "5",
    name: "PROBLEM",
    inner: "Security vulnerabilities risk exposing customer data.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "6",
        subName: "SOLUTION",
        subInner:
          "Magento's robust security features, including PCI compliance, protect data.",
        bg: "#E4E4E4",
        color: "#000",
      },
    ],
  },
];

export const ScrollShopifyArray = [
  {
    id: "1",
    name: "PROBLEM",
    inner: "Other platforms struggle to scale effectively.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "2",
        subName: "SOLUTION",
        subInner: "Shopify provides seamless scalability for traffic.",
        bg: "#1d1e22",
        color: "#fff",
      },
    ],
  },
  {
    id: "3",
    name: "PROBLEM",
    inner: "Managing multi-channel sales can be challenging.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "4",
        subName: "SOLUTION",
        subInner: "Shopify unifies all sales channels in one place. ",
        bg: "#ccf095",
        color: "#000",
      },
    ],
  },
  {
    id: "5",
    name: "PROBLEM",
    inner: "E-commerce platforms limit customization options.",
    bg: "#F6F4F2",
    color: "#000",
    secItem: [
      {
        id: "6",
        subName: "SOLUTION",
        subInner: "Shopify allows extensive customization with APIs.",
        bg: "#E4E4E4",
        color: "#000",
      },
    ],
  },
];
export const GlobleDesignAndDevelopMapData = [
  {
    id: 1,
    name: "Healthcare",
    link: "/healthcare-software-development-services",
  },
  {
    id: 2,
    name: "Education / eLearning",
    link: "/elearning-software-development",
  },
  {
    id: 3,
    name: "Retail / eCommerce",
    link: "/ecommerce-development-services",
  },
  {
    id: 4,
    name: "Real Estate",
    link: "/real-estate-software-development-company",
  },
  {
    id: 5,
    name: "Travel & Hospitality",
    link: "#",
  },
  {
    id: 6,
    name: "Logistics & Distribution",
    link: "/logistics-software-development-company",
  },
  {
    id: 7,
    name: "Media & Entertainment",
    link: "/social-media-app-development",
  },
  {
    id: 8,
    name: "Fintech",
    link: "/fintech-software-development",
  },
  {
    id: 9,
    name: "Fitness App ",
    link: "/fitness-and-wellness-app-development-services",
  },
  {
    id: 10,
    name: "On-Demand App",
    link: "/on-demand-app-development",
  },
];
export const GlobleTechnologiesMapData = [
  {
    id: "1",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Apple.svg",
    Logo_name: "IOS",
    category: "1",
  },
  {
    id: "2",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Android.svg",
    Logo_name: "Android",
    category: "1",
  },
  {
    id: "3",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/flutter.svg",
    Logo_name: "Flutter",
    category: "1",
  },
  {
    id: "4",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/react.svg",
    Logo_name: "React",
    category: "1",
  },
  {
    id: "6",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/swift.svg",
    Logo_name: "Swift",
    category: "1",
  },
  {
    id: "5",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/ionic.svg",
    Logo_name: "Ionic",
    category: "1",
  },
  {
    id: "7",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/kotlin.svg",
    Logo_name: "Kotlin",
    category: "1",
  },
  {
    id: "8",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/ar-kit.svg",
    Logo_name: "AR Kit",
    category: "1",
  },

  {
    id: "9",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Flutter.svg",
    Logo_name: "Flutter",
    category: "2",
  },
  {
    id: "10",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/React.svg",
    Logo_name: "React",
    category: "2",
  },
  {
    id: "11",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Ionic.svg",
    Logo_name: "Ionic",
    category: "3",
  },
  {
    id: "12",
    Icon_img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Swift.svg",
    Logo_name: "Swift",
    category: "4",
  },
  {
    id: "13",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kotlin.svg",
    Logo_name: "Kotlin",
    category: "5",
  },
  {
    id: "14",
    Icon_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/AR-Kit.svg",
    Logo_name: "AR Kit",
    category: "6",
  },
];

export const SingleCaseData = [
  {
    id: "1",
    pathName: "/case-study/skyhub",
    inner_title: "skyhub",
    title:
      "SKYHUB Leading Real Estate Analytical Capabilities with Cutting-edge Technology​",
    main_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_01.png",
    title_1: "Industry: Real Estate",
    subtext_1:
      "Established in 2016, SKYHUB has grown into a premier real estate platform, offering comprehensive solutions for buying, selling, and investing in properties across Canada. Headquarters in Toronto, Canada, they cater to a diverse range of clients, from first-time homebuyers to seasoned investors and real estate developers. With a commitment to innovation, the SKYHUB giant has been at the forefront of adopting cutting-edge technologies to enhance customer experiences, offering a spectrum of services, including to simplify the property search and transaction process and deliver seamless experiences. Whether you’re looking for residential homes, commercial spaces, or investment opportunities, SKYHUB is your trusted partner for all things real estate. ",
    sub_title_1: "",
    sub_txt_1: "",
    l1class_Name: "hidden !pt-[0px]",
    list_1: [],
    title_2: "Business Challenges ",
    subtext_2: "",
    sub_title_2: "",
    sub_txt_2: "",
    l5class_Name: "!pt-[0px]",
    list_2: [
      {
        id: "1",
        contant:
          "The current MLS data integration using CREA and TREB scripts is leading to operational bottlenecks, with suboptimal performance during peak hours due to the reliance on night-mode execution.",
      },
      {
        id: "2",
        contant:
          "The platform experiences significant slowdowns, impacting the user experience and limiting its scalability. Database performance is compromised by the scripts, creating an unsustainable setup for long-term growth. ",
      },
      {
        id: "3",
        contant:
          "The client is facing Inaccuracy in the property data that is frequently displayed, including expired listings still marked as active, and incomplete updates. This undermines the platform’s reliability and erodes customer trust. ",
      },
    ],
    proDetail: [
      {
        id: "1",
        title: "Technologies",
        content: "NextJS, React Native, ReactJS, NodeJS, AWS",
      },
      {
        id: "2",
        title: "Industry",
        content: "REAL ESTATE",
      },
      {
        id: "3",
        title: "Platform",
        content: "Mobile & Web",
      },
      {
        id: "4",
        title: "Team",
        content: "6 Member",
      },
      {
        id: "5",
        title: "Timeline",
        content: "2 year ",
      },
    ],
    title_3: "Solution ",
    subtext_3: "",
    sub_title_3: "",
    sub_txt_3: "",
    l3class_Name: "!pt-[0px]",
    list_3: [
      {
        id: "1",
        contant:
          "To address data accuracy, IT Services India Inc. proposes implementing real-time updates for CREA and TREB listings, with automated data synchronization every 15 minutes. This ensures property listings are consistently current and reliable. ",
      },
      {
        id: "2",
        contant:
          "IT Services India Inc. recommends segregating script execution from the main platform and database operations, ensuring that MLS data scripts run without impacting platform speed or database performance. This separation will allow for smooth platform operation, even during script execution.",
      },
      {
        id: "3",
        contant:
          "All background scripts will be executed separately from the main platform operations, ensuring no impact on front-end performance. This isolation ensures that the customer experience remains uninterrupted, regardless of backend processes.",
      },
      {
        id: "4",
        contant:
          "Listings will not appear on the platform until all associated images have fully loaded. This ensures a consistent and polished user experience, preventing incomplete content from being displayed.",
      },
      {
        id: "5",
        contant:
          "The solution focuses on speed as the highest priority, with content delivery being streamlined to ensure optimal performance. The goal is to maintain a high-quality user experience, with no page loading in more than 0.88 milliseconds, ensuring swift navigation and response times ",
      },
      {
        id: "6",
        contant:
          "In SKYHUB, there is a CRM integrated into the backend. Its primary function, for now, is to manage all the leads and user queries, which are handled directly by the admin. The admin then assigns these leads to the respective agents. Also, the CRM in the backend is used to manage property tour bookings.",
      },
    ],

    before_img: "",

    title_4: "Technology Used",
    subtext_4:
      "By utilizing cutting-edge technology such as <b><a class='HUderLine' href='/next-js-development-company' target='_blank'>Next.js</a> and React.js</b> on the <a class='HUderLine' href='/front-end-development' target='_blank'><b>frontend</b></a> and <b>Node.js</b> on the backend, SKYHUB has revolutionized its platform's performance and scalability. News section is powered by a <b>Headless CMS</b> and hosted on <b>AWS</b>, with integrated <b>CI/CD pipelines, CloudFront URL, S3 bucket, and Route 53 domain hosting</b>, the platform now operates with enhanced efficiency and agility. These advancements not only streamlined operations but also solidified SKYHUB’s position as a leader in real estate technology, setting a new benchmark for innovation and performance in the industry.",
    sub_title_4: "",
    sub_txt_4: "",
    l4class_Name: "hidden !pt-[0px]",
    list_4: [],
    Col_2img: [],
    Col_2img1: [],
    card_img: [
      // {
      //   id: "1",
      //   c_img:
      //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub03.webp",
      // },
    ],

    title_5: "Business Impact",
    subtext_5: "",
    sub_title_5: "",
    sub_txt_5: "",
    l5class_Name: "!pt-[0px]",
    list_5: [
      {
        id: "1",
        contant:
          "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      },
      {
        id: "2",
        contant:
          "Segregating scripts has maintained <b>91.9% uptime</b>, reducing downtime by <b>25%</b> and ensuring seamless platform use.",
      },
      {
        id: "3",
        contant:
          "Isolating backend processes prevents any disruption to the front-end, maintaining a smooth and fast platform interaction for users.",
      },
      {
        id: "4",
        contant:
          "Listings are displayed only when fully loaded, ensuring consistent, professional visuals that enhance platform credibility.",
      },
      {
        id: "5",
        contant:
          "Content delivery within 0.88 milliseconds ensures fast navigation and a superior user experience, reducing bounce rates.",
      },
      {
        id: "6",
        contant:
          "The CRM has enhanced operational efficiency by <b>20%</b> and sped up response times by <b>15%</b>, improving lead conversion and customer service.",
      },
      {
        id: "7",
        contant:
          "These improvements have driven a <b>40% increase in overall business performance</b> for SKYHUB.",
      },
    ],

    title_6: "Conclusion",
    subtext_6:
      "By leveraging cutting-edge technology, SKYHUB not only resolved its operational challenges but also positioned itself as a trailblazer in the real estate industry. The successful implementation of advanced platform enhancements has streamlined processes, enabling SKYHUB to quickly adapt to changing market dynamics. This transformation has set a new standard for innovation and efficiency in property listings and lead management, establishing SKYHUB as a leader in delivering data-driven, high-performance solutions in the real estate sector.",
    sub_title_6: "",
    sub_txt_6: "",
    l6class_Name: "hidden !pt-[0px]",
    list_6: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_7: "",
    subtext_7: "",
    sub_title_7: "",
    sub_txt_7: "",
    l7class_Name: "hidden !pt-[0px]",
    list_7: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_8: "",
    subtext_8: "",
    sub_title_8: "",
    sub_txt_8: "",
    l8class_Name: "hidden !pt-[0px]",
    list_8: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    img_2:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_02.png",
    img_3:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_03-logo.png",
    class: "skyhub",
    img_4:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_04.png",
    img_5:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_05.png",
    img_6:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_06.png",
    img_7:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_07.png",
    img_8:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_08.png",
    img_9:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_09.png",
    img_10:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Skyhub_010.png",
    count: [
      {
        id: "1",
        Count_Num: "11.6%",
        Count_Txt: "Decreased loading time across all pre-cached pages.",
      },
      {
        id: "2",
        Count_Num: "30%+",
        Count_Txt: "Increase in conversion rates.",
      },
      {
        id: "3",
        Count_Num: "2.94 Seconds",
        Count_Txt: "To load PWA and enables checkout within 20 seconds.",
      },
    ],
  },
  {
    id: "2",
    pathName: "/case-study/mysquard",
    inner_title: "MySquard",
    title: "MySquard Application Case Study",
    main_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_01_new-crm.png",
    title_1: "Overview",
    subtext_1:
      "Without data analytics, companies on the web are blind like a deer caught in headlights. They have no idea about what, when and how their users are doing. Still, may prefer to stay blind because you need either an engineer or a data scientist to make sense of an awful lot of information.",
    sub_title_1: "",
    sub_txt_1: "",
    l1class_Name: "hidden !pt-[0px]",
    list_1: [],
    proDetail: [
      {
        id: "1",
        title: "Technologies",
        content: "React Native, Node.js, AWS, Stripe, RTT, CBA",
      },
      {
        id: "2",
        title: "Industry",
        content: "LIFESTYLE",
      },
      {
        id: "3",
        title: "Platform",
        content: "Mobile & Web",
      },
      {
        id: "4",
        title: "Team",
        content: "6 Member",
      },
      {
        id: "5",
        title: "Timeline",
        content: "2 year ",
      },
    ],
    title_2: "Key Features ",
    subtext_2: "",
    sub_title_2: "",
    sub_txt_2: "",
    l2class_Name: "!pt-[0px]",
    list_2: [
      {
        id: "1",
        contant:
          "Service Provider Notifications: Once a user books a service, the assigned provider receives instant notifications detailing the date and time of the job.",
      },
      {
        id: "2",
        contant:
          "Order Tracking: Customers can monitor their service provider's status from the moment they accept the order until they arrive at the customer's doorstep.",
      },
      {
        id: "3",
        contant:
          "Flexible Payment Options: Payment is made only after the service is completed, with options for online transactions. An invoice is generated post-service, ensuring transparency.",
      },
    ],
    before_img: "",
    title_3: "Project Details",
    subtext_3: "",
    sub_title_3: "",
    sub_txt_3: "",
    l3class_Name: "!pt-[0px]",
    list_3: [
      {
        id: "1",
        contant: "Industry: On-Demand Marketplace App",
      },
      {
        id: "2",
        contant: "Location: US ",
      },
      {
        id: "3",
        contant: "Timeline: 2.5 Years",
      },
    ],
    Col_2img: [],
    Col_2img1: [],
    card_img: [
      // {
      //   id: "1",
      //   c_img:
      //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub03.webp",
      // },
    ],

    title_4: "The Challenge",
    subtext_4:
      "MySquard is an innovative marketplace application developed for both Android and iOS platforms. It connects users with relevant service providers, allowing them to book services for specific dates. The app is designed exclusively for users in the US, offering a seamless experience for finding and scheduling services.",
    sub_title_4: "The Solution",
    sub_txt_4:
      "We developed MySquard with a focus on user experience and operational efficiency. By utilizing <a class='HUderLine' href='/react-native-app-development-company' target='_blank'>React Native</a> for the <a class='HUderLine' href='/front-end-development' target='_blank'>front-end</a> and <a class='HUderLine' href='/node-js-development-company' target='_blank'>Node.js</a> for the back-end, we ensured high performance and scalability.",
    l4class_Name: "hidden !pt-[0px]",
    list_4: [],

    title_5: "Outcomes",
    subtext_5: "",
    sub_title_5: "",
    sub_txt_5: "",
    l5class_Name: "!pt-[0px]",
    list_5: [
      {
        id: "1",
        contant:
          "Web Dashboard: Users can manage their MySquard accounts, add team members, change pricing plans, and access detailed reports on previous orders and payment history.",
      },
      {
        id: "2",
        contant:
          "Robust Backend: Hosted on Amazon Web Services, MySquard’s backend supports order management and various server industries with features like menu uploads and tax management. ",
      },
      {
        id: "3",
        contant:
          "Real-Time Tracking: The app includes real-time location tracking so service providers can navigate to customers’ homes easily. Customers can also track updates on their service requests.",
      },
    ],

    title_6: "Our Secret Recipe",
    subtext_6:
      "Through extensive industry research, we identified unique requirements for on-demand marketplace applications. This led us to create a cutting-edge solution that revolutionizes the industry while maintaining a competitive edge.",
    sub_title_6: "",
    sub_txt_6: "",
    l6class_Name: "hidden !pt-[0px]",
    list_6: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_7: "Key Highlights",
    subtext_7:
      "<ol><li>Scalable Architecture: MySquard’s cloud-based technology allows both employees and contractors to input data that is universally accessible.</li><li>Real-Time Service Updates: The app enables partners to modify job orders based on customer requests in real-time.</li><li>Secure Payments with Stripe: Our integration with Stripe ensures secure transactions for customers and instant payouts for service providers.</li></ol>",
    sub_title_7: "",
    sub_txt_7: "",
    l7class_Name: "hidden  !pt-[0px]",
    list_7: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_8: "",
    subtext_8:
      "MySquard exemplifies how technology can bridge gaps in service delivery while enhancing user satisfaction in the on-demand marketplace landscape. <a class='HUderLine' href='/' target='_blank'>IT Services India</a> is happy to be a part of this journey",
    sub_title_8: "",
    sub_txt_8: "",
    l8class_Name: "hidden !pt-[0px]",
    list_8: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],
    count: [
      {
        id: "1",
        Count_Num: "11.6%",
        Count_Txt: "Decreased loading time across all pre-cached pages.",
      },
      {
        id: "2",
        Count_Num: "30%+",
        Count_Txt: "Increase in conversion rates.",
      },
      {
        id: "3",
        Count_Num: "2.94 Seconds",
        Count_Txt: "To load PWA and enables checkout within 20 seconds.",
      },
    ],
    class: "mysquard",
    img_2:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_02-crm.png",
    img_3:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_03-crm-logo.png",
    img_4:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_04-crm.png",
    img_5:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_05-crm.png",
    img_6:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_06-crm.png",
    img_7:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_07-crm.png",
    img_8:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_08-crm.png",
    img_9:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_09-crm.png",
    img_10:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_010-crm.png",
    img_11:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_011-crm.png",
    img_12:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/mysquard_012-crm.png",
  },
  {
    id: "3",
    pathName: "/case-study/giddyup-canvasser",
    inner_title: "Giddyup Canvasser",
    title: "Canvasser Pro Mobile App Development",
    main_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_01.png",
    title_1: "Overview",
    subtext_1:
      "<a class='HUderLine' href='https://www.cuttercroix.com/Canvasser' targt='_blank' >Canvasser Pro</a> is a <a class='HUderLine' href='/mobile-app-development-company' targt='_blank' >mobile application</a> designed for efficient door-to-door canvassing. Developed using <a class='HUderLine' href='/react-native-app-development-company' targt='_blank' >React Native</a>, it streamlines campaigning by enabling smarter scheduling, enhanced lead management, and seamless follow-ups.",
    sub_title_1: "",
    sub_txt_1: "",
    l1class_Name: "hidden !pt-[0px]",
    list_1: [],
    title_2: "Key Features:",
    subtext_2: "",
    sub_title_2: "",
    sub_txt_2: "",
    l5class_Name: "!pt-[0px]",
    list_2: [
      {
        id: "1",
        contant:
          "<b>Drag-and-Drop Scheduling:</b> Intuitive calendar integration allows teams to view and manage appointments effortlessly.",
      },
      {
        id: "2",
        contant:
          "<b>Voice-to-Text Note-Taking:</b> Speeds up documentation with in-built dictation capabilities.",
      },
      {
        id: "3",
        contant:
          "<b>Lead Qualification:</b> Smart, personalized questions simplify lead assessment.",
      },
      {
        id: "4",
        contant:
          "<b>Digital Signature Capture:</b> Eliminates paperwork for a more efficient workflow.",
      },
      {
        id: "5",
        contant:
          "<b>Advanced Reporting & Mapping Tools:</b> Real-time data insights optimize campaign performance.",
      },
      {
        id: "6",
        contant:
          "<b>Smart Knocking:</b> Access optional homeowner and property details for informed interactions.",
      },
    ],
    proDetail: [
      {
        id: "1",
        title: "Technologies",
        content: "NextJS, React Native, ReactJS, NodeJS, AWS",
      },
      {
        id: "2",
        title: "Industry",
        content: "REAL ESTATE",
      },
      {
        id: "3",
        title: "Platform",
        content: "Mobile & Web",
      },
      {
        id: "4",
        title: "Team",
        content: "6 Member",
      },
      {
        id: "5",
        title: "Timeline",
        content: "2 year ",
      },
    ],
    title_3: "",
    subtext_3:
      "Canvasser Pro empowers campaigners with data-driven tools and a user-friendly interface, enhancing productivity and decision-making in every step of the canvassing process.",
    sub_title_3: "",
    sub_txt_3: "",
    l3class_Name: "!pt-[0px]",
    list_3: [],

    before_img: "",

    title_4: "",
    subtext_4: "",
    sub_title_4: "",
    sub_txt_4: "",
    l4class_Name: "hidden !pt-[0px]",
    list_4: [],
    Col_2img: [],
    Col_2img1: [],
    card_img: [
      // {
      //   id: "1",
      //   c_img:
      //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub03.webp",
      // },
    ],

    title_5: "",
    subtext_5: "",
    sub_title_5: "",
    sub_txt_5: "",
    l5class_Name: "hidden !pt-[0px]",
    list_5: [],

    title_6: "",
    subtext_6: "",
    sub_title_6: "",
    sub_txt_6: "",
    l6class_Name: "hidden !pt-[0px]",
    list_6: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_7: "",
    subtext_7: "",
    sub_title_7: "",
    sub_txt_7: "",
    l7class_Name: "hidden !pt-[0px]",
    list_7: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_8: "",
    subtext_8: "",
    sub_title_8: "",
    sub_txt_8: "",
    l8class_Name: "hidden !pt-[0px]",
    list_8: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],
    class: "giddyupCanvasser",
    img_2:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_02.png",
    img_3:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_03-logo.png",
    img_4:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_04.png",
    img_5:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_05.png",
    img_6:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_06.png",
    img_7:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_07.png",
    img_8:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_08.png",
    img_9:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_09.png",
    img_10:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Giddyup_010.png",

    count: [
      {
        id: "1",
        Count_Num: "11.6%",
        Count_Txt: "Decreased loading time across all pre-cached pages.",
      },
      {
        id: "2",
        Count_Num: "30%+",
        Count_Txt: "Increase in conversion rates.",
      },
      {
        id: "3",
        Count_Num: "2.94 Seconds",
        Count_Txt: "To load PWA and enables checkout within 20 seconds.",
      },
    ],
  },
  {
    id: "4",
    pathName: "/case-study/deliveree",
    inner_title: "Deliveree",
    title: "Deliveree Case Study",
    main_img:
      "https://www.itserviceus.com/wp-content/uploads/2023/03/Image-575.jpg",
    title_1: "Overview",
    subtext_1:
      "Deliveree is a cutting-edge <a class='HUderLine' href='/mobile-app-development-company' target='_blank'>marketplace application developed</a> using <a class='HUderLine' href='/react-native-app-development-company' target='_blank'>React Native technology</a>. It is designed to deliver goods and services directly to customers’ doorsteps in Puerto Rico. Our mission is to provide a seamless on-demand delivery experience, allowing users to track their orders in real-time.",
    sub_title_1: "",
    sub_txt_1: "",
    l1class_Name: "!pt-[0px]",
    list_1: [
      {
        id: "1",
        contant: "Client: SiteVibes",
      },
      {
        id: "2",
        contant: "Industry: Fast-Tracking Software",
      },
      {
        id: "3",
        contant: "Team Size: 6 ",
      },
      {
        id: "4",
        contant: "Project Duration: 1.5 Years",
      },
    ],
    title_2: "Project Highlights",
    subtext_2:
      "Deliveree offers a range of solutions tailored for efficient order management and enhanced user experience, including:",
    sub_title_2: "",
    sub_txt_2: "",
    l5class_Name: "!pt-[0px]",
    list_2: [
      {
        id: "1",
        contant:
          "<a class='HUderLine' href='/swift' targt='_blank' >Swift</a> Backend Order Management: A robust system that efficiently handles high volumes of orders and users.",
      },
      {
        id: "2",
        contant:
          "Custom Dashboards: Tailored dashboards for restaurant partners and delivery personnel to streamline operations.",
      },
      {
        id: "3",
        contant:
          "Stripe Payment Integration: A custom API for managing payouts to partners and calculating customer taxes.",
      },
    ],
    proDetail: [
      {
        id: "1",
        title: "Technologies",
        content: "NextJS, React Native, ReactJS, NodeJS, AWS",
      },
      {
        id: "2",
        title: "Industry",
        content: "REAL ESTATE",
      },
      {
        id: "3",
        title: "Platform",
        content: "Mobile & Web",
      },
      {
        id: "4",
        title: "Team",
        content: "6 Member",
      },
      {
        id: "5",
        title: "Timeline",
        content: "2 year ",
      },
    ],
    title_3: "Our Approach",
    subtext_3:
      "To create a flexible, robust, and technologically advanced food delivery app, we utilized a marketplace model of <a class='HUderLine' href='/ecommerce-development-services' target='_blank'>eCommerce</a>. This allowed multiple vendors to receive orders directly from customers, ensuring quick and efficient deliveries.",
    sub_title_3: "",
    sub_txt_3: "",
    l3class_Name: "hidden !pt-[0px]",
    list_3: [],

    before_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub01.webp",

    title_4: "Key Features",
    subtext_4: "",
    sub_title_4: "",
    sub_txt_4: "",
    l4class_Name: " !pt-[0px]",
    list_4: [
      {
        id: "1",
        contant:
          "Cross-Platform Support <br>Deliveree was developed to support both <a class='HUderLine' href='/android-app-development-services' target='_blank'>Android</a> and <a class='HUderLine' href='/ios-app-development-company' target='_blank'>iOS</a> users seamlessly, eliminating the need for separate applications and simplifying updates and maintenance.",
      },
      {
        id: "2",
        contant:
          "Efficient Backend Order Management <br />We implemented a powerful backend system capable of processing multiple orders simultaneously, ensuring 24/7 availability due to the marketplace model. Secure Payments with Stripe",
      },
      {
        id: "3",
        contant:
          "Secure Payments with Stripe <br />Deliveree leverages Stripe’s payment infrastructure for secure transactions, allowing instant payouts to partners and facilitating a weekly pay structure.",
      },
    ],
    Col_2img: [],
    Col_2img1: [],
    card_img: [
      // {
      //   id: "1",
      //   c_img:
      //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub03.webp",
      // },
    ],

    title_5: "Development Process",
    subtext_5:
      "Using React Native and <a class='HUderLine' href='/node-js-development-company' target='_blank'>Node.js</a>, we ensured Deliveree was compatible with both Android and iOS devices. Our development included creating a robust backend order processing system along with advanced server architecture for rapid scaling. This allowed Deliveree's vendors not only to deliver goods but also to enhance customer satisfaction.",
    sub_title_5: "",
    sub_txt_5: "",
    l5class_Name: "hidden !pt-[0px]",
    list_5: [],

    title_6: "Benefits of React Native",
    subtext_6:
      "The React Native framework enabled us to achieve <a class='HUderLine' href='/cross-platform-app-development-company' target='_blank'>cross-platform</a> functionality while significantly shortening the development cycle. This technology also facilitated fast maintenance and upgrade procedures.",
    sub_title_6: "",
    sub_txt_6: "",
    l6class_Name: "hidden !pt-[0px]",
    list_6: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_7: "Conclusion",
    subtext_7:
      "Deliveree exemplifies our commitment to delivering innovative solutions in the on-demand delivery space. By implementing a marketplace <a class='HUderLine' href='/business-engagement-models-it-services-india' target='_blank'>business model</a> with a focus on user experience and operational efficiency, we have created an application that meets the unique needs of both customers and service providers in Puerto Rico.",
    sub_title_7: "",
    sub_txt_7: "",
    l7class_Name: "hidden !pt-[0px]",
    list_7: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_8: "",
    subtext_8: "",
    sub_title_8: "",
    sub_txt_8: "",
    l8class_Name: "hidden !pt-[0px]",
    list_8: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],
    class: "deliveree",
    img_2:
      "https://www.itserviceus.com/wp-content/uploads/2023/03/Mask-Group-317.jpg",
    img_3:
      "https://www.itserviceus.com/wp-content/uploads/2023/03/Group-13621-3.png",
    img_4:
      "https://www.itserviceus.com/wp-content/uploads/2023/03/Group-13621-3.png",
    img_5:
      "https://www.itserviceus.com/wp-content/uploads/2023/03/Splash01.jpg",

    count: [
      {
        id: "1",
        Count_Num: "11.6%",
        Count_Txt: "Decreased loading time across all pre-cached pages.",
      },
      {
        id: "2",
        Count_Num: "30%+",
        Count_Txt: "Increase in conversion rates.",
      },
      {
        id: "3",
        Count_Num: "2.94 Seconds",
        Count_Txt: "To load PWA and enables checkout within 20 seconds.",
      },
    ],
  },
  {
    id: "5",
    pathName: "/case-study/astro-aakshar",
    inner_title: "Astro Aakshar",
    title:
      "Redefining Astrology with Innovation - Astroaakshar by IT Services India",
    main_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_01.png",
    title_1: "Category/Industry:",
    subtext_1:
      "<ul><li>Technology Development</li><li>Mobile Application Development</li><li>E-commerce Solutions</li><li>Astrology & Lifestyle Services</li></ul>",
    sub_title_1: "Services:",
    sub_txt_1:
      "<ul><li>Personalized Astrology</li><li>Numerology Services</li><li>Horoscope and Kundli Readings</li><li>E-commerce for Astro Products</li></ul>",
    l1class_Name: "hidden !pt-[0px]",
    list_1: [
      {
        id: "1",
        contant: "Technology Development",
      },
      {
        id: "2",
        contant: "Mobile Application Development",
      },
      {
        id: "3",
        contant: "E-commerce Solutions",
      },
      {
        id: "4",
        contant: "Astrology & Lifestyle Services",
      },
    ],
    title_2: "Challenges",
    subtext_2: "",
    sub_title_2: "",
    sub_txt_2: "",
    l5class_Name: "!pt-[0px]",
    list_2: [
      {
        id: "1",
        contant:
          "<b>Global Accessibility:</b> <br />Delivering astrology services to users across the globe was a significant challenge. This required addressing issues like time zone differences, multi-language support, and ensuring users from remote locations had seamless access to app features.",
      },
      {
        id: "2",
        contant:
          "<b>Location-Based Service Recommendations:</b> <br />Providing users with relevant astrologers and services based on their location required a robust geo-location system integrated with real-time data updates. This ensured users could connect with the best astrologers while considering regional preferences.",
      },
      {
        id: "3",
        contant:
          "<b>E-commerce Integration and Scalability:</b> <br />Incorporating a fully <a class='HUderLine' href='/ecommerce-development-services' target>functional e-commerce platform</a> was challenging. It required managing inventory, ensuring smooth and secure transactions, and creating an optimized user experience for browsing and purchasing certified astro products. Scalability was essential to handle growing product lines and user traffic.",
      },
      {
        id: "4",
        contant:
          "<b>Astrologer Management and Records:</b> <br />Managing astrologer profiles, availability schedules, and expertise levels while providing real-time updates on consultation slots was complex. Ensuring accurate records and seamless user-astrologer connections was vital for user satisfaction.",
      },
      {
        id: "5",
        contant:
          "<b>User History and Customer Management:</b> <br />Storing detailed user history, including past consultations, number of attendees, and personalized recommendations, was a challenge. Additionally, implementing a customer management system to handle queries, reviews, and service continuity was necessary for enhancing user loyalty.",
      },
    ],
    proDetail: [
      {
        id: "1",
        title: "Technologies",
        content: "ReactJS, React Native, NodeJS, AWS, Stripe, MongoDB",
      },
      {
        id: "2",
        title: "Industry",
        content: "LIFESTYLE",
      },
      {
        id: "3",
        title: "Platform",
        content: "Mobile & Web",
      },
      {
        id: "4",
        title: "Team",
        content: "5 Member",
      },
      {
        id: "5",
        title: "Timeline",
        content: "1 year ",
      },
    ],
    title_3: "Solutions",
    subtext_3: "",
    sub_title_3: "",
    sub_txt_3: "",
    l3class_Name: "!pt-[0px]",
    list_3: [
      {
        id: "1",
        contant:
          "<b>Unified <a class='HUderLine' href='/cross-platform-app-development-company' target='_blank'>Cross-Platform Development</a> with ReactJS and React Native:</b> <br />IT Services India leveraged ReactJS for web development and React Native for mobile applications to deliver a unified, cross-platform experience. This ensured the app’s features and design were consistent across devices, reducing development complexity and enhancing the user journey.",
      },
      {
        id: "2",
        contant:
          "<b>Advanced Geo-Location and Scheduling Features:</b> <br />A robust geo-location framework was integrated to recommend services and astrologers based on the user’s location. A scheduling system with real-time updates allowed users to book consultations in their preferred time zones.",
      },
      {
        id: "3",
        contant:
          "<b>Seamless E-commerce Functionality:</b> <br /><a class='HUderLine' href='/' target='_blank'>IT Services India</a> implemented a user-friendly e-commerce platform within the app. Features like inventory management, secure payment integration via Stripe, and optimized product search made it easy for users to browse and purchase certified astro products. Scalability ensured smooth performance even with increased product offerings.",
      },
      {
        id: "4",
        contant:
          "<b>Astrologer Dashboard for Efficient Management:</b> A dedicated dashboard was created to manage astrologer profiles, availability, and expertise. This system provided users with easy access to astrologers while ensuring astrologer records remained accurate and up-to-date.",
      },
      {
        id: "5",
        contant:
          "<b>User History and Customer Relationship Management (CRM):</b> The backend was designed to securely store user history, including consultation details, attendees, and personalized preferences. A <a class='HUderLine' href='/custom-crm-development' target='_blank'>robust CRM</a> was also implemented to streamline user queries, manage feedback, and ensure smooth communication between users and astrologers.",
      },
    ],

    before_img: "",

    title_4: "Technologies Used",
    subtext_4: "",
    sub_title_4: "",
    sub_txt_4: "",
    l4class_Name: " !pt-[0px]",
    list_4: [
      {
        id: "1",
        contant:
          "<b><a class='HUderLine' href='/react-js-development-company' target='_blank'>ReactJS:</a></b> For a scalable and user-friendly web interface.",
      },
      {
        id: "2",
        contant:
          "<b><a class='HUderLine' href='/react-native-app-development-company' target='_blank'>React Native:</a></b> For consistent performance on iOS and Android platforms.",
      },
      {
        id: "3",
        contant:
          "<b><a class='HUderLine' href='/node-js-development-company' target='_blank'>Node.js:</a></b> For building a high-performance backend that supports real-time data processing.",
      },
      {
        id: "4",
        contant:
          "<b>Amazon Web Services (AWS):</b> For scalable, reliable cloud hosting that ensures the app performs well under high traffic.",
      },
      {
        id: "5",
        contant:
          "<b>Stripe Payments:</b> Integrated for secure and seamless transactions for purchasing certified Astro products.",
      },
      {
        id: "6",
        contant:
          "<b>MongoDB:</b> Chosen for its flexibility in managing diverse datasets, including user profiles, astrologer records, and product inventory.",
      },
    ],
    Col_2img: [],
    Col_2img1: [],
    card_img: [
      // {
      //   id: "1",
      //   c_img:
      //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub03.webp",
      // },
    ],

    title_5: "Conclusion",
    subtext_5:
      "Astroaakshar by <a class='HUderLine' href='/' target='_blank'>IT Services India</a> has successfully bridged the gap between traditional astrology services and modern technology. By integrating personalized astrology services, e-commerce for certified astro products, and a seamless cross-platform experience, the app has set a new standard in the lifestyle and astrology industry. Leveraging advanced technologies like <a class='HUderLine' href='/react-js-development-company' target='_blank'>ReactJS</a>, React Native, and AWS, the platform ensures scalability, security, and user satisfaction. Astroaakshar is now positioned as a global leader in offering astrology and lifestyle services, catering to users’ diverse needs with unparalleled efficiency.",
    sub_title_5: "",
    sub_txt_5: "",
    l5class_Name: "hidden !pt-[0px]",
    list_5: [],

    title_6: "",
    subtext_6: "",
    sub_title_6: "",
    sub_txt_6: "",
    l6class_Name: "hidden !pt-[0px]",
    list_6: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_7: "",
    subtext_7: "",
    sub_title_7: "",
    sub_txt_7: "",
    l7class_Name: "hidden !pt-[0px]",
    list_7: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_8: "",
    subtext_8: "",
    sub_title_8: "",
    sub_txt_8: "",
    l8class_Name: "hidden !pt-[0px]",
    list_8: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],
    class: "astro",
    img_2:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_02.png",
    img_3:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_03-logo.png",
    img_4:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_04.png",
    img_5:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_05.png",
    img_6:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_06.png",
    img_7:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_07.png",
    img_8:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_08.png",
    img_9:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Astro-Aakshar_09.png",

    count: [
      {
        id: "1",
        Count_Num: "100%",
        Count_Txt:
          "<b>Growth in User Engagement:</b> Increased interaction and repeat usage due to personalized offerings.",
      },
      {
        id: "2",
        Count_Num: "50%",
        Count_Txt:
          "<b>Rise in Product Sales:</b> In-app e-commerce boosted sales of certified astro products.",
      },
      {
        id: "3",
        Count_Num: "80%",
        Count_Txt:
          "<b>Retention Rate:</b> Improved usability and tailored experiences kept users engaged.",
      },
    ],
  },
  {
    id: "6",
    pathName: "/case-study/lone-the-look",
    inner_title: "Lone The Look",
    title: "Lone The Look - E-commerce Web Development",
    main_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Loan-the-Look_01.png",
    title_1: "Client Overview:",
    subtext_1:
      "<br /><b>Location: Montreal, Canada</b><br /><b>Industry: Fashion and eCommerce</b><br />Loan The Look is an innovative e-commerce platform that enables users to rent designer outfits for special occasions. Catering to a fashion-forward audience, the website offers convenience, style, and sustainability by connecting users with premium designer collections.",
    sub_title_1: "Project Goals:",
    sub_txt_1: "",
    l1class_Name: "!pt-[0px]",
    list_1: [
      {
        id: "1",
        contant:
          "Build an intuitive, scalable, and feature-rich <a href='/ecommerce-development-services' target='_blank' >e-commerce</a> platform from scratch.",
      },
      {
        id: "2",
        contant:
          "Enable seamless browsing and selection of outfits for specific rental dates.",
      },
      {
        id: "3",
        contant: "Integrate secure online payment and booking functionalities.",
      },
      {
        id: "4",
        contant:
          "Ensure an engaging and responsive design for a superior user experience.",
      },
    ],
    title_2: "Technologies Used:",
    subtext_2: "",
    sub_title_2: "",
    sub_txt_2: "",
    l5class_Name: "!pt-[0px]",
    list_2: [
      {
        id: "1",
        contant:
          "<a class='HUderLine' href='/react-js-development-company' target='_blank'>React.js</a>: For developing a fast, dynamic, and interactive front-end.",
      },
      {
        id: "2",
        contant:
          "Styled Components: To ensure modular and maintainable design architecture.",
      },
      {
        id: "3",
        contant:
          "Shopify Integration: Leveraged Shopify e-commerce development for robust e-commerce functionalities, including product management and secure transactions.",
      },
    ],
    proDetail: [
      {
        id: "1",
        title: "Technologies",
        content: "ReactJS, React Native, NodeJS, AWS, Stripe, MongoDB",
      },
      {
        id: "2",
        title: "Industry",
        content: "LIFESTYLE",
      },
      {
        id: "3",
        title: "Platform",
        content: "Mobile & Web",
      },
      {
        id: "4",
        title: "Team",
        content: "5 Member",
      },
      {
        id: "5",
        title: "Timeline",
        content: "1 year ",
      },
    ],
    title_3: "Key Features:",
    subtext_3: "",
    sub_title_3: "",
    sub_txt_3: "",
    l3class_Name: "!pt-[0px]",
    list_3: [
      {
        id: "1",
        contant:
          "<b>Outfit Selection by Date:</b> <br />Users can effortlessly browse designer collections and choose rental dates through an easy-to-use calendar integration.",
      },
      {
        id: "2",
        contant:
          "<b>Responsive and Engaging Design:</b> <br />Using React.js and styled components, we created a visually appealing and device-friendly interface tailored for fashion enthusiasts.",
      },
      {
        id: "3",
        contant:
          "<b>Online Payment Integration:</b> <br />Integrated secure payment gateways for hassle-free and safe transactions.",
      },
      {
        id: "4",
        contant:
          "<b>Customizable Rental Options: </b> <br />Dynamic pricing and rental durations ensure flexibility for users.",
      },
      {
        id: "5",
        contant:
          "<b>Seamless User Journey:</b> Intuitive navigation and search filters allow users to find outfits by designer, occasion, or availability.",
      },
    ],

    before_img: "",

    title_4: "Challenges & Solutions:",
    subtext_4: "",
    sub_title_4: "",
    sub_txt_4: "",
    l4class_Name: " !pt-[0px]",
    list_4: [
      {
        id: "1",
        contant:
          "<b>Challenge:</b> Implementing date-specific inventory management. <br><b>Solution:</b> Developed a robust system that dynamically updates outfit availability based on selected dates.",
      },
      {
        id: "2",
        contant:
          "<b>Challenge:</b> Ensuring a visually appealing design that reflects the brand’s elegance. <br /><b>Solution:</b> Styled components provided flexibility and modularity for consistent, high-quality design across the site.",
      },
    ],
    Col_2img: [],
    Col_2img1: [],
    card_img: [
      // {
      //   id: "1",
      //   c_img:
      //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub03.webp",
      // },
    ],

    title_5: "Result:",
    subtext_5:
      "Loan The Look launched as a fully functional, user-centric e-commerce platform, gaining quick traction among Montreal’s fashion-conscious audience. The seamless rental experience and cutting-edge technology have positioned the brand as a leader in sustainable fashion rentals.",
    sub_title_5: "",
    sub_txt_5: "",
    l5class_Name: "hidden !pt-[0px]",
    list_5: [],

    title_6: "",
    subtext_6: "",
    sub_title_6: "",
    sub_txt_6: "",
    l6class_Name: "hidden !pt-[0px]",
    list_6: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_7: "",
    subtext_7: "",
    sub_title_7: "",
    sub_txt_7: "",
    l7class_Name: "hidden !pt-[0px]",
    list_7: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    title_8: "",
    subtext_8: "",
    sub_title_8: "",
    sub_txt_8: "",
    l8class_Name: "hidden !pt-[0px]",
    list_8: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],
    class: "LoanTheLook",
    img_2:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Loan-the-Look_02.png",
    img_3:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Loan-the-Look_03-logo.png",
    img_4:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Loan-the-Look_04.png",
    img_5:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Loan-the-Look_05.png",
    img_6:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Loan-the-Look_06.png",
    img_7:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Loan-the-Look_07.png",
    img_8:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Loan-the-Look_08.png",
    img_9:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Loan-the-Look_010.png",
    count: [
      {
        id: "1",
        Count_Num: "100%",
        Count_Txt:
          "<b>Growth in User Engagement:</b> Increased interaction and repeat usage due to personalized offerings.",
      },
      {
        id: "2",
        Count_Num: "50%",
        Count_Txt:
          "<b>Rise in Product Sales:</b> In-app e-commerce boosted sales of products.",
      },
      {
        id: "3",
        Count_Num: "80%",
        Count_Txt:
          "<b>Retention Rate:</b> Improved usability and tailored experiences kept users engaged.",
      },
    ],
  },
  {
    id: "9",
    pathName: "/case-study/vechit",
    inner_title: "vechit",
    title:
      "Redefining Sustainability: The Marketplace for Traditional and Pre-Loved Fashion​",
    main_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_01.png",
    title_1: "Services Provided",
    subtext_1: "",
    sub_title_1: "",
    sub_txt_1: "",
    l1class_Name: "!pt-[0px]",
    list_1: [
      {
        id: "1",
        contant: "Mobile Application Development",
      },
      {
        id: "2",
        contant: "Cross-Platform App Development",
      },
      {
        id: "3",
        contant: "Sustainable Marketplace Solutions",
      },
      {
        id: "4",
        contant: "User-Centric App Design and Development",
      },
    ],
    proDetail: [
      {
        id: "1",
        title: "Technologies",
        content:
          "React Native, ReactJS, NodeJS, MangoPay, Ship Engine, AWS, Firebase",
      },
      {
        id: "2",
        title: "Industry",
        content: "RETAIL",
      },
      {
        id: "3",
        title: "Platform",
        content: "Mobile & Web",
      },
      {
        id: "4",
        title: "Team",
        content: "6 Member",
      },
      {
        id: "5",
        title: "Timeline",
        content: "2 year ",
      },
    ],
    title_2: "Bussiness Challenges",
    subtext_2:
      "Vechit was created to address a unique problem faced by individuals and families across the UK: what to do with clothes that were worn once or twice but no longer needed. This challenge was particularly acute for traditional outfits, which are often bought for specific events but are rarely reused.",
    sub_title_2: "",
    sub_txt_2: "",
    l2class_Name: "!pt-[0px]",
    list_2: [
      {
        id: "1",
        contant:
          "<b>Lack of Dedicated Platforms for Traditional Outfits</b> <br />Most reselling platforms cater to general fashion but lack a focus on traditional wear. Vechit identified this niche but valuable market gap, especially for users looking for eco-friendly options.",
      },
      {
        id: "2",
        contant:
          "<b>Difficulty in Finding Buyers for Barely Worn Clothing</b> <br />Traditional outfits are expensive, yet they are often used only for one-time occasions like weddings or festivals. Finding buyers for these items had always been a major challenge.",
      },
      {
        id: "3",
        contant:
          "<b>Need for an Intuitive Bargaining System</b> <br />Unlike fixed-price marketplaces, selling pre-loved items often involves negotiation. Vechit needed a custom solution for users to make offers and bargain in real-time while maintaining ease of use.",
      },
      {
        id: "4",
        contant:
          "<b>Ensuring Seamless Cross-Platform Accessibility</b> <br />In today’s mobile-first world, reaching users on both Android and iOS devices is critical. Building a robust yet uniform experience across platforms posed a technical challenge.",
      },
      {
        id: "5",
        contant:
          "<b>Aligning with the Sustainability Movement</b> <br />Incorporating features and messaging that highlighted the eco-friendly benefits of reselling clothes was essential to attract users interested in sustainable fashion.",
      },
    ],
    before_img: "",

    title_3: "Solution",
    subtext_3:
      "To overcome the challenges and deliver an innovative, sustainable marketplace app, Vechit and its development team adopted a multi-faceted approach:",
    sub_title_3: "",
    sub_txt_3: "",
    l3class_Name: "!pt-[0px]",
    list_3: [
      {
        id: "1",
        contant:
          "<b>Focused Marketplace for Traditional and Hardly Worn Clothes</b> <br />Vechit became a niche platform catering exclusively to users looking to buy or sell traditional and pre-loved clothes. The user experience was designed to make listing and finding such items quick and easy.",
      },
      {
        id: "2",
        contant:
          "<b>Bargaining and Offer Mechanism</b> <br />A built-in feature allowed buyers and sellers to negotiate prices interactively. This system was seamlessly integrated into the app's workflow, providing users with flexibility while maintaining security.",
      },
      {
        id: "3",
        contant:
          "<b>Streamlined Listing Process</b> <br />The app offered an intuitive process for users to upload items, including fields for item descriptions, photos, and pricing. This simplified the reselling process, making it accessible to users of all ages and tech proficiency levels.",
      },
      {
        id: "4",
        contant:
          "<b>Cross-Platform Development with React Native</b> <br />React Native was chosen as the development framework, enabling a single codebase for both Android and iOS apps. This approach reduced development costs and ensured feature parity across platforms.",
      },
      {
        id: "5",
        contant:
          "<b>Sustainability-Centric Messaging and Features</b> <br />Eco-conscious messaging was embedded throughout the app, emphasizing the benefits of reducing waste. Additionally, Vechit encouraged users to donate or recycle items that couldn't be sold.",
      },
    ],
    Col_2img: [],
    Col_2img1: [],
    card_img: [
      // {
      //   id: "1",
      //   c_img:
      //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub03.webp",
      // },
    ],

    title_4: "Technology Used",
    subtext_4:
      "The app leveraged a combination of advanced technologies to ensure seamless performance, scalability, and user satisfaction:",
    sub_title_4: "",
    sub_txt_4: "",
    l4class_Name: "!pt-[0px]",
    list_4: [
      {
        id: "1",
        contant:
          "<b>React Native:</b> Enabled efficient cross-platform app development with a single codebase for Android and iOS.",
      },
      {
        id: "2",
        contant:
          "<b>ReactJS:</b> Provided a responsive and visually engaging frontend for user interaction.",
      },
      {
        id: "3",
        contant:
          "<b>NodeJS:</b> Ensured a scalable backend for managing large volumes of user activity, including listings and transactions.",
      },
      {
        id: "4",
        contant:
          "<b>MangoPay:</b> Integrated for secure online payments, supporting instant payouts and streamlined payment workflows.",
      },
      {
        id: "5",
        contant:
          "<b>Ship Engine:</b> Facilitated product delivery, order tracking, and process automation for efficient logistics.",
      },
      {
        id: "6",
        contant:
          "<b>AWS (Amazon Web Services):</b> Hosted the application with scalable cloud infrastructure to support growing user demands.",
      },
      {
        id: "7",
        contant:
          "<b>Firebase:</b> Used for real-time database operations and secure user authentication.",
      },
    ],

    title_5: "Detailed Insights",
    subtext_5: "",
    sub_title_5: "",
    sub_txt_5: "",
    l5class_Name: "!pt-[0px]",
    list_5: [
      {
        id: "1",
        contant:
          "<b>Understanding the Market for Traditional Outfits</b> <br />Traditional clothing is often reserved for special occasions like weddings, religious ceremonies, or cultural events. Such outfits are not part of everyday wardrobes and are rarely reused. Vechit identified this challenge and created a platform that allowed users to declutter while also providing buyers with affordable, high-quality traditional attire. ",
      },
      {
        id: "2",
        contant:
          "<b>Encouraging Community-Driven Sustainability</b> <br /> Vachit’s approach wasn’t just about selling clothes; it was about promoting a circular economy. The app allowed sellers to share the stories behind their items, creating a deeper connection between buyers and sellers.",
      },
      {
        id: "3",
        contant:
          "<b>Empowering Sellers with Seamless Features</b> <br /> Sellers could easily upload items, track offers, and communicate with buyers, all through the app. Features like suggested pricing and data-driven insights further empowered users to make informed decisions.",
      },
      {
        id: "4",
        contant:
          "<b>Ensuring Buyer Satisfaction</b> <br /> For buyers, the app provided a curated experience, allowing them to filter searches by category, size, and price. The bargaining feature was a game-changer, offering buyers the flexibility to negotiate deals in real-time.",
      },
      {
        id: "5",
        contant:
          "<b>Reducing Environmental Impact</b> <br /> By encouraging the reuse of clothes, Vechit significantly reduced the environmental footprint associated with fast fashion. The platform also educated users on the environmental benefits of buying secondhand.",
      },
    ],

    title_6: "Conclusion/Impact",
    subtext_6:
      "Vechit has revolutionized the way people view secondhand clothing, particularly traditional outfits. By offering a dedicated platform, seamless features, and a focus on sustainability, the app has empowered users to declutter responsibly while promoting eco-friendly practices. As a result, Vechit has established itself as a leading marketplace in the UK for pre-loved fashion, driving both economic and environmental impact.",
    sub_title_6: "",
    sub_txt_6: "",
    l6class_Name: "hidden !pt-[0px]",
    list_6: [],

    title_7: "",
    subtext_7: "",
    sub_title_7: "",
    sub_txt_7: "",
    l7class_Name: "hidden !pt-[0px]",
    list_7: [],

    title_8: "",
    subtext_8: "",
    sub_title_8: "",
    sub_txt_8: "",
    l8class_Name: "hidden !pt-[0px]",
    list_8: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    img_2:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_02.png",
    img_3:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_03-logo.png",
    class: "vechitapp",
    img_4:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_04.png",
    img_5:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_05.png",
    img_6:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_06.png",
    img_7:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_07.png",
    img_8:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_08.png",
    img_9:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/vechit_09.png",
    count: [
      {
        id: "1",
        Count_Num: "11.6%",
        Count_Txt: "Decreased loading time across all pre-cached pages.",
      },
      {
        id: "2",
        Count_Num: "30%+",
        Count_Txt: "Increase in conversion rates.",
      },
      {
        id: "3",
        Count_Num: "2.94 Seconds",
        Count_Txt: "To load PWA and enables checkout within 20 seconds.",
      },
    ],
  },
  {
    id: "10",
    pathName: "/case-study/kutti-lulu",
    inner_title: "Kutti Lulu",
    title: "Retail Apparel and Fashion​",
    main_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kutti-lulu_01.png",
    title_1: "Client Overview",
    subtext_1:
      "<a class='HUderLine'  href='https://www.kuttilulu.com/' target='_blank'>Kutti Lulu</a> is an award-winning children's loungewear brand based in London, England, founded in 2022. The brand champions individuality, self-expression, and the wild spirit of childhood. Specializing in gender-free, organic cotton loungewear, Kutti Lulu offers vibrant, nature-inspired prints that reflect the unique personalities of the children they dress. The brand also supports women-owned and family-run businesses, creating handcrafted, sustainable clothing in small batches. With a commitment to slow fashion, eco-friendly packaging, and charitable causes, Kutti Lulu aims to make a positive impact on both the environment and the future of children in need.",
    sub_title_1: "",
    sub_txt_1: "",
    l1class_Name: "hidden !pt-[0px]",
    list_1: [],
    proDetail: [
      {
        id: "1",
        title: "Technologies",
        content: "Shopify Development",
      },
      {
        id: "2",
        title: "Industry",
        content: "E-commerce",
      },
      {
        id: "3",
        title: "Platform",
        content: "Mobile & Web",
      },
      {
        id: "4",
        title: "Team",
        content: "4 Member",
      },
      {
        id: "5",
        title: "Timeline",
        content: "6 months ",
      },
    ],
    title_2: "Bussiness Challenges",
    subtext_2:
      "Kutti Lulu needed a robust, scalable, and user-friendly online platform to showcase their unique products and reach a wider audience. The existing e-commerce solution lacked the flexibility to support their expanding product range and values-driven brand. They required an e-commerce website that could effectively handle their retail operations while integrating features such as secure payment gateways, an easy-to-use inventory management system, and seamless user navigation.",
    sub_title_2: "",
    sub_txt_2: "",
    l2class_Name: "hidden !pt-[0px]",
    list_2: [],
    before_img: "",

    title_3: "Our Approach",
    subtext_3:
      "As a leading provider of <a class='HUderLine' href='/shopify-development-company' target='_blank' >Shopify Development</a> and <b>Shopify E-commerce Development</b> services, <a class='HUderLine' href='/' target='_blank' >IT Services India</a> worked closely with Kutti Lulu to create a custom Shopify store tailored to their specific needs. Our goal was to build an intuitive, responsive, and visually appealing website that would enhance the shopping experience for customers while supporting Kutti Lulu’s sustainable and ethical business practices.",
    sub_title_3: "",
    sub_txt_3: "",
    l3class_Name: "hidden !pt-[0px]",
    list_3: [],
    Col_2img: [],
    Col_2img1: [],
    card_img: [
      // {
      //   id: "1",
      //   c_img:
      //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub03.webp",
      // },
    ],

    title_4: "Solution: Shopify E-commerce Development",
    subtext_4: "",
    sub_title_4: "",
    sub_txt_4: "",
    l4class_Name: "!pt-[0px]",
    list_4: [
      {
        id: "1",
        contant:
          "<b>Shopify E-commerce Platform:</b> We utilized the <b>Shopify e-commerce development</b> platform to build a seamless, user-friendly store. Shopify’s robust features allowed us to integrate secure payment gateways, advanced inventory management, and real-time tracking, ensuring a smooth and efficient shopping experience for customers.",
      },
      {
        id: "2",
        contant:
          "<b>Custom Web Design:</b> To reflect Kutti Lulu’s brand ethos and vibrant designs, we developed a custom theme using Shopify's capabilities. The website is visually stunning, with nature-inspired elements and intuitive navigation that highlights the brand’s unique products and values.",
      },
      {
        id: "3",
        contant:
          "<b>Mobile Optimization:</b> With a growing number of customers shopping on mobile devices, we ensured that the website is fully optimized for mobile use. This provides an excellent user experience on all devices, enhancing customer satisfaction and increasing conversion rates.",
      },
      {
        id: "4",
        contant:
          "<b>Sustainable & Eco-friendly Features:</b> Given Kutti Lulu’s commitment to sustainability, we integrated eco-friendly messaging throughout the website, highlighting their eco-conscious materials, small batch production, and sustainable packaging options.",
      },
      {
        id: "5",
        contant:
          "<b>E-commerce Features:</b> We added features like easy product browsing, wishlists, and user reviews, enabling customers to shop with ease and confidence. We also integrated a donation feature, allowing customers to contribute to charities supporting children in need.",
      },
      {
        id: "6",
        contant:
          "<b>Breadcrumb URL:</b> To improve website navigation and SEO, we integrated a customized <b>breadcrumb URL</b> structure. This allows users to easily track their navigation path on the site, enhancing the user experience and ensuring smooth transitions between product categories, leading to a higher user retention rate.",
      },
      {
        id: "7",
        contant:
          "<b>Animated Footer:</b> To add an engaging visual element to the website, we implemented an <b>animated footer</b> that not only enhances the overall design but also dynamically displays key information, such as links to customer service, social media icons, and other important sections, making the footer both functional and visually appealing.",
      },
    ],

    title_5: "Technologies Used",
    subtext_5: "",
    sub_title_5: "",
    sub_txt_5: "",
    l5class_Name: "!pt-[0px]",
    list_5: [
      {
        id: "1",
        contant: "<b>Shopify Development</b>",
      },
      {
        id: "2",
        contant:
          "<b><a class='HUderLine' href='/shopify-development-company' target='_blank'>Shopify E-commerce Development</a></b>",
      },
      {
        id: "3",
        contant: "<b>Custom Theme Design & Development</b>",
      },
      {
        id: "4",
        contant: "<b>Responsive Web Development for Retail Industry</b>",
      },
      {
        id: "5",
        contant: "<b>Payment Gateway Integration</b>",
      },
      {
        id: "6",
        contant: "<b>Inventory Management Integration</b>",
      },
    ],

    title_6: "Results",
    subtext_6: "",
    sub_title_6: "",
    sub_txt_6: "",
    l6class_Name: "!pt-[0px]",
    list_6: [
      {
        id: "1",
        contant:
          "<b>Increased Sales:</b> The new Shopify store led to a significant boost in online sales, thanks to a streamlined, intuitive shopping experience.",
      },
      {
        id: "2",
        contant:
          "<b>Enhanced Brand Visibility:</b> With a modern, responsive design, the website now effectively showcases Kutti Lulu’s unique brand and product offerings.",
      },
      {
        id: "3",
        contant:
          "<b>Improved Customer Engagement:</b> Features like wishlists, reviews, and donation integration helped increase customer engagement, building brand loyalty and trust.",
      },
      {
        id: "4",
        contant:
          "<b>Scalable Platform:</b> The Shopify platform enables Kutti Lulu to scale their online store as their product range grows and its customer base expands.",
      },
    ],

    title_7: "Client Testimonial",
    subtext_7:
      "“<a class='HUderLine' href='/' target='_blank'>IT Services India</a> helped bring our vision to life with a beautifully designed, functional <a class='HUderLine' href='/shopify-development-company' target='_blank'>e-commerce store on Shopify</a>. Their expertise in <b>Shopify E-commerce Development</b> enabled us to create an online shopping experience that aligns perfectly with our brand's values and goals. We’re thrilled with the results and excited to see our online presence grow!”",
    sub_title_7: "",
    sub_txt_7: "",
    l7class_Name: "hidden !pt-[0px]",
    list_7: [],

    title_8: "Conclusion",
    subtext_8:
      "With IT Services India’s expertise in <a class='HUderLine' href='/ecommerce-development-services' target='_blank'>e-commerce web development</a> and <b>Shopify development</b>, Kutti Lulu now has a dynamic, scalable online store that aligns with their values and supports their mission. The new website has enhanced their online sales, improved customer satisfaction, and provided a platform for future growth. As a result, Kutti Lulu is well-positioned to continue expanding its brand and impact in the retail apparel industry.",
    sub_title_8: "",
    sub_txt_8: "",
    l8class_Name: "hidden !pt-[0px]",
    list_8: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    img_2:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kutti-lulu_02.png",
    img_3:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kutti-lulu_03_logo.png",
    class: "vechitapp",
    img_4:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kutti-lulu_04.png",
    img_5:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kutti-lulu_05.png",
    img_6:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kutti-lulu_06.png",
    img_7:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kutti-lulu_07.png",
    img_8:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Kutti-lulu_08.png",
    count: [
      {
        id: "1",
        Count_Num: "11.6%",
        Count_Txt: "Decreased loading time across all pre-cached pages.",
      },
      {
        id: "2",
        Count_Num: "30%+",
        Count_Txt: "Increase in conversion rates.",
      },
      {
        id: "3",
        Count_Num: "2.94 Seconds",
        Count_Txt: "To load PWA and enables checkout within 20 seconds.",
      },
    ],
  },
  {
    id: "11",
    pathName: "/case-study/afyajobs",
    inner_title: "Afyajobs",
    title: "Transforming Healthcare Job Search with Afyajobs​",
    main_img:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/afyasolutions_01.png",
    title_1: "Services Provided",
    subtext_1: "",
    sub_title_1: "",
    sub_txt_1: "",
    l1class_Name: "!pt-[0px]",
    list_1: [
      {
        id: "1",
        contant: "Job Board Development",
      },
      {
        id: "2",
        contant: "Cloud-Based Job Search Engine",
      },
      {
        id: "3",
        contant: "Employer & Candidate Dashboard",
      },
      {
        id: "4",
        contant: "Video Conferencing for Interviews",
      },
    ],
    proDetail: [
      {
        id: "1",
        title: "Technologies",
        content: "ReactJS, NodeJS, ExpressJS",
      },
      {
        id: "2",
        title: "Industry",
        content: "Healthcare Recruitment / Job Search",
      },
      {
        id: "3",
        title: "Platform",
        content: "Job Board & Employment",
      },
      {
        id: "4",
        title: "Team",
        content: "6 Member",
      },
      {
        id: "5",
        title: "Timeline",
        content: "2 year ",
      },
    ],
    title_2: "Bussiness Challenges",
    subtext_2: "",
    sub_title_2: "",
    sub_txt_2: "",
    l2class_Name: "!pt-[0px]",
    list_2: [
      {
        id: "1",
        contant:
          "<b>Scalability & Performance</b> <br />Afyajobs needed a scalable architecture to accommodate thousands of simultaneous job postings, user interactions, and video interviews. With an increasing number of job seekers and recruiters using the platform, ensuring seamless performance without slowdowns or crashes was a top priority.",
      },
      {
        id: "2",
        contant:
          "<b>Efficient Job Matching</b> <br />A major challenge was connecting job seekers with relevant opportunities efficiently. Generic job boards often struggle with irrelevant job recommendations and inefficient filtering. Afyajobs required an intelligent algorithm that could analyze candidate profiles and job descriptions in real-time, ensuring precise recommendations.",
      },
      {
        id: "3",
        contant:
          "<b>Seamless User Experience</b> <br />For both job seekers and recruiters, a frictionless user journey was necessary. Many job platforms suffer from cluttered interfaces and confusing navigation, leading to drop-offs. Afyajobs aimed to create an engaging and easy-to-use interface that would make job searches, applications, and hiring effortless.",
      },
      {
        id: "4",
        contant:
          "<b>Secure Data Management</b> <br />Given the sensitive nature of candidate resumes, employer details, and job data, security was a significant concern. Ensuring data encryption, secure login authentication, and safe storage mechanisms was crucial to maintain user trust.",
      },
      {
        id: "5",
        contant:
          "<b>Integrated Video Conferencing</b> <br />Since many healthcare jobs required remote interviews, Afyajobs needed a built-in video conferencing solution. The challenge was to embed a reliable, high-quality video system without relying on external tools, providing a seamless interview experience for recruiters and candidates.",
      },
    ],
    before_img: "",

    title_3: "Solution",
    subtext_3: "",
    sub_title_3: "",
    sub_txt_3: "",
    l3class_Name: "!pt-[0px]",
    list_3: [
      {
        id: "1",
        contant:
          "<b>Cloud-Based Infrastructure for Scalability</b> <br />To address performance concerns, Afyajobs was built on a cloud-based architecture. This allowed the platform to handle high traffic loads while ensuring a smooth experience for both job seekers and recruiters. The system used auto-scaling mechanisms, ensuring fast response times even during peak hours.",
      },
      {
        id: "2",
        contant:
          "<b>AI-Driven Matching Algorithm</b> <br />Afyajobs implemented an AI-powered recommendation engine that analyzed: <ul class=mt-3><li>Candidate resumes and experience</li> <li>Job descriptions and requirements</li>  <li>Recruiter preferences</li> </ul>  The matching algorithm provided personalized job suggestions to users, reducing manual job searches and increasing successful placements.",
      },
      {
        id: "3",
        contant:
          "<b>Enhanced <a class='text-hover' href='/ui-ux-design-services' target='_blank'>UI/UX</a> for Intuitive Navigation</b> <br />The job portal was designed with <a class='HUderLine' href='/react-js-development-company' target='_blank'>React.js</a> to create a visually appealing, responsive, and user-friendly interface. Features like:  <ul class=mt-3><li>Advanced job filters</li> <li>One-click applications</li>  <li>Personalized dashboards for job seekers and recruiters</li> </ul>  made navigation smooth and efficient, improving user engagement. ",
      },
      {
        id: "4",
        contant:
          "<b>Advanced Security & Authentication</b> <br />To protect sensitive user data, the platform integrated secure login authentication using:  <ul class=mt-3><li>OAuth-based social sign-ins (<a class='HUderLine' href='https://www.google.com/' target='_blank'>Google</a>, <a class='HUderLine' href='https://www.facebook.com' target='_blank'>Facebook</a>, <a class='HUderLine' href='https://www.linkedin.com/' target='_blank'>LinkedIn</a>)</li> <li>End-to-end encrypted databases</li>  <li>Automated fraud detection</li> </ul>  This ensured data privacy and prevented unauthorized access.",
      },
      {
        id: "5",
        contant:
          "<b>Built-in Video Conferencing for Interviews</b> <br />Afyajobs integrated a real-time video interview system, eliminating the need for external video platforms. This allowed:  <ul class=mt-3><li>Recruiters to schedule and conduct interviews</li> <li>Job seekers to connect instantly with hiring managers</li>  <li>Smooth, high-quality video and audio interactions</li> </ul>",
      },
    ],
    Col_2img: [],
    Col_2img1: [],
    card_img: [
      // {
      //   id: "1",
      //   c_img:
      //     "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/SkyHub03.webp",
      // },
    ],

    title_4: "Technology Used",
    subtext_4:
      "The app leveraged a combination of advanced technologies to ensure seamless performance, scalability, and user satisfaction:",
    sub_title_4: "",
    sub_txt_4: "",
    l4class_Name: "!pt-[0px]",
    list_4: [
      {
        id: "1",
        contant:
          "<ul><b><a class='text-hover' href='/front-end-development' target='_blank'>Frontend Development</a></b/> <br/> <ul><li> <b><a class='HUderLine' href='/react-js-development-company' target='_blank'>React.js</a>:</b> Created a dynamic, interactive, and responsive user interface</li></ul>  <ul><li> <b>Styled Components:</b> Ensured modular, maintainable, and scalable UI</li></ul>",
      },
      {
        id: "2",
        contant:
          "<ul><b><a class='text-hover' href='/backend-development-services' target='_blank'>Backend Development</a></b/> <br/> <ul><li> <b><a class='HUderLine' href='/node-js-development-company' target='_blank'>Node.js<a/>:</b> Provided robust and high-performance backend support</li></ul>  <ul><li> <b><a class='HUderLine' href='/express-js-development-company' target='_blank'>Express.js</a>:</b> Managed API requests efficiently</li></ul>",
      },
      {
        id: "3",
        contant:
          "<ul><b>Database & Security</b/> <br/> <ul><li> <b>Cloud-Based Storage:</b> Allowed fast data retrieval and secure access</li></ul>  <ul><li> <b>Encrypted Database:</b> Ensured secure storage of job postings, resumes, and personal data</li></ul>",
      },
      {
        id: "4",
        contant:
          "<ul><b>Authentication & Access Control</b/> <br/> <ul><li> <b>OAuth Authentication:</b> Enabled sign-in via Facebook, Google, and LinkedIn</li></ul>  <ul><li> <b>Role-Based Access Control (RBAC):</b> Restricted access based on user roles (job seekers, employers, admins)</li></ul>",
      },
      {
        id: "5",
        contant:
          "<ul><b>AI & Automation</b/> <br/> <ul><li> <b>Smart Job Matching Algorithm:</b> Matched candidates with the best job opportunities</li></ul>  <ul><li> <b>Automated Job Alerts:</b> Notified users of relevant job openings in real-time</li></ul>",
      },
      {
        id: "6",
        contant:
          "<ul><b>Additional Features</b/> <br/> <ul><li> <b>Integrated Resume Database:</b> Allowed employers to browse candidate profiles</li></ul>  <ul><li> <b>Real-Time Notifications:</b> Alerted users about interview schedules and application updates</li></ul>",
      },
    ],

    title_5: "",
    subtext_5: "",
    sub_title_5: "",
    sub_txt_5: "",
    l5class_Name: " !pt-[0px]",
    list_5: [],

    title_6: "Conclusion/Impact",
    subtext_6:
      "Afyajobs has revolutionized healthcare job recruitment by providing a feature-rich, scalable, and secure platform for both job seekers and employers. With a powerful AI-driven job matching system, seamless video interviews, and a user-friendly experience, Afyajobs has significantly reduced hiring time, improved employer engagement, and increased candidate registrations. The cloud-based, secure, and efficient architecture ensures long-term scalability, making it a leading job search engine in the healthcare industry.",
    sub_title_6: "",
    sub_txt_6: "",
    l6class_Name: "hidden !pt-[0px]",
    list_6: [],

    title_7: "",
    subtext_7: "",
    sub_title_7: "",
    sub_txt_7: "",
    l7class_Name: "hidden !pt-[0px]",
    list_7: [],

    title_8: "",
    subtext_8: "",
    sub_title_8: "",
    sub_txt_8: "",
    l8class_Name: "hidden !pt-[0px]",
    list_8: [
      // {
      //   id: "1",
      //   contant:
      //     "Real-time CREA and TREB updates every 15 minutes have led to a <b>15% increase in user engagement</b> by ensuring accurate, up-to-date listings. ",
      // },
    ],

    img_2:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/afyasolutions_02.png",
    img_3:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/afyasolutions_03.png",
    class: "vechitapp",
    img_4:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/afyasolutions_04.png",
    img_5:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/afyasolutions_05.png",
    img_6:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/afyasolutions_06.png",
    img_7:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/afyasolutions_07.png",
    img_8:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/afyasolutions_08.png",
    img_9:
      "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/afyasolutions_09.png",
    count: [
      {
        id: "1",
        Count_Num: "40%",
        Count_Txt: "Faster Job Placements",
      },
      {
        id: "2",
        Count_Num: "30%",
        Count_Txt: " Increased Employer Engagement",
      },
      {
        id: "3",
        Count_Num: "50%",
        Count_Txt: "Growth in Candidate Sign-ups",
      },
    ],
  },
];

export const StartUpFullServiceMapData = [
  {
    Id: "1",
    Title: "Let’s start building your startup",
    Text: "Try working with us for three days at no cost.",
    btn: "BOOK A FREE TRIAL",
    Icon: "charm:arrow-up-right",
  },
];
export const CoreServicesPopUp = [
  {
    id: "1",
    title: "",
    content:
      "Our web application development solutions enhance user experience and align with your business strategies for long-term success.",
    link: "/web-application-development-company",
  },
  {
    id: "2",
    title: "Mobile Application Development",
    content:
      "Our mobile application development services create secure, user-friendly apps that align with your business goals.",
    link: "/mobile-app-development-company",
  },
  {
    id: "3",
    title: "Software Development Services",
    content:
      "Our software development services deliver custom solutions that align with your business goals, ensuring efficiency and growth.",
    link: "/software-development",
  },
  {
    id: "4",
    title: "Website Development Services",
    content:
      "Our website development services create secure, tailored solutions that align with your business goals for lasting success.",
    link: "/website-design-and-development",
  },
  {
    id: "5",
    title: "Front-end Development Services",
    content:
      "Our front-end development services create intuitive, engaging user interfaces that align with your business goals for optimal user experience.",
    link: "/front-end-development",
  },
  {
    id: "6",
    title: "Back-end Development Services",
    content:
      "Our backend development services deliver robust, scalable solutions that align with your business objectives effectively.",
    link: "/backend-development-services",
  },
  {
    id: "7",
    title: "E-Commerce Store Development",
    content:
      "Our eCommerce store development services create secure, customized solutions that drive sales and enhance user experience.",
    link: "/ecommerce-development-services",
  },
  {
    id: "8",
    title: "UI/UX Development Services",
    content:
      "Our UI/UX development services create engaging, user-centered designs that enhance usability and align with your business goals.",
    link: "/ui-ux-design-services",
  },
  {
    id: "9",
    title: "Hire a Developer",
    content:
      "Our developer hiring services connect you with skilled professionals who align with your project needs and goals.",
    link: "/",
  },
];

export const ScrollToTabsArray = [
  {
    id: "1",
    title: "Analysis",
    sub_title:
      "We thoroughly research project needs to deliver tailored front-end solutions.",
    review_section: [
      {
        id: "1",
        content:
          "IT Services lndia has helped us develop our custom software system. Before we signed the contract, we had initial pre-sales meetings, during which I provided them with documentation. We also signed an NDA so that they could look at everything I provided. Then, Micame back with an in-depth project proposal of about 50 pages. ",
        img: "https://media.designrush.com/agency_reviews/758559/conversions/1735945296077-user-avatar.jpg",
        uname: "Graham Edward",
        position: "Managing Director,  Holbeck Viaduct",
        bg: "#1d1e22",
      },
    ],
  },
  {
    id: "2",
    title: "Resource Collection",
    sub_title:
      "Efficiently collect frameworks, libraries, and assets to streamline development.",
    review_section: [
      {
        id: "1",
        content:
          "IT Services India delivered a high-quality app that improved the client's operational efficiency, user engagement, and customer satisfaction. The team was flexible, adaptive, and customer-centric. Moreover, IT Services India's project management and communication were excellent.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/andrzej-ciesielski.webp",
        uname: "Andrzej Ciesielski",
        position: "Owner, Furgonetka Sp. z o. o. Sp. k.",
        bg: "#FCFCFC",
        color: "000000",
      },
    ],
  },
  {
    id: "3",
    title: "UI Component Development",
    sub_title:
      "Develop dynamic and responsive components for a seamless user experience.",
    review_section: [
      {
        id: "1",
        content:
          "I wasn't sure which technology to choose, IT Services lndia recommended me the right tech stack and asked me to pick. I picked MERN Stack and I believe they did the right thing.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Jacob-Webb-user-avatar.jpg",
        uname: "Jacob Webb",
        position: "Founder , Starlight",
      },
    ],
  },
  {
    id: "4",
    title: "Documentation",
    sub_title:
      "Maintain comprehensive documentation to support scalability and future updates.",
    review_section: [
      {
        id: "1",
        content:
          "IT Services lndia was tasked with taking a concept for a mobile dating app, designing it, developing it, and testing it. The concept evolved over time into a very robust, full-featured dating app. In addition, I asked them to act as product managers, developing,,,,",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Dennis-Hefter-user-avatar.jpg",
        uname: "Dennis Hefter",
        position: "Founder, TruuBlue LLC , TruuBlue",
        bg: "#FCFCFC",
        color: "000000",
      },
    ],
  },
  {
    id: "5",
    title: "Continuous Maintenance",
    sub_title:
      "Ensure consistent performance with regular updates and technical support.",
    review_section: [
      {
        id: "1",
        content:
          "We recently had the pleasure of working with IT Services India to create our new website, and we couldn't be happier with the results! They delivered exactly what we envisioned and were incredibly patient throughout the entire process.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/Morgan-Gregory-user-avatar.jpg",
        uname: "Morgan Gregory",
        position: "CEO & Co-founder at Alltold",
        bg: "#FCFCFC",
        color: "000000",
      },
    ],
  },
];

export const ScrollButtonsTabs = [
  "Analysis",
  "Resource Collection",
  "UI Component ",
  "Documentation",
  "Continuous",
];

export const MaintenanceScrollButtonsTabs = [
  "Requirement Analysis",
  "System Assessment",
  "System Diagnostics",
  "Custom Maintenance",
  "Stability Assessment",
  "Feedback & Reporting",
];
export const MaintenanceScrollToTabsArray = [
  {
    id: "1",
    title: "Requirement Analysis",
    sub_title:
      "Collaborating closely with your team to understand your needs, ensuring all project requirements. ",
    review_section: [
      {
        id: "1",
        content:
          "IT Services India developed a real-time inventory tracking system for an office equipment supplier. The team integrated the system with third-party tools to streamline their shipping and fulfillment processes.",
        img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/auth-img-1.webp",
        uname: "Barry Fish",
        position: "Managing Director, A2B Office Supplies",
        bg: "#1d1e22",
      },
    ],
  },
  {
    id: "2",
    title: "System Assessment",
    sub_title:
      "Conducting a thorough analysis of your existing programs to identify areas for improvement. ",
    review_section: [],
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/img-design-system-04-p-02.webp",
  },
  {
    id: "3",
    title: "System Diagnostics",
    sub_title:
      "Developing a specialized maintenance proposal that addresses your unique challenges.",
    review_section: [],
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/img-design-system-04-p-03.webp",
  },
  {
    id: "4",
    title: "Custom Maintenance",
    sub_title:
      "Regularly evaluating your system's reliability to proactively address potential issues.",
    review_section: [],
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/img-design-system-04-p-04.webp",
  },
  {
    id: "5",
    title: "Stability Assessment",
    sub_title:
      "Performing comprehensive testing to verify changes, followed by seamless deployment updates. ",
    review_section: [],
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/img-design-system-04-p-05.webp",
  },

  {
    id: "6",
    title: "Feedback & Reporting",
    sub_title:
      "Providing detailed reports on changes, updates, and system performance to keep you informed.",
    review_section: [],
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/img-design-system-04-p-03.webp",
  },
];

export const ImageGridFirst = [
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/holi-2025-04.webp",
    alttxt: "about us Holi",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/girls-2024-scaled.webp",
    alttxt: "about us Group photo of Team",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/IMG_8609.webp",
    alttxt: "about us Group photo",
  },
];

export const ImageGridSec = [
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/hotel-party-02.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/IMG_8580.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/diwali-04.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/holi-2025-03.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/office-img-21-group.webp",
    alttxt: "about us Group photo",
  },
];

export const ImageGridThrd = [
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/it-GroupPhoto-03.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/diwali-01-scaled.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/IMG_8609.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/holi-2025-05.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/holi-2025-02.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/it-GroupPhoto-03.webp",
    alttxt: "about us Group photo",
  },
];

export const ImageGridFour = [
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/hotel-party-01.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/IMG_8580.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/IMG_8609.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/holi-2025-01.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/IMG_8573-2.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/navaratri-vibes-2024-01.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/office-img-02.webp",
    alttxt: "about us Group photo",
  },
  {
    img: "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/office-img-21-group.webp",
    alttxt: "about us Group photo",
  },
];
export const MagentoFaqMapData = [
  {
    id: "4",
    heading: "How long does Magento Development take?",
    inner:
      "The timeline for Magento Development varies based on project complexity. A simple Magento store can be completed in 8-10 weeks, while advanced, feature-rich solutions may take 6-12 months. We provide detailed project timelines and milestones during the planning phase.<a class='HUderLine' target='_blank' href='/contact-us'>Get Magento Project Deadline</a>.",
  },
  {
    id: "5",
    heading: "Why choose your Magento Development Agency?",
    inner:
      "Partnering with our <a class='HUderLine' target='_blank' href='/'> Magento Development Agency</a> ensures you get a highly skilled team with extensive experience, a proven track record, adherence to industry best practices, continuous support, and competitive pricing with transparent billing.",
  },
  {
    id: "6",
    heading:
      "How do you approach SEO and digital marketing for Magento stores?",
    inner:
      "Our Magento Development Services include a comprehensive SEO and digital marketing strategy, covering keyword research, on-page optimization, content marketing, link building, PPC campaigns, social media marketing, email marketing, and conversion rate optimization.",
  },
  {
    id: "7",
    heading:
      "What is your Magento Development Cost for startups and small businesses?",
    inner:
      "For <a class='HUderLine' target='_blank' href='/startup'>startups</a> and small businesses, our Magento Development Services start at $4,000. This includes a custom Magento website, payment gateway integration, product catalog setup, mobile-friendly design, basic SEO, and one year of hosting and maintenance. Fully customized solutions start at $10,000. <a class='HUderLine' target='_blank' href='/lets-talk'>Get Quick Quote Now</a>.",
  },
  {
    id: "8",
    heading: "What factors influence the Magento Development Cost?",
    inner:
      "Several factors impact <a class='HUderLine' target='_blank' href='/lets-talk'> Magento Development Cost</a>, including the choice of CMS, custom design and features, payment gateway integration, security enhancements, ongoing maintenance, and SEO and digital marketing efforts.",
  },
];

export const BrandlogoArray = [
  { id: 1, url: "../svgs/AllLogo2.svg", alt: "All logos" },
  { id: 2, url: "../svgs/AllLogo2.svg", alt: "2Test" },
  // {"id": 1, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/giddyup-01.webp", "alt": "Giddyup"},
  // {"id": 2, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/skyhub-01.webp", "alt": "Skyhub"},
  // {"id": 3, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/mysquard-logo.webp", "alt": "MySquard"},
  // {"id": 4, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_01-min-1.png", "alt": "Acrevis"},
  // {"id": 5, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_02-min.png", "alt": "Ancasta"},
  // {"id": 6, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_03-min-1.png", "alt": "AbuGarcia"},
  // {"id": 7, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_04-min-1.png", "alt": "Aquiire - A Coupa Company"},
  // {"id": 8, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_05-min.png", "alt": "Sauter"},
  // {"id": 9, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_06-min-1.png", "alt": "Datum360"},
  // {"id": 10, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_07-min-1.png", "alt": "Burflex"},
  // {"id": 11, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_08-min.png", "alt": "Jostens"},
  // {"id": 12, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_09-min.png", "alt": "Smatrics"},
  // {"id": 13, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_10-min.png", "alt": "Bayern International"},
  // {"id": 14, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/evolved.png", "alt": "Evolved"},
  // {"id": 15, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_12-min.png", "alt": "Pitchfork"},
  // {"id": 16, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/oh_logo_01-min-1.png", "alt": "Acrevis"},
  // {"id": 17, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x24/itservicesindia/vechit-logo.webp", "alt": "Vechit"},
  // {"id": 18, "url": "https://d31kzidzn6t9lk.cloudfront.net/fit-in/100x40/itservicesindia/giddyup-01.webp", "alt": "Giddyup"}
];
export const AppFaqMAPAcco = [
  {
    id: "1",
    question:
      "What is Application Management Services <br /> (AMS), and how does it benefit businesses?",
    answer:
      "AMS refers to the outsourcing of application lifecycle management, including <a class='HUderLine' target='_blank' href='/application-maintenance-services'>maintenance</a>, optimization, and support. It ensures smooth operations,&amp; reduces downtime",
  },
  {
    id: "2",
    question:
      "How does an Application Management Service Company ensure application performance and reliability?",
    answer:
      "Such companies use advanced monitoring tools, automated updates, and proactive maintenance to enhance application performance.",
  },
  {
    id: "3",
    question:
      "What is the typical cost and duration for implementing AMS solutions?",
    answer:
      "The cost depends on the complexity of the application and the services required, while implementation can take weeks to a few months.",
  },
  {
    id: "4",
    question:
      "How can enterprise application management help improve operational efficiency?",
    answer:
      "Enterprise application management enhances operational efficiency by automating processes, ensuring seamless updates, and providing 24/7 support, which minimizes disruptions.",
  },
  {
    id: "5",
    question:
      "What are the key features of application management software services?",
    answer:
      "Key features include performance monitoring, anomaly detection, configuration management, automated updates, and real-time reporting to optimize application functionality.",
  },
  {
    id: "6",
    question:
      "How does AMS support businesses in adhering to security and compliance requirements?",
    answer:
      "AMS providers implement strict security measures, regular audits, and compliance protocols to ensure applications meet industry standards and regulations.",
  },
  {
    id: "7",
    question:
      "What role do AMS providers play in <a class='text-hover' target='_blank' href='/application-modernization-services'> application modernization </a> and upgrades?",
    answer:
      "AMS providers assess legacy systems, recommend upgrades, and implement <a class='HUderLine' target='_blank' href='/application-modernization-services' > application modernization strategies </a> to ensure applications remain efficient and compatible with new technologies.",
  },
  {
    id: "8",
    question:
      "How can comprehensive application management enhance scalability for growing businesses?",
    answer:
      "Comprehensive application management enables businesses to scale by optimizing application performance, managing increased workloads, and ensuring resource allocation meets demand.",
  },
  {
    id: "9",
    question:
      "What are the latest trends in application management and support services?",
    answer:
      "Trends include the use of AI for predictive  <a class='HUderLine' target='_blank' href='/application-maintenance-services'> application maintenance</a>, <a class='HUderLine' target='_blank' href='/cloud-application-development'> cloud-based application</a> management, automation in updates, and enhanced focus on data security and analytics.",
  },
  {
    id: "10",
    question:
      "How does AMS contribute to reducing downtime and improving application availability?",
    answer:
      " AMS minimizes downtime through proactive monitoring, issue resolution, and backup strategies, ensuring applications are always available for users.",
  },
];
